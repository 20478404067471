import styled from "styled-components";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { toggleSidebar } from "../redux/actions/sidebarActions";

import { Badge, Collapse, Button } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import routes, {
  adminDashboard,
  standardDashboard,
  publicRoutes,
} from "../routes/index";
import { LogOut } from "@styled-icons/ionicons-outline/LogOut";

import { isValidPublicDomain } from "../config";

const LogOutIcon = styled(LogOut)`
  &:hover {
    transform: scale(1.1);
  }
`;

const initOpenRoutes = (location) => {
  const pathName = location.pathname;

  let _routes = {};

  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    });
  });

  return _routes;
};

const ItemList = styled.span`
  font-size: 16px !important;
`;

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" &&
          (path === "/dashboard" || path === "/links"))
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={
            "d-flex align-items-center sidebar-link " +
            (!isOpen ? "collapsed" : "")
          }
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <ItemList>{name}</ItemList>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };
    const dispatch = useDispatch();

    return name ? (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink
          to={to}
          className="sidebar-link d-flex align-items-center"
          activeClassName="active"
          onClick={() =>
            window.innerWidth < 992 ? dispatch(toggleSidebar()) : null
          }
        >
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          <ItemList>{name}</ItemList>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    ) : null;
  }
);

const Sidebar = ({ location, sidebar, packageValidity }) => {
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));

  const history = useHistory();

  const clientName = window.location.href.split("/")[2].split(".")[0];

  const user = JSON.parse(localStorage.getItem("user"));
  const token = user && user.authorization.split("=")[1].split(";")[0];
  let userType = token && JSON.parse(atob(token.split(".")[1]));

  const sidebarRoutes = isValidPublicDomain
    ? publicRoutes
    : userType && userType.role === "Admin"
    ? adminDashboard
    : standardDashboard;
  const planMapping = {
    oysterpackage: "Oyster Package",
    pearlpackage: "Pearl Package",
    shellpackage: "Shell Package",
  };
  const [plan, setPlan] = useState(null);
  React.useEffect(() => {
    if (packageValidity) {
      setPlan(packageValidity?.plan);
    } else {
      setTimeout(() => {
        setPlan(localStorage?.getItem("userPlan"));
      }, 1000);
    }
  }, [packageValidity]);
  const formattedPlan = planMapping[plan];
  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  if (!user) {
    window.location.reload();
  }

  return (
    <nav
      className={
        "sidebar" +
        (sidebar.isOpen ? " toggled" : "") +
        (!sidebar.isSticky ? " sidebar-sticky" : "")
      }
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a
            className="sidebar-brand pl-4 pt-1 pb-4 d-block"
            href={isValidPublicDomain ? "/links" : "/dashboard"}
          >
            <img
              className="align-middle pt-3"
              src={`https://storage.googleapis.com/purl-assets/client-logos/${clientName}.png`}
              alt={clientName}
              width="120"
              height="90"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
              }}
            />
          </a>

          <ul className="sidebar-nav mb-4 margin-last-element">
            {sidebarRoutes.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                  {category.children ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      isOpen={openRoutes[index]}
                      onClick={() => toggle(index)}
                    >
                      {category.children.map((route, index) => (
                        <SidebarItem
                          key={index}
                          name={route.name}
                          to={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                      name={category.name}
                      to={category.path}
                      icon={category.icon}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                    />
                  )}
                </React.Fragment>
              );
            })}
            <div
              className="w-100 d-sm-none d-flex justify-content-start mb-3"
              style={{ paddingLeft: "27.200px", marginTop: "15px" }}
            >
              <Button
                color="primary"
                size="lg"
                href="https://www.p-url.com"
                target="_blank"
              >
                Visit our website
              </Button>
            </div>
          </ul>

          <div className="sidebar-bottom d-block">
            <div className="d-flex align-items-center ml-3">
              <label title="Logout" className="m-0">
                <LogOutIcon
                  className="cursor-pointer"
                  color="#ffffff"
                  size={32}
                  onClick={() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("userPlan");
                    localStorage.removeItem("cancelPlan");
                    history.push("/auth/sign-in");
                  }}
                />
              </label>
              <div className="media-body pl-3">
                <h5 className="mb-1">{user.full_name}</h5>
                <div className="">
                  {isValidPublicDomain ? formattedPlan : userType.role}
                </div>
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
    layout: store.layout,
    auth: store.auth.user,
    packageValidity: store.subscriber.packageValidityData,
  }))(Sidebar)
);
