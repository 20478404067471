import React from "react";

import { Col, Container, Row } from "reactstrap";

import Main from "../components/Main";
import { isValidPublicDomain } from "../config";

const Auth = ({ children }) => {
  if (isValidPublicDomain) {
    return (
      <React.Fragment>
        <Main className="d-flex w-100 justify-content-center">
          <Row
            className="position-absolute w-100 d-none d-lg-flex"
            style={{ height: "100%", top: "0" }}
          >
            <Col>
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  bottom: "50%",
                  left: "40px",
                  backgroundColor: "#FFDA15",
                }}
                className="rounded-circle position-absolute"
              ></div>
            </Col>
            <Col>
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  bottom: "90px",
                  right: "0px",
                  backgroundColor: "#F6281B",
                }}
                className="rounded-circle position-absolute"
              ></div>
            </Col>
            <Col>
              <div
                style={{
                  height: "20px",
                  width: "20px",
                  top: "100px",
                  backgroundColor: "#1EB2D2",
                }}
                className="rounded-circle position-absolute"
              ></div>
            </Col>
            <Col></Col>
            <Col>
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  top: "20px",
                  left: "40%",
                  backgroundColor: "#FFDA15",
                }}
                className="rounded-circle position-absolute z-10"
              ></div>
            </Col>
            <Col></Col>
            <Col></Col>
            <Col>
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  top: "80px",
                  right: "0px",
                  backgroundColor: "#F6281B",
                }}
                className="rounded-circle position-absolute"
              ></div>
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  bottom: "170px",
                  right: "0px",
                  backgroundColor: "#1EB2D2",
                }}
                className="rounded-circle position-absolute"
              ></div>
            </Col>
            <Col></Col>
            <Col>
              <div
                style={{
                  height: "20px",
                  width: "20px",
                  bottom: "50%",
                  left: "40px",
                  backgroundColor: "#FFDA15",
                }}
                className="rounded-circle position-absolute"
              ></div>
            </Col>
          </Row>
          <Container className="d-flex flex-column ">
            <Row className="h-100">
              <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
                <div className="d-table-cell align-middle">{children}</div>
              </Col>
            </Row>
          </Container>
        </Main>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Main className="d-flex w-100 justify-content-center">
          <Container className="d-flex flex-column">
            <Row className="h-100">
              <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
                <div className="d-table-cell align-middle">{children}</div>
              </Col>
            </Row>
          </Container>
        </Main>
      </React.Fragment>
    );
  }
};

export default Auth;
