import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Form,
  FormGroup,
  Label,
  Button,
  CustomInput,
  Spinner,
} from "reactstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar, Filter, RefreshCw } from "react-feather";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import { Search } from "@styled-icons/boxicons-regular/Search";
import { Warning } from "@styled-icons/ionicons-solid/Warning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { Inputs, ButtonDropdown } from "../../../../components/CustomStyle";
import Pagination from "../../../../components/Pagination";
import {
  fetchTableData,
  downloadTableData,
} from "../../../../redux/actions/subscriberActions";
import { isValidPublicDomain } from "../../../../config";

const Bsearch = styled(Search)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  left: 23px;
  padding-top: 7px;
  z-index: 1;
`;

const Table = ({
  theme,
  fetchTableData,
  table_data,
  downloadTableData,
  singleReportData,
  refresh,
  tableDataLoading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("all");
  const [tableData, setTableData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [date, setDate] = useState([
    {
      startDate: moment().subtract(6, "days").startOf("day").toDate(),
      endDate: moment().endOf("day").toDate(),
      key: "selection",
    },
  ]);
  const [uniqueIdWidth, setUniqueIdWidth] = useState("200px");

  const setWidth = () => {
    setUniqueIdWidth((prevWidth) => (prevWidth === "200px" ? "none" : "200px"));
  };

  const smallButton = () => {
    return (
      <div className="d-flex align-items-center">
        Unique ID
        <Button
          onClick={setWidth}
          size="sm"
          color="primary"
          className="ml-2 rounded-lg"
          outline
        >
          {uniqueIdWidth == "200px" ? "expand" : "collapse"}
        </Button>
      </div>
    );
  };

  const roundedIcon = (iconClicks) => {
    return (
      <>
        {iconClicks == 0 ? (
          <FontAwesomeIcon
            icon={faCircle}
            color={theme.danger}
            className="ml-2"
          />
        ) : (
          <FontAwesomeIcon
            icon={faCircle}
            color={theme.success}
            className="ml-2"
          />
        )}
      </>
    );
  };

  const refreshTable = () => {
    fetchTableData(id, date[0].startDate, date[0].endDate, type);
  };

  //UseEffect for date filter and refresh data
  useEffect(() => {
    if ((date[0]?.startDate && date[0]?.endDate) || refresh) {
      refreshTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date[0].startDate, date[0].endDate, type, refresh]);

  //useEffect for Table Data
  useEffect(() => {
    const tempTabledata = table_data?.map((item) => {
      return {
        unique_id: item?.uniqueid,
        shorturl: item?.hash_id,
        longurl: item?.redirect_url,
        clicks: item?.clicks,
        created_at: item?.first_created_at
          ? moment(item?.first_created_at).format("DD MMM YYYY, h:mm A")
          : "-",
        Last_updated_at: item?.last_created_at
          ? moment(item?.last_created_at).format("DD MMM YYYY, h:mm A")
          : "-",
        total_clicks_across_all_campaigns:
          item?.total_clicks_across_all_campaigns,
      };
    });
    setTableData(tempTabledata);
  }, [table_data]);

  // set variable name for downloading reports
  useEffect(() => {
    setName(singleReportData?.campaign_name);
  }, [singleReportData]);

  useEffect(() => {
    if (type == "all") {
      setFilterText(`Showing "All" purls`);
    } else if (type == "clicked") {
      setFilterText(`Showing "Clicked" purls`);
    } else if (type == "unclicked") {
      setFilterText(`Showing "Unclicked" purls`);
    }
  }, [type]);

  const filterTableData = tableData?.filter(
    (data) =>
      data?.longurl?.toLowerCase()?.includes(searchInput.toLowerCase()) ||
      data?.shorturl?.toLowerCase()?.includes(searchInput.toLowerCase()) ||
      data?.unique_id?.toLowerCase()?.includes(searchInput.toLowerCase())
  );

  const { id } = useParams();
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginatedData = filterTableData?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const paginate = (number) => setCurrentPage(number);

  const tableColumns = [
    {
      dataField: "unique_id",
      text: smallButton(),
      headerStyle: {
        minWidth: "40px",
        whiteSpace: "nowrap",
      },
      style: {
        minWidth: "40px",
        maxWidth: uniqueIdWidth,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    {
      dataField: "shorturl",
      text: "purls",
      headerStyle: { minWidth: "50px" },
      style: { minWidth: "50px", whiteSpace: "nowrap" },
    },
    {
      dataField: "longurl",
      text: "Long url",
      headerStyle: {
        minWidth: "50px",
        whiteSpace: "nowrap",
      },
      style: {
        minWidth: "50px",
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    {
      dataField: "created_at",
      text: "First click",
      headerStyle: {
        minWidth: "50px",
        whiteSpace: "nowrap",
      },
      style: { minWidth: "50px", whiteSpace: "nowrap" },
    },
    {
      dataField: "Last_updated_at",
      text: "Latest click",
      headerStyle: {
        minWidth: "50px",
        whiteSpace: "nowrap",
      },
      style: { minWidth: "50px", whiteSpace: "nowrap" },
    },
    {
      dataField: "clicks",
      text: "Clicks",
      sort: true,
      headerStyle: { minWidth: "50px" },
      style: { minWidth: "50px" },
      formatter: (cell) => (
        <div className="flex">
          {cell}
          {roundedIcon(cell)}
        </div>
      ),
    },
    {
      dataField: "total_clicks_across_all_campaigns",
      text: isValidPublicDomain
        ? "Total clicks across all trackable links"
        : "Total clicks across all campaigns",
      sort: true,
      headerStyle: { minWidth: "150px" },
      style: { minWidth: "50px" },
      hidden: true,
      formatter: (cell) => (
        <div className="flex">
          {cell}
          {roundedIcon(cell)}
        </div>
      ),
    },
  ];

  return (
    <Card className="flex-fill w-100">
      <CardHeader>
        <div className="card-actions float-right"></div>
        <CardTitle tag="h5" className="text-center mb-0 font-weight-bold">
          Performance Table
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className="d-flex align-items-center custom-wrap justify-content-center mb-3">
          <div className="w-100 mr-sm-2 mr-0 m mb-3 mb-sm-0">
            <Bsearch />
            <Inputs
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              className="text-truncate w-100"
              placeholder="Search"
            />
          </div>
          <Button
            color="primary"
            className="shadow-sm d-flex align-items-center mr-2"
            style={{ height: "38px" }}
            onClick={refreshTable}
          >
            <RefreshCw className="feather" />
          </Button>
          <UncontrolledDropdown
            inNavbar
            className="d-inline mr-2"
            style={{ height: "38px" }}
          >
            <span>
              <DropdownToggle color="primary h-100">
                <Filter className="feather" />
              </DropdownToggle>
            </span>
            <DropdownMenu className="p-0 h-auto custom-width " right>
              <Card className="mb-0">
                <CardHeader className="px-3 py-2">
                  <CardTitle
                    tag="h5"
                    className="text-center mb-0 font-weight-bold text-nowrap"
                  >
                    Filter
                  </CardTitle>
                </CardHeader>
                <div className="row border-top mx-0 ">
                  <div className="mb-2 col-md-12 px-3">
                    <Form>
                      <FormGroup className="mb-0">
                        <div className="mt-2">
                          <CustomInput
                            type="radio"
                            id="customRadio3"
                            name="customRadio"
                            label="All purls"
                            className="mb-2"
                            value="all"
                            checked={type === "all"}
                            onChange={(event) => {
                              setType(event.target.value);
                            }}
                          />
                          <CustomInput
                            type="radio"
                            id="customRadio"
                            name="customRadio"
                            label="Clicked purls"
                            className="mb-2"
                            value="clicked"
                            checked={type === "clicked"}
                            onChange={(event) => {
                              setType(event.target.value);
                            }}
                          />
                          <CustomInput
                            type="radio"
                            id="customRadio2"
                            name="customRadio"
                            label="Unclicked purls"
                            disabled={
                              moment()._d >
                              moment(singleReportData?.shorturl_expiry_time)
                                .endOf("day")
                                .toDate()
                                ? true
                                : false
                            }
                            className="mb-2"
                            value="unclicked"
                            checked={type === "unclicked"}
                            onChange={(event) => {
                              setType(event.target.value);
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </Card>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown
            inNavbar
            className="d-inline"
            style={{ height: "38px" }}
          >
            <span>
              <ButtonDropdown>
                <DropdownToggle
                  color="primary"
                  className="d-flex justify-content-center py-2 h-100 align-items-center"
                >
                  <Calendar className="feather align-middle" />
                </DropdownToggle>
              </ButtonDropdown>
            </span>
            <DropdownMenu
              right
              className="pb-4 pt-0"
              style={{ width: "300px" }}
            >
              <CardHeader className="px-4 ">
                <CardTitle
                  tag="h5"
                  className="text-center mb-0 font-weight-bold text-nowrap"
                >
                  Filter by Date
                </CardTitle>
              </CardHeader>
              <div className="px-4 pt-2 border-top">
                <Label>Select date range</Label>
                <DatePicker
                  className="form-control form-control-lg w-100"
                  placeholderText="Select date range"
                  selectsRange
                  startDate={date[0]?.startDate}
                  endDate={date[0]?.endDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(item) => {
                    setDate((prevDate) => [
                      {
                        ...prevDate[0],
                        startDate: item[0]
                          ? moment(item[0]).startOf("day").toDate()
                          : null,
                        endDate: item[1]
                          ? moment(item[1]).endOf("day").toDate()
                          : null,
                      },
                      ...prevDate.slice(1),
                    ]);
                  }}
                />
                <div className="mt-3 d-flex justify-content-center">
                  <DropdownToggle
                    color="primary"
                    className=""
                    onClick={() => {
                      setDate((prevDate) => [
                        {
                          ...prevDate[0],
                          startDate: moment()
                            .subtract(6, "days")
                            .startOf("day")
                            .toDate(),
                          endDate: moment().endOf("day").toDate(),
                        },
                        ...prevDate.slice(1),
                      ]);
                    }}
                  >
                    Reset{" "}
                  </DropdownToggle>
                </div>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
          <div
            className="d-flex justify-content-end ml-2"
            style={{ height: "38px" }}
          >
            <Button
              color="primary d-flex align-items-center"
              disabled={paginatedData?.length > 0 ? false : true}
              onClick={() =>
                downloadTableData(
                  id,
                  date[0].startDate,
                  date[0].endDate,
                  type,
                  name
                )
              }
            >
              Download
            </Button>
          </div>
        </div>
        <p className="mb-2" style={{ textAlign: "end" }}>
          {filterText}
        </p>
        {tableDataLoading ? (
          <div className="d-flex justify-content-center my-4">
            <Spinner
              animation="border"
              color="primary"
              role="status"
              style={{ width: "5rem", height: "5rem", borderWidth: "8px" }}
            />
          </div>
        ) : paginatedData?.length > 0 ? (
          <div className="overflow-auto">
            <BootstrapTable
              bootstrap4
              bordered={true}
              striped={true}
              keyField="name"
              data={paginatedData}
              columns={tableColumns}
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center my-5">
            {table_data?.length === 0 ? (
              <h4 className="text-center">
                No clicks have been recorded for this campaign for the applied
                filter.
              </h4>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Warning size={50} />
                <h4 className="text-center">
                  Sorry, no results match your search parameters. Please try
                  again with different keywords.
                </h4>
              </div>
            )}
          </div>
        )}
      </CardBody>
      {!tableDataLoading ? (
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={filterTableData?.length}
          paginate={paginate}
          currentPage={currentPage}
          setPostPerPage={setPostPerPage}
          postsInCurrentPage={paginatedData.length}
        />
      ) : null}
    </Card>
  );
};
const mapPropsToState = (state) => {
  return {
    table_data: state.subscriber.singleReportTableData,
    theme: state.theme.currentTheme,
    singleReportData: state.subscriber.singleReportData,
    refresh: state.subscriber.refresh,
    tableDataLoading: state.subscriber.tableDataLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchTableData: (id, startDate, endDate, type) =>
      dispatch(fetchTableData(id, startDate, endDate, type)),
    downloadTableData: (id, startDate, endDate, type, name) =>
      dispatch(downloadTableData(id, startDate, endDate, type, name)),
  };
};
export default connect(mapPropsToState, mapDispatchToProps)(Table);
