import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";

import { CardBody, CardHeader, CardTitle, Button, Card } from "reactstrap";
import RetargetModal from "../../../../components/RetargetModal";

const ClickChart = ({ theme, Data, singleCampaignExpiryDate }) => {
  const [showModal, setShowModal] = useState(false);
  const modalToggle = () => setShowModal(!showModal);

  const [isWideScreen, setIsWideScreen] = React.useState(
    window.innerWidth > 1330
  );

  React.useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1330);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function ratio() {
    if (Data.clicks[0] <= 150) {
      return false;
    } else {
      return Data.clicks[1] / Data.clicks[0] < 1 / 25;
    }
  }

  const type = ratio() ? "logarithmic" : "linear";

  const data = {
    labels: Data.label,
    datasets: [
      {
        data: Data.clicks,
        label: "Value",
        backgroundColor: [
          theme.primary,
          theme.danger,
          theme.warning,
          theme.success,
          theme.secondary,
          "#ecdd7bff",
          "#4ecdc4ff",
          "#cd4631ff",
          "#5438dcff",
          "#2e2e3aff",
        ],
      },
    ],
  };
  const options = {
    maintainAspectRatio: isWideScreen,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)",
          },
        },
      ],
      yAxes: [
        {
          type: type,
          position: "left",
          scaleLabel: {
            display: true,
            fontColor: theme.primary,
          },
          ticks: {
            beginAtZero: true,
            fontColor: theme.primary,
            autoSkip: true,
            maxTicksLimit: 6,
            callback: function (value) {
              return value.toLocaleString();
            },
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff",
          },
        },
      ],
    },
  };
  return (
    <React.Fragment>
      <Card className={`${singleCampaignExpiryDate && "shadow-none"}`}>
        <>
          <div
            className={`flex-fill  ${
              singleCampaignExpiryDate ? "border rounded " : ""
            } w-100  h-100`}
          >
            <CardHeader className="pb-0 mb-0">
              <CardTitle tag="h5" className="text-center mb-0 font-weight-bold">
                Clicks Chart
              </CardTitle>
            </CardHeader>
            <CardBody className="d-flex mt-0">
              <div className="align-self-center w-100">
                <div className="chart chart-sm">
                  <Bar data={data} options={options} />
                </div>
                {Data?.clicks[1] && singleCampaignExpiryDate ? (
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <Button
                      color="primary"
                      className="px-4"
                      size="lg"
                      onClick={modalToggle}
                    >
                      Click to retarget via Clicks
                    </Button>
                  </div>
                ) : null}
              </div>
            </CardBody>
          </div>
          <RetargetModal
            isOpen={showModal}
            toggle={modalToggle}
            title="Clicks"
            optionData={Data}
            singleCampaignExpiryDate={singleCampaignExpiryDate}
          />
        </>
      </Card>
    </React.Fragment>
  );
};

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(ClickChart);
