import { connect } from "react-redux";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { login } from "./../../redux/actions/authActions";
import { LoginFormValidation } from "../validation/validation";
import styled from "styled-components";
import { Eye, EyeSlash } from "@styled-icons/bootstrap";
import { jwtDecode } from "jwt-decode";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import SignUp from "./SignUp";
import { isValidPublicDomain } from "../../config";

const Text = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;

const Showeye = styled(Eye)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  right: 50px;
  padding-top: 7px;
  z-index: 1;
`;
const Noeye = styled(EyeSlash)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  right: 50px;
  padding-top: 7px;
  z-index: 1;
`;
// export let userData;
const SignIn = ({ login }) => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [validError, setValidError] = useState({
    email: "",
    password: "",
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const clientName = window.location.href.split("/")[2].split(".")[0];

  // Toggle for Modal/////////////////////////////
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const handleLogin = (data, formIsValid) => {
    if (formIsValid) {
      login(data);
    }
  };

  const newPageTitle = "purl - Sign in";
  document.title = newPageTitle;

  const local_user = JSON.parse(localStorage.getItem("user"));
  const token = local_user?.authorization?.split("=")[1]?.split(";")[0];

  const decoded = token ? jwtDecode(token) : "";
  if (!local_user && isValidPublicDomain) {
    return <Redirect to="/auth/sign-in" />;
  }
  if (!local_user) {
    return (
      <div>
        <div style={{ alignItems: "flex-end" }}></div>
        <div className="text-center mt-4">
          <div className="text-center pb-0">
            <img
              alt={clientName}
              src={`https://storage.googleapis.com/purl-assets/client-logos/${clientName}.png`}
              width="150"
              height="90"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
              }}
            />
          </div>
          <p className="lead mt-3">Sign in to your account to continue</p>
        </div>

        <Card>
          <CardBody>
            <div className="m-sm-4">
              <Form>
                <FormGroup>
                  <Label>
                    Email<span className="text-danger">*</span>
                  </Label>
                  <Input
                    bsSize="lg"
                    type="email"
                    name="email"
                    id="email"
                    value={user.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        const { formIsValid, errors } =
                          LoginFormValidation(user);
                        setValidError(errors);
                        handleLogin(user, formIsValid);
                      }
                    }}
                  />
                  <span style={{ color: "red" }}>
                    <p>{validError.email}</p>
                  </span>
                </FormGroup>
                <FormGroup>
                  <Label>
                    Password<span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex">
                    <Input
                      style={{ paddingRight: "35px" }}
                      bsSize="lg"
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      id="password"
                      value={user.password}
                      onChange={handleChange}
                      placeholder="Enter your password"
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          const { formIsValid, errors } =
                            LoginFormValidation(user);
                          setValidError(errors);
                          handleLogin(user, formIsValid);
                        }
                      }}
                    />
                    {!passwordShown ? (
                      <Noeye onClick={togglePasswordVisiblity} />
                    ) : (
                      <Showeye onClick={togglePasswordVisiblity} />
                    )}
                  </div>
                  <span style={{ color: "red" }}>
                    <p>{validError.password}</p>
                  </span>
                  <h5>
                    <Link to="/auth/reset-link">Forgot password?</Link>
                  </h5>
                </FormGroup>
                <div className="text-center mt-3 mb-4">
                  <Button
                    color="primary"
                    size="lg"
                    onClick={() => {
                      const { formIsValid, errors } = LoginFormValidation(user);
                      setValidError(errors);
                      handleLogin(user, formIsValid);
                    }}
                  >
                    Sign in
                  </Button>
                </div>
                <div>
                  <h5 style={{ alignItems: "center", textAlign: "center" }}>
                    Request a new account ?{" "}
                    <span>
                      <Text className="primary-color" onClick={toggleModal}>
                        Click Here
                      </Text>
                    </span>
                  </h5>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>

        {/* Modal for requesting a new account  */}
        <Modal center isOpen={modal} toggle={toggleModal}>
          {/* <ModalHeader toggle={toggleModal}></ModalHeader> */}
          <ModalBody>
            <SignUp />
          </ModalBody>
        </Modal>
        {/* Modal Ended here */}
      </div>
    );
  } else {
    if (local_user && !decoded?.otpVerified) {
      return <Redirect to="/auth/otp" />;
    } else if (local_user && decoded?.onboarding === false) {
      return <Redirect to="/onboarding/step1" />;
    } else if (local_user && local_user?.mfa_type == "disabled") {
      return <Redirect to="/dashboard" />;
    } else if (local_user && decoded?.otpVerified === "NA") {
      return <Redirect to="/auth/2fa-request" />;
    } else {
      return <Redirect to="/dashboard" />;
    }
  }
};

const mapStateToProps = (state) => {
  return {
    userState: state.auth.user,
    loginState: state.auth.loginState,
    errorMessage: state.auth.error.errorMessage,
    errorState: state.auth.error.errorState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
