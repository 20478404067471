import React, { useEffect, useState } from "react";
import { Card, CardBody, Spinner, Row, Col, Button } from "reactstrap";
import FileUpload from "../CreatePurls/FileUpload";
import CopyForm from "./draftForm";
import axios from "axios";
import CryptoJS from "crypto-js";
import { ArrowLeft, ArrowRight } from "react-feather";
import { BACKEND_ENDPOINT } from "../../../../config";
import {
  draftACampaign,
  createNewList,
  getAllTag,
  getShortDomain,
  fetchSubscriberLists,
  campaignStatus,
  emptyCampaignStatus,
  emptySignedUrls,
  getTeams,
} from "../../../../redux/actions/subscriberActions";
import { connect } from "react-redux";
import {
  subscriberListValidation,
  draftCampaignDataValidation,
} from "../../../validation/validation";
import { useHistory, useParams } from "react-router-dom";
import TagManager from "react-gtm-module";

import DraftModal from "../../../../components/DraftModal";

import { isValidPublicDomain } from "../../../../config";
function DraftCampaign({
  getShortDomain,
  shortDomain,
  shortDomainLoadingState,
  getAllTag,
  tagState,
  loading,
  fetchSubscriberLists,
  campaignStatus,
  saveCampaignStatus,
  emptyCampaignStatus,
  emptySignedUrls,
  getTeams,
  teams,
}) {
  const { id } = useParams();
  const newPageTitle = "purl - Create purls";
  document.title = newPageTitle;
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user && user.authorization.split("=")[1].split(";")[0];
  let userType = token && JSON.parse(atob(token.split(".")[1]));

  //States are defined here
  const [finalData, setfinalData] = useState(null);
  const [isFormValid, setFormValidation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const modalToggle = () => setShowModal(!showModal);
  const [copyCampaignData, setCopyCampaignData] = useState("");
  const [campaign_name, setCampaignName] = useState("");
  const [landingPage, setLandingPage] = useState("");
  const [parsedLandingPageUrl, setParsedLandingPageUrl] = useState("");
  const [checked, setChecked] = useState(false);
  const [password, setPassword] = useState(null);
  const [encryptedPassword, setEncryptedPassword] = useState(null);
  const [checked1, setChecked1] = useState(null);
  const [short_Domain, setShortDomain] = useState([]);
  const [team, setTeam] = useState([]);
  const [expiry, setExpiry] = useState("");
  const [utmSource, setUtmSource] = useState({
    value: null,
    isFromQuery: true,
  });
  const [utmCampaign, setUtmCampaign] = useState({
    value: null,
    isFromQuery: true,
  });
  const [utmContent, setUtmContent] = useState({
    value: null,
    isFromQuery: true,
  });
  const [utmMedium, setUtmMedium] = useState({
    value: null,
    isFromQuery: true,
  });
  let [tag, setTag] = useState([]);
  const [selectShort, setSelectShort] = useState([]);
  const [selectdTeam, setSelectdTeam] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [file, setfile] = useState([]);
  const [step, setStep] = useState(0);
  const [fileStatus, setFileStatus] = useState(false);
  const [landingPageFromBacked, setLandingPageFromBackend] = useState("");

  const [error, setError] = useState({
    title: "",
    landingPage: "",
    expiry: "",
    utmSource: "",
    utmCampaign: "",
    utmContent: "",
    utmMedium: "",
    tag: "",
    domainId: "",
    file: "",
    uniqueId: "",
    password: "",
  });
  let [tagOptions] = useState([{ value: "", label: "" }]);
  let [shortDomainOptions] = useState([{ value: "", label: "" }]);
  // eslint-disable-next-line no-undef
  const key = process.env.REACT_APP_PASSWORD_DECRYPTION_KEY;
  const history = useHistory();

  useEffect(() => {
    if (password) {
      setChecked1(true);
    }
  }, [password]);
  useEffect(() => {
    getAllTag();
    const reqUrl = `${BACKEND_ENDPOINT}/campaign/${id}`;
    axios
      .get(reqUrl, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCopyCampaignData(res.data);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    let url = landingPageFromBacked;
    const realUrl = landingPageFromBacked;
    if (url) {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = `https://${url}`;
      }
      const urlObject = new URL(url);
      const baseUrl = `${urlObject.origin}${urlObject.pathname}`;
      const decodedParams = Array.from(urlObject.searchParams.entries())
        .map(([key, value]) => `${key}=${decodeURIComponent(value)}`)
        .join("&");
      let finalUrl = baseUrl;
      if (decodedParams) {
        finalUrl += `?${decodedParams}`;
      }

      if (!realUrl.startsWith("http://") && !realUrl.startsWith("https://")) {
        if (finalUrl.startsWith("https://")) {
          finalUrl = finalUrl.slice(8);
        } else if (finalUrl.startsWith("http://")) {
          finalUrl = finalUrl.slice(7);
        }
        if (finalUrl.endsWith("/")) {
          finalUrl = finalUrl.slice(0, -1);
        }
      }
      setLandingPage(finalUrl);
    }
  }, [landingPageFromBacked]);

  useEffect(() => {
    const setUtms = () => {
      let url = landingPageFromBacked;
      try {
        const parsedUrl = new URL(url);
        const queryParams = parsedUrl.searchParams;
        const extractedParams = {};
        const utmPattern = /^utm[\s_-]*(source|campaign|content|medium)$/i;

        queryParams.forEach((value, key) => {
          if (utmPattern.test(key)) {
            const normalizedKey = key.toLowerCase().replace(/[\s_-]/g, "");
            const values = value.split(",");
            values.forEach((val, index) => {
              const utmKey =
                values.length > 1
                  ? `${normalizedKey}_${index + 1}`
                  : normalizedKey;
              extractedParams[utmKey] = val.trim();
            });
          }
        });

        const setUTM = (paramKey, stateSetter, fallbackValue) => {
          const normalizedParamKey = paramKey
            .toLowerCase()
            .replace(/[\s_-]/g, "");
          const matchedKey = Object.keys(extractedParams).find((key) =>
            key.startsWith(normalizedParamKey)
          );
          if (matchedKey) {
            stateSetter({
              value: extractedParams[matchedKey],
              isFromQuery: false,
            });
          } else {
            stateSetter({
              value: fallbackValue,
              isFromQuery: true,
            });
          }
        };

        setUTM("utm_source", setUtmSource, copyCampaignData?.utm_source || "-");
        setUTM("utm_medium", setUtmMedium, copyCampaignData?.utm_medium || "-");
        setUTM(
          "utm_campaign",
          setUtmCampaign,
          copyCampaignData?.utm_campaign || "-"
        );
        setUTM(
          "utm_content",
          setUtmContent,
          copyCampaignData?.utm_content || "-"
        );
      } catch (err) {
        setUtmSource({
          value: copyCampaignData?.utm_source || "-",
          isFromQuery: true,
        });
        setUtmMedium({
          value: copyCampaignData?.utm_medium || "-",
          isFromQuery: true,
        });
        setUtmContent({
          value: copyCampaignData?.utm_content || "-",
          isFromQuery: true,
        });
        setUtmCampaign({
          value: copyCampaignData?.utm_campaign || "-",
          isFromQuery: true,
        });
      }
    };

    setUtms();
  }, [landingPageFromBacked]);

  useEffect(() => {
    setCampaignName(copyCampaignData?.campaign_name);
    setLandingPageFromBackend(copyCampaignData?.landing_page);
    setChecked(copyCampaignData?.shorturl_expiry_time ? true : false);
    setShortDomain({
      value: copyCampaignData?.shortDomain?.short_domain_id,
      label: copyCampaignData?.shortDomain?.short_domain,
    });
    setTeam({
      value: copyCampaignData?.Team?.team_id,
      label: copyCampaignData?.Team?.team_name,
    });
    setTag(
      copyCampaignData?.subTagMappings?.map((el) => ({
        value: el.tag.tag_name,
        label: el.tag.tag_name,
      }))
    );
    setExpiry(
      copyCampaignData?.shorturl_expiry_time
        ? copyCampaignData?.shorturl_expiry_time
        : ""
    );

    setfile(
      (copyCampaignData?.files || []).map((i) => ({
        file: i.file_name,
        path: i.input_location,
      }))
    );
    setEncryptedPassword(copyCampaignData?.password);
  }, [copyCampaignData]);

  useEffect(() => {
    return () => {
      emptySignedUrls();
    };
  }, []);
  ///Functions are defined here
  function decryptDataWithAES(encryptedDataWithIV, keyHex) {
    const ivHex = encryptedDataWithIV.slice(0, 32);
    const encryptedDataHex = encryptedDataWithIV.slice(32);

    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const key = CryptoJS.enc.Hex.parse(keyHex);
    const encryptedData = CryptoJS.enc.Hex.parse(encryptedDataHex);

    const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedData }, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedPassword = decrypted.toString(CryptoJS.enc.Utf8);
    setPassword(decryptedPassword);
  }
  const parseLandingPageURL = (urls) => {
    const realUrl = urls;
    let url = urls;
    if (!url?.startsWith("http://") && !url?.startsWith("https://")) {
      url = `https://${url}`;
    }
    try {
      const parsedUrl = new URL(url);
      const queryParams = parsedUrl.searchParams;
      const utmPattern = /^utm[\s_-]*(source|campaign|content|medium)$/i;
      const extractedParams = {};

      queryParams.forEach((value, key) => {
        if (utmPattern.test(key)) {
          const values = value.split(",");
          values.forEach((val, index) => {
            const utmKey = values.length > 1 ? `${key}_${index + 1}` : key;
            extractedParams[utmKey] = val.trim();
          });
        }
      });
      let newUrl = parsedUrl.toString();
      if (!realUrl.startsWith("http://") && !realUrl.startsWith("https://")) {
        if (newUrl.startsWith("https://")) {
          newUrl = newUrl.slice(8);
        } else if (newUrl.startsWith("http://")) {
          newUrl = newUrl.slice(7);
        }

        if (newUrl.endsWith("/")) {
          newUrl = newUrl.slice(0, -1);
        }
      }
      setParsedLandingPageUrl(newUrl);
    } catch (error) {
      console.error("Error parsing URL:", error.message);
    }
  };
  const handleLandingPageInput = (value) => {
    setLandingPage(value);
    if (value) {
      let url = value.trim();
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = `https://${url}`;
      }
      try {
        const parsedUrl = new URL(url);
        const queryParams = parsedUrl.searchParams;
        const extractedParams = {};
        const utmPattern = /^utm[\s_-]*(source|campaign|content|medium)$/i;

        queryParams.forEach((value, key) => {
          if (utmPattern.test(key)) {
            const values = value.split(",");
            values.forEach((val, index) => {
              const utmKey = values.length > 1 ? `${key}_${index + 1}` : key;
              extractedParams[utmKey.toLowerCase()] = val.trim();
            });
          }
        });
        const processedUtms = {
          source: false,
          medium: false,
          campaign: false,
          content: false,
        };
        Object.entries(extractedParams).forEach(([key, value]) => {
          const lowercaseKey = key.toLowerCase();
          if (lowercaseKey.includes("source")) {
            setUtmSource({ value, isFromQuery: false });
            processedUtms.source = true;
          }
          if (lowercaseKey.includes("medium")) {
            setUtmMedium({ value, isFromQuery: false });
            processedUtms.medium = true;
          }
          if (lowercaseKey.includes("campaign" && !isValidPublicDomain)) {
            setUtmCampaign({ value, isFromQuery: false });
            processedUtms.campaign = true;
          }
          if (lowercaseKey.includes("content")) {
            setUtmContent({ value, isFromQuery: false });
            processedUtms.content = true;
          }
        });
        if (!processedUtms.source && utmSource?.isFromQuery === false) {
          setUtmSource({ value: "", isFromQuery: false });
        }
        if (!processedUtms.medium && utmMedium?.isFromQuery === false) {
          setUtmMedium({ value: "", isFromQuery: false });
        }
        if (!processedUtms.campaign && utmCampaign?.isFromQuery === false) {
          setUtmCampaign({ value: "", isFromQuery: false });
        }
        if (!processedUtms.content && utmContent?.isFromQuery === false) {
          setUtmContent({ value: "", isFromQuery: false });
        }
      } catch (error) {
        console.error("Error parsing URL:", error.message);
      }
    } else {
      setUtmSource({ value: "", isFromQuery: true });
      setUtmMedium({ value: "", isFromQuery: true });
      setUtmCampaign({ value: "", isFromQuery: true });
      setUtmContent({ value: "", isFromQuery: true });
    }
  };
  const filteredTeams = teams?.filter((team) => {
    return (
      team?.Team?.status === "Active" &&
      (team?.role === "Admin" || team?.role === "Editor")
    );
  });
  const teamsOptions = filteredTeams?.map((team) => ({
    value: team?.team_id,
    label: team?.Team?.team_name,
  }));
  const setDropdownTwo = () => {
    for (let j = 0; shortDomain && j < shortDomain.length; j++) {
      shortDomainOptions[j] = {
        value: shortDomain[j].short_domain_id,
        label: shortDomain[j].short_domain,
      };
    }
  };

  const setDropdown = () => {
    for (let i = 0; tagState && i < tagState.length; i++) {
      tagOptions[i] = {
        value: tagState[i].tag_name,
        label: tagState[i].tag_name,
      };
    }
  };

  const handleTag = (val) => {
    setTag(val);
  };

  const handleUtmsChecks = () => {
    const utms = {
      utm_source:
        utmSource?.value === "-" || utmSource?.value === ""
          ? null
          : utmSource?.isFromQuery
          ? utmSource.value
          : null,
      utm_medium:
        utmMedium?.value === "-" || utmMedium?.value === ""
          ? null
          : utmMedium?.isFromQuery
          ? utmMedium.value
          : null,
      utm_content:
        utmContent?.value === "-" || utmContent?.value === ""
          ? null
          : utmContent?.isFromQuery
          ? utmContent.value
          : null,
      utm_campaign:
        utmCampaign?.value === "-" || utmCampaign?.value === ""
          ? null
          : utmCampaign?.isFromQuery
          ? utmCampaign.value
          : null,
    };

    return utms;
  };
  const uploadData = async () => {
    if (finalData && isFormValid) {
      // ---------GTM Start----------
      const tagManagerArgs = {
        gtmId: "GTM-PSTCZS3",
        dataLayer: {
          event: "formSubmission",
          formName: "Add new subscriber list",
          userId: userType && userType.id,
        },
      };
      TagManager.initialize(tagManagerArgs);
      // --------- GTM End-------------

      await campaignStatus(finalData);
    }
  };
  const handleSubmitFirstStep = () => {
    tagArray();
    const utms = handleUtmsChecks();
    const finalUploadData = {
      campaign_name: campaign_name,
      tag: tag,
      domainId: selectShort?.value,
      team_id: selectdTeam?.value ? selectdTeam?.value : null,
      expiry_state: checked,
      expiry: expiry,
      landing_page: parsedLandingPageUrl,
      ...utms,
      step: step,
      password: password,
      checked1: checked1,
    };
    const { formIsValid, error } = subscriberListValidation(finalUploadData);
    setError(error);

    if (formIsValid) {
      setStep((step) => step + 1);
    }
  };
  const handleCampaignDraft = async (campaign_id) => {
    const utms = handleUtmsChecks();
    tagArray();
    const finalUploadDataToDraft = {
      campaign_id: campaign_id,
      campaign_name: campaign_name,
      tag: tag,
      file:
        file.length > 0 && !fileStatus && fileData.length === 0
          ? file
          : [...fileData, ...file],
      domainId: selectShort?.value,
      team_id: selectdTeam?.value ? selectdTeam?.value : null,
      expiry_state: checked,
      expiry: expiry,
      landing_page: parsedLandingPageUrl,
      ...utms,
      fileStatus: fileStatus,
      password: password,
      checked1: checked1,
    };
    const { formIsValid, error } = draftCampaignDataValidation({
      ...finalUploadDataToDraft,
    });
    setError(error);
    if (formIsValid) {
      await campaignStatus(finalUploadDataToDraft);
    }
  };
  React.useEffect(() => {
    if (saveCampaignStatus) {
      history.push("/campaigns");
    }
    return () => {
      emptyCampaignStatus();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCampaignStatus]);

  const handleSubmit = async (campaign_id) => {
    tagArray();
    const utms = handleUtmsChecks();
    const finalUploadData = {
      campaign_id: campaign_id,
      campaign_name: campaign_name,
      tag: tag,
      file:
        file.length > 0 && !fileStatus && fileData.length === 0
          ? file
          : [...fileData, ...file],

      domainId: selectShort?.value,
      team_id: selectdTeam?.value ? selectdTeam?.value : null,
      expiry_state: checked,
      expiry: expiry,
      landing_page: parsedLandingPageUrl,
      utm_source: utmSource === "-" ? null : utmSource.value,
      utm_medium: utmMedium === "-" ? null : utmMedium.value,
      utm_content: utmContent === "-" ? null : utmContent.value,
      utm_campaign: utmCampaign === "-" ? null : utmCampaign.value,
      password: password,
      checked1: checked1,
      status: "Pending",
      ...utms,
    };

    const { formIsValid, error } = subscriberListValidation({
      ...finalUploadData,
      step: step,
    });
    setError(error);
    if (formIsValid) {
      setfinalData(finalUploadData);
      setFormValidation(true);
      modalToggle();
    }
  };
  const tagArray = () => {
    if (tag !== null) {
      tag = tag?.filter(function (e) {
        return e !== " ";
      });
    } else {
      tag = [];
    }
  };

  //USE EFFECT SECTION
  useEffect(() => {
    if (!checked1) {
      setPassword(null);
    }
  }, [checked1]);
  useEffect(() => {
    if (encryptedPassword && key) {
      decryptDataWithAES(encryptedPassword, key);
    }
  }, [encryptedPassword]);

  useEffect(() => {
    parseLandingPageURL(landingPage);
  }, [landingPage]);
  useEffect(() => {
    getAllTag();
    getShortDomain();
    getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagOptions, shortDomainOptions]);

  useEffect(() => {
    setError((prevError) => ({
      ...prevError,
      title: campaign_name ? "" : prevError.title,
      landingPage: landingPage ? "" : prevError.landingPage,
      expiry: expiry ? "" : prevError.expiry,
      utmSource: utmSource ? "" : prevError.utmSource,
      utmCampaign: utmCampaign ? "" : prevError.utmCampaign,
      utmContent: utmContent ? "" : prevError.utmContent,
      utmMedium: utmMedium ? "" : prevError.utmMedium,
      domainId: selectShort?.value ? "" : prevError.domainId,
      password: password ? "" : prevError.password,
    }));
    if (expiry === "Invalid date") {
      setExpiry("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaign_name,
    landingPage,
    expiry,
    utmSource,
    utmCampaign,
    utmContent,
    utmMedium,
    selectShort,
    password,
  ]);

  useEffect(() => {
    setError((prevError) => ({
      ...prevError,
      expiry: "",
    }));
    if (!checked) {
      setExpiry("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  //Value of the short domain is getting setup here
  if (shortDomainLoadingState && loading) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "200px" }}
      >
        <Spinner
          animation="border"
          color="primary"
          role="status"
          style={{ width: "8rem", height: "8rem", borderWidth: "10px" }}
        />
      </div>
    );
  } else {
    setDropdown();
    setDropdownTwo();
  }

  const stepTitles = ["Step 1: Campaign Details", "Step 2: Upload Files"];

  //Render starts here
  return (
    <div>
      <Row className="mb-2 mb-xl-4 mt-7 mt-lg-0">
        <Col xs="auto">
          <h1 className="mb-3 pl-2 pl-lg-0 page-headers-1 text-dark">
            Create purls
          </h1>
        </Col>
      </Row>
      <Card>
        <div className="m-2">
          <Row className="d-flex justify-content-center flex-column flex-lg-row">
            <h3 className="pl-4 ml-2 mb-1 mt-2 one-rem-font-size d-lg-none">
              {stepTitles[step]}
            </h3>
            <Col sm={12} md className={step == 0 ? "" : "d-none d-lg-block"}>
              <CopyForm
                handleLandingPageInput={handleLandingPageInput}
                campaign_name={campaign_name}
                setCampaignName={setCampaignName}
                checked={checked}
                checked1={checked1}
                setChecked={setChecked}
                setChecked1={setChecked1}
                error={error}
                tagOptions={tagOptions}
                shortDomainOptions={shortDomainOptions}
                shortDomain={short_Domain}
                team={team}
                tag={tag}
                teamsOptions={teamsOptions}
                handleTag={handleTag}
                selectShort={selectShort}
                selectdTeam={selectdTeam}
                setSelectShort={setSelectShort}
                setSelectdTeam={setSelectdTeam}
                handleSubmit={handleSubmit}
                fetchSubscriberLists={fetchSubscriberLists}
                setExpiry={setExpiry}
                expiry={expiry}
                landingPage={landingPage}
                setLandingPage={setLandingPage}
                utmSource={utmSource}
                setUtmSource={setUtmSource}
                utmCampaign={utmCampaign}
                setUtmCampaign={setUtmCampaign}
                utmContent={utmContent}
                setUtmContent={setUtmContent}
                utmMedium={utmMedium}
                setUtmMedium={setUtmMedium}
                setPassword={setPassword}
                password={password}
              />
            </Col>
            <Col sm={12} md className={step == 1 ? " " : "d-none d-lg-block"}>
              <FileUpload
                setFiles={setFileData}
                setError={setError}
                error={error}
                file={file}
                isEdit={true}
                setFileStatus={setFileStatus}
              />
            </Col>
            <Col sm={12}>
              <CardBody className="w-100 d-flex justify-content-end pt-1">
                {step == 1 ? (
                  <div
                    className="d-sm-flex align-items-center justify-content-between w-100 "
                    style={{ gap: "25px" }}
                  >
                    <Button
                      color="primary"
                      href="#top"
                      onClick={() => {
                        setStep((step) => step - 1);
                      }}
                      className="d-flex d-lg-none align-items-center mb-2 mb-sm-0"
                      style={{ width: "fit-content" }}
                    >
                      <ArrowLeft size={17} className="mr-2" /> Back
                    </Button>
                    <div
                      className="d-sm-flex "
                      style={{ gap: "25px", flexGrow: "1" }}
                    >
                      <Button
                        color="primary"
                        className="w-100 btn bg-white text-primary border border-primary mb-sm-0 mb-lg-0 mr-lg-2  mb-2"
                        onClick={() => {
                          handleCampaignDraft(id);
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleSubmit(id);
                        }}
                        className="w-100"
                      >
                        {isValidPublicDomain
                          ? "Publish Trackable Link"
                          : "Publish Campaign"}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Button
                      color="primary"
                      className="d-flex d-lg-none align-items-center"
                      href="#top"
                      onClick={() => {
                        handleSubmitFirstStep();
                      }}
                    >
                      Next <ArrowRight size={17} className="ml-2" />
                    </Button>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Button
                        color="primary"
                        className="btn one-rem-font-size bg-white text-primary border border-primary d-none d-lg-inline-block"
                        onClick={() => {
                          handleCampaignDraft(id);
                        }}
                      >
                        Save
                      </Button>

                      <Button
                        color="primary"
                        onClick={() => {
                          handleSubmit(id);
                        }}
                        className="one-rem-font-size d-none d-lg-block"
                      >
                        {isValidPublicDomain
                          ? "Publish Trackable Link"
                          : "Publish Campaign"}
                      </Button>
                    </div>
                  </div>
                )}
              </CardBody>
            </Col>
          </Row>
        </div>
      </Card>
      <DraftModal
        isOpen={showModal}
        toggle={modalToggle}
        handleUpload={() => {
          uploadData(id);
        }}
        handleCampaignDraft={() => handleCampaignDraft(id)}
      />
    </div>
  );
}

const mapPropsToState = (state) => {
  return {
    loading: state.subscriber.loading,
    shortDomain: state.subscriber.shortDomain,
    shortDomainLoadingState: state.subscriber.shortDomainLoadState,
    ShortDomainState: state.subscriber.shortDomainState,
    uploadState: state.subscriber.uploadState,
    tagState: state.subscriber.tag,
    errorMessage: state.subscriber.error,
    formSubmissionStatus: state.subscriber.formSubmissionStatus,
    retargetCampaignData: state.subscriber.retargetCampaignData,
    retargetCampaignParentId: state.subscriber.retargetCampaignParentId,
    saveCampaignStatus: state.subscriber.saveCampaignStatus,
    fileUploadPath: state.subscriber.fileUploadPath,
    teams: state.subscriber.teams,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createNewList: (data, file) => dispatch(createNewList(data, file)),
    draftACampaign: (data, file) => dispatch(draftACampaign(data, file)),
    getShortDomain: () => dispatch(getShortDomain()),
    getAllTag: () => dispatch(getAllTag()),
    fetchSubscriberLists: () => dispatch(fetchSubscriberLists()),
    campaignStatus: (id, status) => dispatch(campaignStatus(id, status)),
    emptyCampaignStatus: () => dispatch(emptyCampaignStatus()),
    emptySignedUrls: () => dispatch(emptySignedUrls()),
    getTeams: () => dispatch(getTeams()),
  };
};
export default connect(mapPropsToState, mapDispatchToProps)(DraftCampaign);
