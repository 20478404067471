import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone-uploader";
import { UploadCloud } from "react-feather";
import { Card, CardBody, Label, Spinner } from "reactstrap";
import "./custom-dropzone.css";
import { connect } from "react-redux";
import {
  getSignedUrls,
  uploadFiles,
} from "../../../../redux/actions/subscriberActions";
import { fileUploadValidation } from "../../../validation/validation";
import { CheckCircle, XCircle } from "react-feather";
import Instructions from "./Instructions";
import { FileEarmarkCheck as FileIcon } from "@styled-icons/bootstrap/FileEarmarkCheck";
import { isValidPublicDomain } from "../../../../config";

const FileUpload = ({
  fileUploadStart,
  fileUploadPath,
  fileUploadState,
  setFiles,
  setFileStatus,
  uploadErrorState,
  error,
  setError,
  uploadFiles,
  isEdit,
  file,
  retargetCampaignData,
}) => {
  const [uploaded, setUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchor, setAnchor] = useState(false);

  const styles = {
    dropzone: {
      border: "2px solid red",
    },
  };

  const handleSubmit = (files) => {
    const fileData = files.map((g) => g?.file);
    const { formIsValid, error } = fileUploadValidation({ file: fileData });
    setError(error);
    if (formIsValid) {
      uploadFiles(fileData);
    }
  };

  const handleRevert = (e) => {
    const done = e.meta.status === "done";
    if (setFileStatus) setFileStatus(done);

    setError((prev) => ({
      ...prev,
      file: "",
    }));
  };

  useEffect(() => {
    if (fileUploadStart) {
      setAnchor(true);
      setLoading(true);
    }
  }, [fileUploadStart]);

  useEffect(() => {
    if (uploadErrorState) {
      setLoading(false);
      setAnchor(false);
    }
  }, [uploadErrorState]);

  useEffect(() => {
    if (fileUploadState) {
      setLoading(false);
      setUploaded(true);
      setFiles(fileUploadPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadState]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      <CardBody className="w-100">
        <Label className="one-rem-font-size">
          Upload File(s)
          {isEdit ? "" : <span style={{ color: "#d9534f" }}>*</span>}
        </Label>
        {retargetCampaignData?.length > 0 && !isEdit ? (
          <div className="border rounded-lg p-3 text-center mb-3 mb-sm-5 mt-sm-4 mt-2">
            <div className="d-flex justify-content-center align-items-center my-4">
              <FileIcon size={50} color="#4bbf73" />
            </div>
            Your file has been uploaded. Please remember to fill any compulsory
            details for the{" "}
            {isValidPublicDomain ? "trackable link" : "campaign"} and click on
            "Publish {isValidPublicDomain ? "trackable link" : "campaign"}" to
            continue.
          </div>
        ) : (
          <React.Fragment>
            <div>
              <Instructions />
            </div>
            <div>
              <Dropzone
                onSubmit={handleSubmit}
                onChangeStatus={handleRevert}
                inputContent={() => {
                  return (
                    <div>
                      <UploadCloud
                        className="primary-color mb-3"
                        size={50}
                      ></UploadCloud>
                      <h4 className="primary-color">
                        Click or drop files to upload.
                      </h4>
                    </div>
                  );
                }}
                accept=".csv,.xlsx,.xls"
                submitButtonDisabled={anchor}
                disabled={anchor}
                canRemove={!anchor}
                styles={error?.file && styles}
                submitButtonContent="Upload"
              />
              <div
                style={{
                  color: "red",
                  width: "100%",
                }}
              >
                <p>{error?.file}</p>
              </div>
            </div>
            <div className="d-flex w-100 mb-3">
              <strong className="mr-2">File upload status:</strong>
              {loading ? (
                <div className="d-flex align-items-center">
                  <Spinner
                    animation="border"
                    color="warning"
                    role="status"
                    size="sm"
                  />
                  <span className="ml-2" style={{ color: "#f0ad4e" }}>
                    Uploading
                  </span>
                </div>
              ) : uploaded ? (
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#008000" }}
                >
                  <CheckCircle size={15} />
                  <span className="ml-1">Complete</span>
                </div>
              ) : (
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#d9534f" }}
                >
                  <XCircle size={15} />
                  <span className="ml-1">Incomplete</span>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
        {isEdit && (
          <div className="mb-3">
            <Card className="mb-3 bg-light cursor-auto border">
              <CardBody>
                <Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                  }}
                >
                  Existing Files :
                </Label>
                {file &&
                  file.map((fl) => {
                    let fileName = "";
                    if (typeof fl === "string") {
                      fileName = fl;
                    } else {
                      fileName = fl?.file;
                    }
                    return (
                      <li
                        key={fl}
                        style={{
                          fontSize: "0.9rem",
                        }}
                      >
                        {fileName}
                      </li>
                    );
                  })}
              </CardBody>
            </Card>
          </div>
        )}
        {/* {!retargetCampaignData?.length > 0 ? ( */}
        <div
          className="d-flex justify-content-start w-100"
          style={{ fontSize: "0.825rem" }}
        >
          <span>
            <strong>NOTE: </strong>
            Please note that the form requires you to upload relevant files
            before submitting. Kindly ensure that all necessary files are
            attached and uploaded before proceeding with the submission.
          </span>
        </div>
        {/* ) : null} */}
      </CardBody>
    </div>
  );
};

const mapPropsToState = (state) => {
  return {
    fileUploadPath: state.subscriber.fileUploadPath,
    fileUploadStart: state.subscriber.fileUploadStart,
    fileUploadState: state.subscriber.fileUploadState,
    uploadErrorState: state.subscriber.uploadErrorState,
    retargetCampaignData: state.subscriber.retargetCampaignData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSignedUrls: (data) => dispatch(getSignedUrls(data)),
    uploadFiles: (files) => dispatch(uploadFiles(files)),
  };
};

export default connect(mapPropsToState, mapDispatchToProps)(FileUpload);
