import React, { useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationComp = ({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
  setPostPerPage,
  postsInCurrentPage,
}) => {
  const pageNumbers = [];
  let lastPage;

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
    lastPage = i;
  }

  const [shownNumbers, setShownNumbers] = useState(5);

  React.useEffect(() => {
    if (currentPage === 1) {
      setShownNumbers(5);
    } else if (
      currentPage !== 1 &&
      currentPage !== 2 &&
      currentPage != lastPage &&
      currentPage != lastPage - 1
    ) {
      setShownNumbers(currentPage + 2);
    }
  }, [currentPage]);
  return (
    <div>
      {totalPosts > 10 ? (
        <div className="d-flex align-items-start justify-content-between flex-column flex-md-row">
          {postsInCurrentPage < postsPerPage && pageNumbers.length > 1 ? (
            ""
          ) : (
            <div className="d-flex justify-content-start ml-md-4 mb-2 mb-md-0 w-100">
              <div className="mx-auto mx-md-0">
                <select
                  className="form-control mb-0"
                  value={postsPerPage}
                  onChange={(e) => {
                    setPostPerPage(e.target.value);
                    paginate(1);
                    setShownNumbers(5);
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
          )}
          <ul className="pagination mr-md-4 mx-auto">
            {shownNumbers < 6 ? null : (
              <PaginationItem>
                <PaginationLink
                  first
                  onClick={() => {
                    paginate(1);
                    setShownNumbers(5);
                  }}
                />
              </PaginationItem>
            )}
            {currentPage === 1 ? null : (
              <PaginationItem>
                <PaginationLink
                  previous
                  onClick={() => {
                    if (currentPage > 1) {
                      paginate(currentPage - 1);
                      if (
                        currentPage - 1 !== 1 &&
                        currentPage - 1 !== 2 &&
                        currentPage - 1 !== lastPage - 1 &&
                        currentPage - 1 !== lastPage - 2
                      ) {
                        setShownNumbers(shownNumbers - 1);
                      }
                    }
                  }}
                />
              </PaginationItem>
            )}
            {pageNumbers.slice(shownNumbers - 5, shownNumbers).map((number) => (
              <Pagination aria-label="Page navigation example" key={number}>
                <PaginationItem
                  onClick={() => {
                    paginate(number);
                    if (
                      number < currentPage &&
                      number !== 1 &&
                      number !== lastPage &&
                      number !== lastPage - 1 &&
                      number !== lastPage - 2 &&
                      currentPage !== 3
                    ) {
                      if (number === lastPage - 3) {
                        setShownNumbers(shownNumbers - 1);
                      } else {
                        if (number < currentPage - 1 && number !== 2) {
                          setShownNumbers(shownNumbers - 2);
                        } else {
                          setShownNumbers(shownNumbers - 1);
                        }
                      }
                    }
                    if (
                      number > currentPage &&
                      number !== 2 &&
                      number !== 3 &&
                      number !== lastPage &&
                      currentPage !== lastPage - 2
                    ) {
                      if (number === 4) {
                        setShownNumbers(shownNumbers + 1);
                      } else {
                        if (
                          number > currentPage + 1 &&
                          number !== lastPage - 1
                        ) {
                          setShownNumbers(shownNumbers + 2);
                        } else {
                          setShownNumbers(shownNumbers + 1);
                        }
                      }
                    }
                  }}
                  active={currentPage === number}
                >
                  <PaginationLink>{number}</PaginationLink>
                </PaginationItem>
              </Pagination>
            ))}
            {lastPage === currentPage ? null : (
              <PaginationItem>
                <PaginationLink
                  next
                  onClick={() => {
                    if (currentPage < lastPage) {
                      paginate(currentPage + 1);
                      if (
                        currentPage + 1 !== 2 &&
                        currentPage + 1 !== 3 &&
                        currentPage + 1 !== lastPage &&
                        currentPage + 1 !== lastPage - 1
                      ) {
                        setShownNumbers(shownNumbers + 1);
                      }
                    }
                  }}
                />
              </PaginationItem>
            )}
            {lastPage <= shownNumbers || shownNumbers >= totalPosts ? null : (
              <PaginationItem>
                <PaginationLink
                  size="lg"
                  last
                  onClick={() => {
                    if (currentPage < lastPage) {
                      paginate(lastPage);
                      setShownNumbers(lastPage);
                    }
                  }}
                />
              </PaginationItem>
            )}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default PaginationComp;
