import React, { useState, useEffect } from "react";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Col,
  Row,
  Input,
  FormGroup,
  Label,
  CustomInput,
} from "reactstrap";
import { connect } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { ArrowLeft } from "react-feather";

import Header from "./OnboardingHeader";
import { StepThreeValidation } from "../validation/validation";
import { onboarding } from "../../redux/actions/authActions";
const StepThree = ({ StepThreeCall, onboardingDone, onboardingData }) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [radio, setRadio] = useState("");
  const [error, setError] = useState({ email: "", mfa: "" });
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user?.authorization?.split("=")[1]?.split(";")[0];
  const decoded = token ? jwtDecode(token) : "";

  const stepThreeUpload = () => {
    const stepThreeData = {
      alerts_email: email,
      mfa_type: radio,
    };
    const { formIsValid, error } = StepThreeValidation(stepThreeData);
    if (formIsValid) {
      StepThreeCall(stepThreeData, "three");
    }
    setError(error);
  };
  useEffect(() => {
    if (onboardingDone) {
      history.push("/auth/2fa-request");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingDone]);

  if (user) {
    if (decoded?.role === "Admin" && decoded?.onboarding === false) {
      if (
        !onboardingData?.campaign_expiry_fallback_url == "" &&
        !onboardingData?.auto_delete_purl_days == ""
      ) {
        return (
          <Row className="h-100 my-auto mx-2">
            <Col sm="10" md="8" lg="6" className="mx-auto my-6 d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-start bg-white py-3">
                  <div className="px-3 px-md-5 py-3">
                    <Header step={3} />
                    <div>
                      <p className="text-center h4 mb-4">
                        Security and Notifications
                      </p>
                      <div>
                        <form className="mb-2">
                          <Label className="mb-1 one-rem-font-size">
                            Email Address for Error/Alerts:{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            className="custom-input"
                            style={
                              error?.email ? { border: "1px solid red" } : {}
                            }
                            type="email"
                            bsSize="lg"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              error["email"] = "";
                            }}
                            placeholder="Enter Email Address"
                          />
                          {error?.email && (
                            <p className="mb-0" style={{ color: "red" }}>
                              {error?.email}
                            </p>
                          )}
                        </form>
                        <p>
                          Provide an email address where error alerts and
                          notifications will be sent. You'll receive important
                          updates about your campaigns and any potential issues.
                        </p>
                      </div>
                      <div>
                        <Label className="mb-2 one-rem-font-size">
                          Two-Factor Authentication:{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <FormGroup className="pl-0 pl-sm-3 ">
                          <div className="mb-3 ">
                            <CustomInput
                              type="radio"
                              id="customRadio"
                              name="customRadio"
                              label="Self"
                              className="mb-2 one-rem-font-size"
                              value="self"
                              onChange={(event) => {
                                setRadio(event.target.value);
                                error["mfa"] = "";
                              }}
                            />
                            <p className="mb-0 pl-4 ml-1">
                              Enable two-factor authentication (2FA) for
                              individual users.
                            </p>
                          </div>
                          {/* <div className="mb-3">
                            <CustomInput
                              type="radio"
                              id="customRadio2"
                              name="customRadio"
                              label="Everyone"
                              className="mb-2 one-rem-font-size"
                              value="everyone"
                              onChange={(event) => {
                                setRadio(event.target.value);
                                error["mfa"] = "";
                              }}
                            />
                            <p className="mb-0 pl-4 ml-1">
                              Require 2FA for all users during onboarding.
                            </p>
                          </div> */}
                          <div className="mb-3">
                            <CustomInput
                              type="radio"
                              id="customRadio3"
                              name="customRadio"
                              label="Disabled"
                              className="mb-2 one-rem-font-size"
                              value="disabled"
                              onChange={(event) => {
                                setRadio(event.target.value);
                                error["mfa"] = "";
                              }}
                            />
                            <p className="mb-0 pl-4 ml-1">
                              Users will not need to set up 2FA.
                            </p>
                          </div>
                        </FormGroup>
                        {error?.mfa && (
                          <p className="mb-0" style={{ color: "red" }}>
                            {error?.mfa}
                          </p>
                        )}
                        <p>
                          Choose your preferred level of security for client
                          onboarding.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center ">
                      <Button
                        className="px-3"
                        color="primary d-flex align-items-center"
                        outline
                        onClick={() => history.push("/onboarding/step2")}
                      >
                        <span className="mr-1" style={{ lineHeight: "0" }}>
                          <ArrowLeft size={17} />
                        </span>
                        Back
                      </Button>
                      <Button
                        color="primary "
                        onClick={() => stepThreeUpload()}
                      >
                        Finish Onboarding
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        );
      } else {
        return <Redirect to="/onboarding/step1" />;
      }
    } else {
      return <Redirect to="/dashboard" />;
    }
  } else {
    return <Redirect to="/auth/sign-in" />;
  }
};
const mapStateToProps = (state) => {
  return {
    onboardingData: state.auth.onboardingData,
    onboardingDone: state.auth.onboarding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    StepThreeCall: (data, step) => dispatch(onboarding(data, step)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepThree);
