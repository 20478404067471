import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Security } from "styled-icons/material";
import { Link } from "react-feather";
import { Users } from "styled-icons/fa-solid";

const Header = ({ step }) => {
  const history = useHistory();
  return (
    <div className="mb-4">
      <p className="h2 text-center mb-3">Welcome to purl</p>
      <div className="d-flex align-items-center justify-content-center">
        <div
          className={`rounded-circle d-flex align-items-center justify-content-center ${
            step === 1
              ? "border-primary bg-primary"
              : "border-success bg-success onboarding-div cursor-pointer"
          } `}
          style={{
            width: "45px",
            height: "45px",
          }}
        >
          {step === 1 ? (
            <span className="text-white">
              <Link size={22} />
            </span>
          ) : (
            <span
              className="text-white"
              onClick={() => history.push("/onboarding/step1")}
            >
              <Link size={22} />
            </span>
          )}
        </div>
        <div
          className={`custom-bar-width custom-border mx-1 ${
            step == 1 ? "" : "border-success"
          } `}
        ></div>
        <div
          className={`rounded-circle  d-flex align-items-center justify-content-center custom-wide-border ${
            step == 2 ? "border-primary bg-primary" : ""
          } ${
            step === 3
              ? "border-success bg-success cursor-pointer onboarding-div"
              : ""
          }`}
          style={{
            width: "45px",
            height: "45px",
          }}
        >
          {step === 3 ? (
            <span
              className={`${step == 1 ? "gray-color" : "text-white"}`}
              onClick={() => history.push("/onboarding/step2")}
            >
              <Users size={22} />
            </span>
          ) : (
            <span className={`${step == 1 ? "gray-color" : "text-white"}`}>
              <Users size={22} />
            </span>
          )}
        </div>

        <div
          className={`custom-bar-width custom-border mx-1 ${
            step === 3 ? "border-success" : ""
          }`}
        ></div>
        <div
          className={`rounded-circle  d-flex custom-wide-border align-items-center justify-content-center ${
            step === 3 ? "bg-primary border-primary" : ""
          }`}
          style={{
            width: "45px",
            height: "45px",
          }}
        >
          <span className={`${step === 3 ? "text-white" : "gray-color"} `}>
            <Security size={22} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
