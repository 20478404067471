import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { connect } from "react-redux";
import { customStyles, errorStyles } from "../../../../components/CustomStyle";
import { shortUrlValidation } from "../../../validation/validation";
import { emptyAddNewShortUrlStatus } from "../../../../redux/actions/subscriberActions";
import { createShortUrl } from "../../../../redux/actions/subscriberActions";
import { useHistory } from "react-router-dom";
import { isValidPublicDomain } from "../../../../config";
import { InfoCircleFill } from "@styled-icons/bootstrap/InfoCircleFill";
const ShortUrlForm = ({
  longUrl,
  setLongUrl,
  shortDomainOptions,
  setSelectShort,
  selectShort,
  addNewShortUrlStatus,
  emptyAddNewShortUrlStatus,
  createShortUrl,
  shortDomainState,
  singlePurlLimit,
  name,
  setName,
}) => {
  const [error, setError] = useState({
    name: "",
    longUrl: "",
    domainId: "",
  });
  const [isMobile, setIsMobile] = useState(false);
  const [domainOptions, setDomainOptions] = useState([]);

  useEffect(() => {
    setDomainOptions(
      [{ value: 0, label: "Add custom domain" }].concat(shortDomainOptions)
    );
  }, [shortDomainState]);

  useEffect(() => {
    if (selectShort?.value === 0) {
      history.push(
        isValidPublicDomain ? "/domains" : "/user-settings/teams/domains"
      );
    }
  }, [selectShort]);

  const history = useHistory();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const buttonStyle = isMobile ? { width: "100%" } : {};

  useEffect(() => {
    setError((prevError) => ({
      ...prevError,
      name: name ? "" : prevError.name,
      longUrl: longUrl ? "" : prevError.longUrl,
      domainId: selectShort?.value ? "" : prevError.domainId,
    }));
  }, [longUrl, selectShort, name]);
  useEffect(() => {
    if (addNewShortUrlStatus && addNewShortUrlStatus === 200) {
      history.push("/links");
    }
    return () => {
      emptyAddNewShortUrlStatus();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewShortUrlStatus]);

  //Functions:-
  const handleSubmit = () => {
    const finalUploadData = {
      link_name: name,
      long_url: longUrl,
      short_domain_id: selectShort?.value,
    };
    const { formIsValid, error } = shortUrlValidation({
      ...finalUploadData,
    });
    setError(error);

    if (formIsValid) {
      createShortUrl(finalUploadData);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 pl-2 pr-2">
      <CardBody className="w-100">
        <Form id="formElem">
          <FormGroup>
            <Label className="one-rem-font-size">
              Give a name to your Short URL
              <span style={{ color: "#d9534f" }}>*</span>
              <InfoCircleFill
                size={16}
                className="cursor-pointer primary-color ml-2 mb-1"
                id="nameInfo"
                style={{
                  border: "none",
                  outline: "none",
                }}
              />
              <UncontrolledTooltip placement="top" target="nameInfo">
                This is a required field when creating a short URL because this
                name will help you easily analyse the link's performance on the
                link analytics page
              </UncontrolledTooltip>
            </Label>
            <Input
              className="custom-input w-100"
              bsSize="lg"
              type="text"
              name="name"
              id="name"
              value={name}
              placeholder="Give a name to your short url"
              onChange={(e) => setName(e.target.value)}
              style={error?.name ? { border: "1px solid red" } : {}}
              required
            />
            <div
              style={{
                color: "red",
                width: "100%",
              }}
            >
              <p>{error?.name}</p>
            </div>
          </FormGroup>
          <FormGroup>
            <Label className="one-rem-font-size">
              Long URL<span style={{ color: "#d9534f" }}>*</span>
            </Label>
            <Input
              className="custom-input w-100"
              bsSize="lg"
              type="text"
              name="longUrl"
              id="longUrl"
              value={longUrl}
              placeholder="Paste your URL here"
              onChange={(e) => setLongUrl(e.target.value)}
              style={error?.longUrl ? { border: "1px solid red" } : {}}
              required
            />
            <div
              style={{
                color: "red",
                width: "100%",
              }}
            >
              <p>{error?.longUrl}</p>
            </div>
          </FormGroup>

          <FormGroup>
            <Label className="one-rem-font-size">
              Domain<span style={{ color: "#d9534f" }}>*</span>
            </Label>
            <div style={{ width: "100%" }}>
              <Select
                styles={error?.domainId ? errorStyles : customStyles}
                className="react-select-container w-100"
                placeholder="Select Domain..."
                options={domainOptions}
                onChange={(e) => {
                  setSelectShort(e);
                }}
                value={selectShort}
                isClearable
              />
            </div>
            <div
              style={{
                color: "red",
                width: "100%",
              }}
            >
              <p>{error?.domainId}</p>
            </div>
          </FormGroup>
        </Form>
        <Button
          color="primary"
          onClick={() => {
            handleSubmit();
          }}
          className="one-rem-font-size d-block"
          style={
            ({ cursor: !singlePurlLimit ? "not-allowed" : "pointer" },
            buttonStyle)
          }
          disabled={!singlePurlLimit}
        >
          Create your link
        </Button>
      </CardBody>
    </div>
  );
};

const mapPropsToState = (state) => {
  return {
    addNewShortUrlStatus: state.subscriber.addNewShortUrlStatus,
    shortDomainState: state.subscriber.shortDomainState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    emptyAddNewShortUrlStatus: () => dispatch(emptyAddNewShortUrlStatus()),
    createShortUrl: (data) => dispatch(createShortUrl(data)),
  };
};

export default connect(mapPropsToState, mapDispatchToProps)(ShortUrlForm);
