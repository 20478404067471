import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { requestAccount } from "../../redux/actions/authActions";
import { RegisterFormValidation } from "../validation/validation";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import { countryCodes } from "../../components/countryCodeObject";
import { customStyles, PhoneInput } from "../../components/CustomStyle";

const SignUp = ({ requestAccount, requestState }) => {
  //always add props for the redux
  const [req, setReq] = useState({
    full_name: "",
    email: "",
    phone: "",
  });
  const [number, setNumber] = useState("");
  const [countryCode, setCountryCode] = useState({ value: "", label: "" });
  /*Error States */
  const [error, setError] = useState({
    full_name: "",
    email: "",
    phone: "",
    country_code: "",
  });
  const history = useHistory();
  const handleChange = (e) => {
    const { id, value } = e.target;
    setReq((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const handleReq = (data, formIsValid) => {
    if (formIsValid) {
      requestAccount(data);
    }
  };

  useEffect(() => {
    if (requestState) {
      history.push("/requestAccount/200");
    }
  });

  const newPageTitle = "purl - Register account";
  document.title = newPageTitle;
  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Get started</h1>
        <p className="lead">
          Start creating the best possible user experience for your customers
        </p>
      </div>
      <div className="m-sm-4 mt-4 mb-4">
        <Form>
          <FormGroup>
            <Label>
              Name<span className="text-danger">*</span>
            </Label>
            <Input
              style={{ height: "38px" }}
              bsSize="lg"
              type="text"
              name="name"
              id="full_name"
              value={req.full_name}
              placeholder="Enter your full name"
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <span style={{ color: "red" }}>
              <p>{error.full_name}</p>
            </span>
          </FormGroup>
          <div className="d-block d-sm-flex h-auto">
            <FormGroup
              className="w-100 w-sm-75 mb-0"
              style={{
                marginRight: "15px",
              }}
            >
              <Label>
                Country<span className="text-danger">*</span>
              </Label>
              <Select
                id="country_code"
                options={countryCodes}
                styles={customStyles}
                value={countryCode.value}
                onChange={(e) => {
                  setCountryCode(e.value);
                }}
                placeholder="Select country"
              />
              <span style={{ color: "red" }}>
                <p>{error.country_code}</p>
              </span>
            </FormGroup>
            <FormGroup style={{ width: "100%", marginBottom: "0px" }}>
              <Label>
                Phone<span className="text-danger">*</span>
              </Label>
              <PhoneInput
                style={{ height: "38px" }}
                bsSize="lg"
                type="number"
                name="phone"
                id="phone"
                value={number || ""}
                placeholder="Enter your phone number"
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
              <span style={{ color: "red" }}>
                <p>{error.phone}</p>
              </span>
            </FormGroup>
          </div>
          <FormGroup>
            <Label>
              Email<span className="text-danger">*</span>
            </Label>
            <Input
              style={{ height: "38px" }}
              bsSize="lg"
              type="email"
              name="email"
              id="email"
              value={req.email || ""}
              placeholder="Enter your email"
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <span style={{ color: "red" }}>
              <p>{error.email}</p>
            </span>
          </FormGroup>
          <div className="text-center mt-3 pt-3">
            <Button
              color="primary"
              size="lg"
              onClick={() => {
                req.phone = countryCode + "-" + number;
                const { formIsValid, errors } = RegisterFormValidation(req);
                //
                setError(errors);
                handleReq(req, formIsValid);
              }}
            >
              Sign up
            </Button>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    request: state.auth.request_account,
    requestState: state.auth.request_account.requestState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestAccount: (data) => dispatch(requestAccount(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
