export const BACKEND_ENDPOINT = "https://test-api-server.p-url.id";
const PUBLIC_HOST = "app-testing";

const currentDomain = window.location.hostname.split(".")[0];

export const isValidPublicDomain = currentDomain === PUBLIC_HOST;
export const purlPlans = [
  {
    0: "shellpackage",
    1: "oysterpackage",
    2: "pearlpackage",
  },
];
export const purlPackages = [
  { plan: "oysterpackage", amount: "£14.99" },
  { plan: "pearlpackage", amount: "£19.99" },
];
