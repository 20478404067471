import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { connect } from "react-redux";

import { Button, CardBody, CardHeader, CardTitle, Table } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import RetargetModal from "../../../../components/RetargetModal";

const CustomDonoutgraph = ({ theme, Data, tabledata, title }) => {
  const [TabelTitle, setTableTitle] = useState("");
  const [comment, setComment] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const modalToggle = () => setShowModal(!showModal);

  useEffect(() => {
    if (title == "Browser") {
      setTableTitle("Browser Name");
      setComment("No Browsers/Clicks recorded in current date range!");
      setButtonText("Click to retarget via Browser")
    } else if (title == "Device OS") {
      setTableTitle("OS");
      setComment("No OS/Clicks recorded in current date range!");
      setButtonText("Click to retarget via Device")
    } else if (title == "Region") {
      setTableTitle("Region");
      setComment("No Region/Clicks recorded in current date range!");
      setButtonText("Click to retarget via Region")
    } else if (title == "Country") {
      setTableTitle("Country");
      setComment("No Country/Clicks recorded in current date range!");
      setButtonText("Click to retarget via Country")
    }
  }, [title]);

  const data = {
    labels: Data.label,
    datasets: [
      {
        data: Data.clicks,
        backgroundColor: [
          theme.primary,
          theme.warning,
          theme.danger,
          theme.success,
          theme.secondary,
          "#ecdd7bff",
          "#4ecdc4ff",
          "#cd4631ff",
          "#5438dcff",
          "#2e2e3aff",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 75,
    legend: {
      display: false,
    },
  };

  let bgColor = [
    theme.primary,
    theme.warning,
    theme.danger,
    theme.success,
    theme.secondary,
    "#ecdd7bff",
    "#4ecdc4ff",
    "#cd4631ff",
    "#5438dcff",
    "#2e2e3aff",
  ];

  return (
    <React.Fragment>
      <div className="flex-fill w-100 h-100 border rounded">
        <CardHeader className="pb-0 mb-0">
          <CardTitle tag="h5" className="text-center mb-0 font-weight-bold">
            {title}
          </CardTitle>
        </CardHeader>
        <CardBody className="d-flex mt-0 w-100">
          <div className="align-self-center w-100">
            <div className="chart chart-xs h-100">
              <Pie data={data} options={options} />
            </div>
            <div className="overflow-auto">
              <Table className="mb-0 mt-3" striped>
                <thead>
                  <tr>
                    <th className="text-nowrap">{TabelTitle}</th>
                    <th className="text-right">Clicks</th>
                  </tr>
                </thead>
                <tbody>
                  {tabledata?.length > 0 ? (
                    tabledata?.map((e, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-nowrap">
                            <FontAwesomeIcon
                              icon={faSquare}
                              color={bgColor[index]}
                            />{" "}
                            {e.name}
                          </td>
                          <td className="text-right text-blue">{e.clicks}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="2" className="text-center">
                        {comment}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

          </div>
        </CardBody>
        {tabledata?.length > 0 ? (
          <div className="d-flex justify-content-center align-items-center mt-2 mb-3">
            <Button
              color="primary"
              className="px-4"
              size="lg"
              onClick={modalToggle}
            >
              {buttonText}
            </Button>
          </div>
        ) : null}
      </div>
      <RetargetModal
        isOpen={showModal}
        toggle={modalToggle}
        title={title}
        optionData={tabledata}
      />
    </React.Fragment>
  );
};

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(CustomDonoutgraph);
