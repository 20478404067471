import React, { useState, useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import { CardBody, CardHeader, CardTitle, Table, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { Calendar as CalendarIcon } from "@styled-icons/boxicons-regular/Calendar";
import Select from "react-select";
import { customStyles } from "../../../../components/CustomStyle";
import moment from "moment";
import { isValidPublicDomain } from "../../../../config";
const CampaignComparisonBarChart = ({
  theme,
  campaignName,
  campaignClicks,
  campaignShortUrls,
  campaignDropdownData,
  campaignId,
}) => {
  const [totalClickComparisonData, setTotalClickComparisonData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);

  const [isLogarithmic, setIsLogarithmic] = useState(false);
  const campaignOptions = useMemo(() => {
    return campaignDropdownData
      ?.filter((option) => option.campaign_id != campaignId)
      ?.map((value) => ({
        label: value?.campaign_name,
        value: value?.campaign_id,
        date: moment(value?.createdAt).format("DD MMM YYYY, h:mm:s A"),
        clicks: value?.clicks,
        shorturls: value?.shorturls,
      }));
  }, [campaignDropdownData, campaignId]);
  useEffect(() => {
    setTotalClickComparisonData({
      clicks: [campaignClicks],
      shorturls: [campaignShortUrls],
      campaignName: [campaignName],
    });
  }, [campaignClicks, campaignName, campaignShortUrls]);
  useEffect(() => {
    const addNewData = (newData) => {
      newData.forEach((item) => {
        if (!totalClickComparisonData.campaignName.includes(item.label)) {
          setTotalClickComparisonData((prevState) => ({
            clicks: [...prevState.clicks, item.clicks],
            shorturls: [...prevState.shorturls, item.shorturls],
            campaignName: [...prevState.campaignName, item.label],
          }));
        }
      });
    };
    if (selectedCampaign && selectedCampaign.length > 0) {
      addNewData(selectedCampaign);
    }
  }, [selectedCampaign, totalClickComparisonData.campaignName]);
  useEffect(() => {
    setTableData(
      totalClickComparisonData?.clicks
        ?.map((clicks, index) => ({
          clicks,
          shorturls: totalClickComparisonData?.shorturls[index],
          name: totalClickComparisonData?.campaignName[index],
        }))
        .filter((data) => data.name !== undefined)
    );

    const largeClicks = totalClickComparisonData?.clicks?.some(
      (click) => click > 500
    );
    const largeShorturls = totalClickComparisonData?.shorturls?.some(
      (shorturl) => shorturl > 500
    );
    if (largeClicks || largeShorturls) {
      setIsLogarithmic(true);
    } else {
      setIsLogarithmic(false);
    }
  }, [totalClickComparisonData || selectedCampaign]);
  const removeDataByLabel = (labelToRemove) => {
    setTotalClickComparisonData((prevState) => {
      const indexToRemove = prevState.campaignName.indexOf(labelToRemove);
      if (indexToRemove !== -1) {
        return {
          clicks: prevState.clicks.filter((_, i) => i !== indexToRemove),
          shorturls: prevState.shorturls.filter((_, i) => i !== indexToRemove),
          campaignName: prevState.campaignName.filter(
            (_, i) => i !== indexToRemove
          ),
        };
      }
      return prevState;
    });
  };
  const handleChange = (selectedValues) => {
    if (selectedValues?.length > 3) {
      // If more than 3 selected, remove the last selected value
      selectedValues.pop();
    }
    const removedCampaigns = selectedCampaign?.filter(
      (campaign) => !selectedValues?.includes(campaign)
    );
    if (removedCampaigns?.length > 0) {
      removedCampaigns?.forEach((removedCampaign) => {
        removeDataByLabel(removedCampaign.label);
      });
    }
    setSelectedCampaign(selectedValues);
  };
  const colorConstants = [
    theme.primary,
    theme.danger,
    theme.warning,
    theme.success,
  ];

  const lightColorConstants = [
    theme.primary + "85",
    theme.danger + "85",
    theme.warning + "85",
    theme.success + "85",
  ];

  const filteredLabels = totalClickComparisonData?.campaignName?.filter(
    (label) => label !== undefined
  );
  const filteredShortUrls = totalClickComparisonData?.shorturls?.filter(
    (_, index) => totalClickComparisonData?.campaignName[index] !== undefined
  );
  const filteredClicks = totalClickComparisonData?.clicks?.filter(
    (_, index) => totalClickComparisonData?.campaignName[index] !== undefined
  );

  const data = {
    labels: filteredLabels,
    datasets: [
      {
        label: "purls Created",
        backgroundColor: lightColorConstants,
        borderColor: lightColorConstants,
        hoverBackgroundColor: lightColorConstants,
        hoverBorderColor: lightColorConstants,
        data: filteredShortUrls,
      },
      {
        label: "Total Clicks",
        backgroundColor: colorConstants,
        borderColor: colorConstants,
        hoverBackgroundColor: colorConstants,
        hoverBorderColor: colorConstants,
        data: filteredClicks,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      yAxes: [
        {
          type: isLogarithmic ? "logarithmic" : "linear",
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff",
          },
          stacked: false,
          ticks: {
            beginAtZero: true,
            stepSize: 2,
            maxTicksLimit: 7,
            autoSkip: true,
            callback: function (value) {
              return value.toLocaleString();
            },
          },
        },
      ],
      xAxes: [
        {
          barPercentage:
            totalClickComparisonData?.clicks?.length > 2 ? 0.9 : 0.7,
          categoryPercentage: 0.7,
          stacked: false,
          gridLines: {
            display: true,
            color: "rgba(0,0,0,0.05)",
            fontColor: "#fff",
          },
          reverse: true,
          ticks: {
            display: false,
          },
        },
      ],
    },
  };
  const CustomOption = ({ innerProps, data }) => (
    <div {...innerProps} className="d-flex flex-column">
      <div className="custom-drop-down">
        <span className="inner-label">{data?.label}</span>
        <span className="inner-date text-muted">
          <CalendarIcon size={13} className="mr-1" />
          {data?.date}
        </span>
      </div>
      <div className="separator" />
    </div>
  );
  return (
    <Card className={`${campaignName && "shadow-none"}`}>
      <div
        className={`flex-fill ${campaignName ? "border rounded " : ""} w-100`}
      >
        <CardHeader>
          <CardTitle tag="h5" className="text-center mb-0 font-weight-bold">
            Performance Progress
          </CardTitle>
        </CardHeader>
        <div className="py-2 px-4">
          {campaignName ? (
            <span>
              {!isValidPublicDomain ? (
                <>
                  Compare your Campaign performance progress with up to 3
                  previous Campaigns
                </>
              ) : (
                <>
                  Compare your Trackable Links performance progress with up to 3
                  previous Trackable Links
                </>
              )}
            </span>
          ) : (
            <span>
              {!isValidPublicDomain ? (
                <>
                  Compare your campaign performance progress.<br></br> Please
                  select upto 3 campaigns
                </>
              ) : (
                <>
                  Compare your trackable links performance progress.<br></br>{" "}
                  Please select upto 3 trackable links
                </>
              )}
            </span>
          )}
        </div>
        <CardBody className="py-2  pb-4">
          <Select
            isMulti
            onChange={handleChange}
            options={campaignOptions}
            value={selectedCampaign}
            styles={customStyles}
            placeholder={
              !isValidPublicDomain
                ? "Select up to 3 campaigns"
                : "Select up to 3 trackable links"
            }
            isSearchable
            isClearable
            components={{
              Option: CustomOption,
            }}
          />
        </CardBody>
        {campaignName || selectedCampaign?.length > 0 ? (
          <>
            <CardBody className="d-flex">
              <div
                className={`align-self-center ${
                  totalClickComparisonData?.clicks?.length > 2 ? "w-100" : ""
                }`}
              >
                <div className="chart chart-lg">
                  <Bar data={data} options={options} />
                </div>
              </div>
            </CardBody>
            <CardBody className="pt-0">
              <div className="table-overflow-custom overflow-auto w-100">
                <Table className="mb-0 mt-3" striped bordered>
                  <thead>
                    <tr>
                      <th className="text-nowrap">
                        {!isValidPublicDomain ? "Campaign" : "Trackable links"}
                      </th>
                      <th className="text-right" style={{ minWidth: "115px" }}>
                        purls Created
                      </th>
                      <th className="text-right" style={{ minWidth: "100px" }}>
                        Total Clicks
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.length > 0 ? (
                      tableData?.map((e, index) => {
                        return (
                          <tr key={index}>
                            <td
                              title={e?.name}
                              className="text-nowrap"
                              style={{
                                minWidth: "50px",
                                maxWidth: "215px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faSquare}
                                color={colorConstants[index]}
                              />{" "}
                              {e?.name}
                            </td>

                            <td className="text-right text-blue">
                              {e?.shorturls}
                            </td>
                            <td className="text-right text-blue">
                              {e?.clicks}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="2" className="text-center">
                          No clicks data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </>
        ) : null}
      </div>
    </Card>
  );
};
export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(CampaignComparisonBarChart);
