import React from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import { CardBody, CardHeader, CardTitle } from "reactstrap";

const RecentPerformance = ({ theme, date_array, click_array, step_size }) => {
  if (date_array?.length == 1 || date_array?.length == 2) {
    const padArray = (arr, fillValue = "") => {
      const padding = new Array(1).fill(fillValue);
      return [...padding, ...arr, ...padding];
    };
    date_array = padArray(date_array, "");
    click_array = padArray(click_array, 0);
  }
  const data = {
    labels: date_array,
    datasets: [
      {
        label: "Clicks",
        yAxisID: "Clicks",
        fill: true,
        backgroundColor: "#3b82ec36",
        borderColor: theme.primary,
        name: "Clicks",
        data: click_array,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      onClick: (e) => e.stopPropagation(),
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)",
          },
        },
      ],
      yAxes: [
        {
          id: "Clicks",
          type: "linear",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: "Clicks",
            fontColor: theme.primary,
          },
          ticks: {
            stepSize: step_size,
            beginAtZero: true,
            fontColor: theme.primary,
            callback: function (value) {
              return Math.round(value);
            },
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff",
          },
        },
      ],
    },
  };

  return (
    <div className="flex-fill w-100 border rounded">
      <CardHeader>
        <CardTitle className="text-center font-weight-bold mb-0" tag="h5">
          Recent Performance
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className="chart chart-lg dashboard-chart-height">
          <Line data={data} options={options} type="line" />
        </div>
      </CardBody>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme.currentTheme,
  };
};

export default connect(mapStateToProps, null)(RecentPerformance);
