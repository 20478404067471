import React from "react";
import { Card, CardBody } from "reactstrap";
import { Info } from "react-feather";
import { downloadTemplate } from '../../../../redux/actions/subscriberActions';

const Instructions = () => {
    return (
        <div>
            <Card className="mb-3 bg-light cursor-auto border">
                <CardBody className="pt-3 mb-n3">
                    <span style={{ paddingRight: "10px" }}>
                        <Info size={28} className="text-info primary-color" />
                    </span>
                    File Upload Instructions.
                    <ul className="ml-3 mb-n2">
                        <li>Only csv, xlsx, xls files are accepted.</li>
                        <li>Single file size limit: 100MB; multiple files limit: total 140MB.</li>
                        <li>Ensure spreadsheet has one column with unique IDs (e.g., email, phone). Ensure "UniqueID" header is assigned to cell A1, present in the first sheet.</li>
                        <li>Headers and UniqueIDs are distinct; exclude special characters except (-) and (_). Maximum of 10 headers are accepted, including UniqueID; excess headers are ignored.</li>
                        <li>Header columns must be consecutive; null or empty headers disregarded.</li>
                        <li>Ensure correctness by using our provided template.
                            <span
                                style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    paddingLeft: "5px",
                                    cursor: "pointer",
                                }}
                                onClick={async () => await downloadTemplate()}
                            >
                                Download template
                            </span>
                        </li>
                    </ul>
                </CardBody>
            </Card>
        </div>
    )
}

export default Instructions;
