import React from "react";
import { jwtDecode } from "jwt-decode";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { isValidPublicDomain } from "../config";

import { Redirect } from "react-router-dom";
const Dashboard = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user?.authorization?.split("=")[1]?.split(";")[0];
  const decoded = token ? jwtDecode(token) : "";
  if (!user && isValidPublicDomain) {
    return <Redirect to="/auth/sign-in" />;
  }
  if (!user || !decoded?.otpVerified) {
    return <Redirect to="/auth/sign-in" />;
  } else {
    return (
      <React.Fragment>
        <Wrapper>
          <Sidebar />
          <Main>
            <Navbar />
            <Content>{children}</Content>
            <Footer />
          </Main>
        </Wrapper>
      </React.Fragment>
    );
  }
};

export default Dashboard;
