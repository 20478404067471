import * as types from "../constants";
const initialState = {
  user: {
    authorization: "",
    email: "",
    full_name: "",
    phone: "",
    status: "",
    mfa_enabled: false,
  },
  request_account: {
    full_name: "",
    email: "",
    phone: "",
    requestState: false,
  },
  reset_password: {
    email: "",
    reset_link: "",
    full_name: "",
  },
  formSubmitted: false,
  loginState: false,
  loading: false,
  logoutState: false,
  error: { errorMessage: "", errorState: false },
  secretKeyState: false,
  secretKey: "",
  firstTimeMfa: false,
  stepOneDone: false,
  stepOneFilled: false,
  stepTwoDone: false,
  onboarding: false,
  onboardingData: {
    campaign_expiry_fallback_url: "",
    auto_delete_purl_days: "",
    alerts_email: "",
    mfa_type: "",
  },
  linkPagePopup: false,
  shortUrl: "",
  longUrl: "",
  purlPackageInRes: null,
  errors: [],
  couponCode: null,
  redirectionLoading: false,
  paymentRedirectionUrl: null,
  planUpgraded: false,
  planDowngraded: false,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.LOGIN:
      localStorage.setItem("user", JSON.stringify(actions.payload));

      return {
        ...state,
        user: {
          ...state.user,
          authorization: actions.payload.authorization,
          email: actions.payload.email,
          full_name: actions.payload.full_name,
          phone: actions.payload.phone,
          status: actions.payload.status,
          mfa_enabled: actions.payload.mfa_enabled,
        },
        loading: false,
        formSubmitted: false,
        error: { ...state.error, errorMessage: "" },
      };
    case types.LOGIN_SUCCESS:
      return { ...state, loginState: false };
    case types.SET_ERRORS:
      return {
        ...state,
        error: { ...state.error, errorState: true },
      };
    case types.SET_PURL_PACKAGE:
      return {
        ...state,
        purlPackageInRes: actions.payload,
      };
    case types.REMOVE_PURL_PACKAGE:
      return {
        ...state,
        purlPackageInRes: actions.payload,
      };

    case types.SET_ERRORS_SUCCESS:
      return {
        ...state,
        error: {
          ...state.error,
          errorMessage: actions.payload,
          errorState: false,
        },
      };
    case types.REQUEST_ACCOUNT:
      return {
        ...state,
        request_account: {
          ...state.request_account,
          full_name: actions.payload.full_name,
          email: actions.payload.email,
          phone: actions.payload.phone,
          requestState: true,
        },
        formSubmitted: false,
      };
    case types.REQUEST_ACCOUNT_SUCCESS:
      return {
        ...state,
        request_account: { ...state.request_account, requestState: false },
        formSubmitted: false,
      };
    case types.RESET_PASSWORD:
      return {
        ...state,
        reset_password: {
          ...state.reset_password,
          email: actions.payload,
        },
        formSubmitted: false,
      };
    case types.LOGOUT:
      localStorage.removeItem("user");
      return {
        ...state,
        user: initialState,
        formSubmitted: false,
        loginState: false,
        logoutState: true,
        error: actions.payload.message,
      };
    case types.LOGOUT_SUCCESS:
      return { ...state, logoutState: false };
    case types.SECRET_KEY:
      return {
        ...state,
        secretKey: actions.payload.secretKey.base32,
        secretKeyState: true,
      };
    case types.SECRET_KEY_SUCCESS:
      return {
        ...state,
        secretKeyState: false,
      };
    case types.OTP_VERIFY:
      localStorage.setItem("user", JSON.stringify(actions.payload));
      return {
        ...state,
        user: {
          ...state.user,
          authorization: actions.payload.authorization,
          email: actions.payload.email,
          full_name: actions.payload.full_name,
          phone: actions.payload.phone,
          status: actions.payload.status,
          mfa_enabled: actions.payload.mfa_enabled,
        },
        loginState: true,
      };
    case types.FIRST_TIME_MFA:
      return {
        ...state,
        firstTimeMfa: true,
      };
    case types.FIRST_TIME_MFA_SUCCESS:
      return {
        ...state,
        firstTimeMfa: false,
      };
    case types.STEP_ONE:
      return {
        ...state,
        stepOneDone: true,
      };
    case types.STEP_ONE_UNDONE:
      return {
        ...state,
        stepOneDone: false,
      };
    case types.STEP_ONE_FILLED:
      return {
        ...state,
        stepOneFilled: true,
      };
    case types.STEP_TWO:
      return {
        ...state,
        stepTwoDone: true,
      };
    case types.STEP_TWO_UNDONE:
      return {
        ...state,
        stepTwoDone: false,
      };
    case types.STEP_THREE:
      localStorage.setItem("user", JSON.stringify(actions.payload));
      return {
        ...state,
        onboarding: true,
      };
    case types.STEP_THREE_SUCCESS:
      return {
        ...state,
        onboarding: false,
      };
    case types.FETCH_ONBOARDING_DATA:
      return {
        ...state,
        onboardingData: {
          ...state.onboardingData,
          campaign_expiry_fallback_url:
            actions.payload.campaign_expiry_fallback_url,
          auto_delete_purl_days: actions.payload.auto_delete_purl_days,
        },
      };
    case types.GET_STARTED:
      localStorage.setItem("user", JSON.stringify(actions.payload));
      return {
        ...state,
        user: {
          ...state.user,
          authorization: actions.payload.authorization,
          email: actions.payload.email,
          full_name: actions.payload.full_name,
          phone: actions.payload.phone,
          status: actions.payload.status,
          mfa_enabled: actions.payload.mfa_enabled,
        },
        shortUrl: actions.payload?.shortUrl,
        longUrl: actions.payload?.long_url,
        loading: false,
        linkPagePopup: true,
      };
    case types.LINK_POPUP:
      return {
        ...state,
        linkPagePopup: false,
      };
    case types.PUBLIC_LOGIN:
      localStorage.setItem("user", JSON.stringify(actions.payload));
      return {
        ...state,
        user: {
          authorization: actions.payload.authorization,
          email: actions.payload.email,
          full_name: actions.payload.full_name,
          phone: actions.payload.phone,
          status: actions.payload.status,
          mfa_enabled: actions.payload.mfa_enabled,
        },
        onboardingData: {
          mfa_type: actions.payload.mfa_type,
        },
        shortUrl: actions.payload?.shorturl,
        longUrl: actions.payload?.long_url,
        linkPagePopup: true,
        errors: [],
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        errors: actions.payload,
      };
    case types.SET_COUPON_CODE:
      return {
        ...state,
        couponCode: actions.payload,
      };
    case types.REMOVE_COUPON_CODE:
      return {
        ...state,
        couponCode: actions.payload,
      };
    case types.REDIRECTION_LOADING:
      return {
        ...state,
        redirectionLoading: actions.payload,
      };
    case types.PAYMENT_REDIRECTION_URL:
      return {
        ...state,
        paymentRedirectionUrl: actions.payload,
      };
    case types.PLAN_UPGRADED: {
      const { planUpgraded } = actions.payload;
      return {
        ...state,
        planUpgraded: planUpgraded,
      };
    }
    case types.PLAN_DOWNGRADED: {
      const { planDowngraded } = actions.payload;
      return {
        ...state,
        planDowngraded: planDowngraded,
      };
    }
    default:
      return state;
  }
}
