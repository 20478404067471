import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import layout from "./layoutReducer";
import theme from "./themeReducer";
import auth from "./authReducers";
import userrequest from "./userrequestReducers";
import user from "./userReducer";
import role from "./roleReducer";
import subscriber from "./subscriberReducers";
import report from "./reportReducer";
import help from "./helpReducer";

import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
  sidebar,
  layout,
  theme,
  toastr,
  auth,
  userrequest,
  role,
  subscriber,
  user,
  report,
  help,
});
