import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import store from "./redux/store/index";
import Routes from "./routes/Routes";
import axios from "axios";
import Toastr from "./pages/misc/Toaster";
import topbar from "topbar";
import TagManager from "react-gtm-module";
//Img size need to be 220x220

const primaryColor = "#495057";
topbar.config({
  autoRun: true,
  barThickness: 4,
  barColors: {
    0: primaryColor,
    ".25": primaryColor,
    ".50": primaryColor,
    ".75": primaryColor,
    "1.0": primaryColor,
  },
  shadowBlur: 10,
  shadowColor: "rgba(0,   0,   0,   .6)",
});

axios.interceptors.request.use((request) => {
  topbar.show();
  return request;
});

axios.interceptors.response.use(
  (response) => {
    if (response.skipInterceptor) {
      return response;
    }
    topbar.hide();
    return response;
  },
  (error) => {
    topbar.hide();
    if (error.config && error.config.skipInterceptor) {
      return Promise.reject(error);
    }
    if (error.toJSON().message === "Network Error") {
      topbar.hide();
      Toastr(
        "You are offline. Please check your internet connection.",
        "error"
      );
      return null;
    }
    const status = error.response.status;
    const statusText = error.response.data.message;
    if (status === 401 && statusText === "Not authorized!") {
      localStorage.removeItem("user");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const getfaviconEl = () => {
  return document.getElementById("favicon");
};

const App = () => {
  const currentVersion = "3.0.1"; // Update with your app version
  const storedVersion = localStorage.getItem("appVersion");

  if (storedVersion !== currentVersion) {
    // Clear old data from local storage
    localStorage.removeItem("user");

    // Update the version in local storage
    localStorage.setItem("appVersion", currentVersion);
  }

  const clientName = window.location.href.split("/")[2].split(".")[0];

  React.useEffect(() => {
    const info = async () => {
      const favicon = getfaviconEl();
      favicon.href = `https://storage.googleapis.com/purl-assets/client-favicons/${clientName}.ico`;
    };
    info();
  }, [clientName]);

  const user = JSON.parse(localStorage.getItem("user"));
  const token = user && user?.authorization?.split("=")[1]?.split(";")[0];
  let userType = token && JSON.parse(atob(token.split(".")[1]));

  const tagManagerArgs = {
    gtmId: "GTM-PSTCZS3",
    dataLayer: {
      userId: userType && userType.id,
    },
  };

  TagManager.initialize(tagManagerArgs);
  return (
    <Provider store={store} className="root">
      <script src="topbar.js"></script>
      <Routes />
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Provider>
  );
};

export default App;
