import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  CustomInput,
  Spinner,
} from "reactstrap";
import moment from "moment";
import { User as UserIcon } from "@styled-icons/fa-solid/User";
import { retargetCampaign } from "../../redux/actions/subscriberActions";

const RetargetModal = (props) => {
  const {
    toggle,
    title,
    optionData,
    retargetCampaign,
    retargetCampaignData,
    retargetCampaignLoading,
    singleCampaignExpiryDate,
  } = props;
  const [checkBoxValues, setCheckBoxValues] = useState([]);
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [journeyCompleted, setJourneyCompleted] = useState(false);
  const replacementMap = {
    "Engaged Users": "clicked",
    "Unengaged Users": "unclicked",
  };
  const { id } = useParams();
  const history = useHistory();
  useEffect(() => {
    if (
      optionData?.length > 0 ||
      optionData?.unClickedPurls ||
      optionData?.uniqueClicks
    ) {
      if (title === "Clicks") {
        setCheckBoxValues([
          {
            label: "Engaged Users",
            value: optionData?.uniqueClicks,
          },
          {
            label: "Unengaged Users",
            value: optionData?.unClickedPurls,
          },
        ]);
      } else {
        setCheckBoxValues(
          optionData?.map((option) => ({
            label: option?.name,
            value: option?.clicks,
          }))
        );
      }
    }
  }, [title, optionData]);

  useEffect(() => {
    if (retargetCampaignData?.length > 0 && journeyCompleted) {
      history.push("/campaigns/create-purls");
    }
    return () => {
      setJourneyCompleted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retargetCampaignData]);

  const handleCheckboxChange = (optionLabel) => {
    const isChecked = checkedOptions.includes(optionLabel);
    if (isChecked) {
      setCheckedOptions(
        checkedOptions.filter((label) => label !== optionLabel)
      );
    } else {
      setCheckedOptions([...checkedOptions, optionLabel]);
    }
  };

  const handleSelectAll = () => {
    const allLabels = checkBoxValues
      .filter((option) => option.value !== 0)
      .map((option) => option.label);

    const label = isExpired(singleCampaignExpiryDate)
      ? allLabels.filter((option) => option !== "Unengaged Users")
      : allLabels;

    if (checkedOptions.length === label.length) {
      setCheckedOptions([]);
    } else {
      setCheckedOptions(label);
    }
  };

  const handleSubmit = () => {
    const modifiedOptionsArray = checkedOptions?.map(
      (word) => replacementMap[word] || word
    );
    const data = {
      campaign_id: id,
      retargetType:
        title === "Clicks"
          ? "purls"
          : title?.toLowerCase()?.replace("device os", "os"),
      type: modifiedOptionsArray,
    };
    if (data) {
      retargetCampaign(data);
      setJourneyCompleted(true);
    }
  };
  function isExpired(date) {
    return date ? moment(date).isBefore(moment()) : false;
  }

  return (
    <Modal
      {...props}
      toggle={() => {
        toggle();
        setCheckedOptions([]);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="retarget-modal"
    >
      <ModalHeader
        toggle={() => {
          toggle();
          setCheckedOptions([]);
        }}
      >
        <div style={{ fontSize: 22 }}>Retarget by {title}</div>
      </ModalHeader>
      <ModalBody className="px-4">
        <p className="mb-3 font-weight-bold">
          Select categories for retargeting. Feel free to choose multiple.
        </p>
        <div className="border rounded-lg p-3">
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <div className="font-weight-bold">Available options:</div>
            <div className="d-flex justify-content-start align-items-center">
              <label htmlFor="selectAll" className="m-0 mr-2">
                Select All
              </label>
              <CustomInput
                type="switch"
                id="selectAll"
                className="d-flex flex-column cursor-pointer"
                checked={
                  checkedOptions.length ===
                    checkBoxValues
                      .filter((option) => option.value !== 0)
                      .filter((opt) => {
                        if (opt.label === "Unengaged Users") {
                          return !isExpired(singleCampaignExpiryDate);
                        }
                        return true;
                      }).length && checkedOptions.length !== 0
                }
                onChange={handleSelectAll}
              />
            </div>
          </div>
          <div className="retarget-modal-overflow">
            {checkBoxValues?.length > 0 ? (
              checkBoxValues?.map((option, index) => (
                <div
                  className="d-flex flex-column"
                  style={{
                    paddingInline: "3px",
                  }}
                  key={index}
                >
                  <CustomInput
                    type="checkbox"
                    id={`exampleCustomCheckbox_${index}`}
                    value={option.label}
                    checked={checkedOptions.includes(option.label)}
                    onChange={() => handleCheckboxChange(option.label)}
                    label={
                      <div className="d-flex pl-1">
                        <span className="m-0 retarget-modal-options">
                          {option?.label}{" "}
                          {option?.value === 0 ||
                          (option.label === "Unengaged Users" &&
                            isExpired(singleCampaignExpiryDate))
                            ? "(Disabled)"
                            : null}
                        </span>
                        <span
                          className="d-flex align-items-center ml-2"
                          style={{ fontSize: "14px" }}
                        >
                          <UserIcon className="mr-1" size={12} />
                          <span>Users: {option?.value}</span>
                        </span>
                      </div>
                    }
                    className="d-flex flex-column mb-1"
                    disabled={
                      option.value === 0 ||
                      (option.label === "Unengaged Users" &&
                        isExpired(singleCampaignExpiryDate))
                    }
                  />
                  {index !== checkBoxValues?.length - 1 && (
                    <div className="separator my-2" />
                  )}
                </div>
              ))
            ) : (
              <div>No options available</div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <Button
            color="primary"
            size="lg"
            className="px-4"
            disabled={!checkedOptions?.length}
            onClick={handleSubmit}
            style={{ minWidth: "190px" }}
          >
            {retargetCampaignLoading ? (
              <Spinner
                animation="border"
                color="white"
                role="status"
                size="sm"
              />
            ) : (
              <span>Retarget Campaign</span>
            )}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapPropsToState = (state) => {
  return {
    retargetCampaignData: state.subscriber.retargetCampaignData,
    retargetCampaignLoading: state.subscriber.retargetCampaignLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    retargetCampaign: (data) => dispatch(retargetCampaign(data)),
  };
};
export default connect(mapPropsToState, mapDispatchToProps)(RetargetModal);
