import { toastr } from "react-redux-toastr";
const Toastr = (message, type) => {
  // return
  const options = {
    timeOut: parseInt(3000),
    showCloseButton: false,
    progressBar: false,
    position: "top-right",
  };

  const toastrInstance =
    message !== "" && type === "error" ? toastr.error : toastr.success;
  toastrInstance && toastrInstance(type.toUpperCase(), message, options);
};
export default Toastr;
