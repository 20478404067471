import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  Dashboard as dashboardRoutes,
  Landing as landingRoutes,
  Page as pageRoutes,
} from "./accessControl";
import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";
import { isValidPublicDomain } from "../config";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => {
            return (
              <Layout>
                <Component {...props} />
              </Layout>
            );
          }}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }}
      />
    )
  );

const Routes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <Redirect to={isValidPublicDomain ? "/links" : "/dashboard"} />
          </Route>
          {childRoutes(LandingLayout, landingRoutes)}
          {childRoutes(DashboardLayout, dashboardRoutes)}
          {childRoutes(AuthLayout, pageRoutes)}
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
