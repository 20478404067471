import React, { useState, useEffect } from "react";
import { Button, Col, Row, Input, Label } from "reactstrap";
import Select from "react-select";
import { Link, useHistory, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { ArrowRight } from "react-feather";

import Header from "./OnboardingHeader";
import { customStyles } from "../../components/CustomStyle";
import { StepOneValidation } from "../validation/validation";
import {
  onboarding,
  fetchFallbackUrlData,
} from "../../redux/actions/authActions";

const StepOne = ({
  StepOnecall,
  fetchUrlData,
  onboardingData,
  stepOneDone,
}) => {
  const [error, setError] = useState({ url: "" });
  const [fallbackURL, setfallbackURL] = useState("");
  const [autoDeleteDays, setAutoDeleteDays] = useState("90");
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("user"));
  const tokenString = user?.authorization?.split("=")[1]?.split(";")[0];
  const token = typeof tokenString === "string" ? tokenString : "";
  const decoded = token ? jwtDecode(token) : "";

  const stepOneUpload = () => {
    const stepOneUploadData = {
      campaign_expiry_fallback_url: fallbackURL,
      auto_delete_purl_days: autoDeleteDays?.value,
    };
    const { formIsValid, error } = StepOneValidation(stepOneUploadData);
    if (formIsValid) {
      StepOnecall(stepOneUploadData, "one");
    }
    setError(error);
  };

  const options = [
    {
      label: "7 Days",
      value: "7",
    },
    {
      label: "30 Days",
      value: "30",
    },
    {
      label: "60 Days",
      value: "60",
    },
    {
      label: "90 Days (default)",
      value: "90",
    },
  ];

  useEffect(() => {
    setAutoDeleteDays(options.find((e) => e.value === "90"));
    if (user) {
      fetchUrlData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onboardingData?.campaign_expiry_fallback_url && fallbackURL == "") {
      setfallbackURL(onboardingData?.campaign_expiry_fallback_url);
    }
    if (onboardingData?.auto_delete_purl_days) {
      setAutoDeleteDays(
        options.find(
          (option) => option.value === onboardingData?.auto_delete_purl_days
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingData]);

  useEffect(() => {
    if (stepOneDone == true) {
      history.push("/onboarding/step2");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepOneDone]);

  if (user) {
    if (decoded?.role === "Admin" && decoded?.onboarding === false) {
      return (
        <Row className="h-100 my-auto mx-2">
          <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className="text-start bg-white py-3">
                <div className="px-3 px-md-5 py-3">
                  <Header step={1} />
                  <div className="">
                    <p className="h4 text-center mb-4">
                      Configure Campaign Fallback and PURL Auto Delete
                    </p>
                    <div>
                      <form>
                        <div>
                          <Label className="one-rem-font-size">
                            Campaign expiry fallback URL:{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            className={`custom-input mb-2`}
                            style={
                              error?.url ? { border: "1px solid red" } : {}
                            }
                            bsSize="lg"
                            type="text"
                            value={fallbackURL}
                            onChange={(e) => {
                              setfallbackURL(e.target.value);
                              error["url"] = "";
                            }}
                            placeholder="Enter Fallback URL"
                          />
                          <span
                            style={{ color: "red" }}
                            className={`${error?.url && "mb-3"}`}
                          >
                            {error?.url}
                          </span>
                          <p className={`mt-2`} style={{ lineHeight: "1rem" }}>
                            Enter the URL of your fallback page where your
                            clients will be directed after purl has expired.
                          </p>
                        </div>
                        <div>
                          <Label className="one-rem-font-size">
                            Default Campaign Expiry Days:{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Select
                            className="react-select-container w-100 mb-2"
                            isSearchable={true}
                            styles={customStyles}
                            options={options}
                            value={autoDeleteDays}
                            onChange={(e) => {
                              setAutoDeleteDays(e);
                            }}
                          />
                          <p className="" style={{ lineHeight: "1rem" }}>
                            Set the period for automatic deletion of
                            personalised URLs (PURLs). The default is 90 days,
                            but you can customise it as needed. This feature
                            helps maintain data privacy and campaign management.
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center px-3 px-md-5">
                  <Link to="/dashboard" className="mb-0 cursor-pointer">
                    skip for now
                  </Link>
                  <Button
                    className="px-3"
                    color="primary d-flex align-items-center"
                    onClick={() => {
                      stepOneUpload();
                    }}
                  >
                    Next
                    <span className="ml-1" style={{ lineHeight: "0" }}>
                      <ArrowRight size={17} />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      );
    } else {
      return <Redirect to="/dashboard" />;
    }
  } else {
    return <Redirect to="/auth/sign-in" />;
  }
};

const mapStateToProps = (state) => {
  return {
    onboardingData: state.auth.onboardingData,
    stepOneDone: state.auth.stepOneDone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    StepOnecall: (data, step) => dispatch(onboarding(data, step)),
    fetchUrlData: () => dispatch(fetchFallbackUrlData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
