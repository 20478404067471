import { dashboard as fullDashboard } from ".";
import { landing as fullLanding } from ".";
import { page as fullPage } from ".";
import { isValidPublicDomain } from "../config";

const filterRoutes = (routes, toRemove, childrenThatStays) => {
  return routes.filter((route) => {
    if (route.children) {
      const filteredChildren = route.children.filter(
        (child) => !childrenThatStays.some((keep) => child.path !== keep)
      );

      if (filteredChildren.length > 0) {
        route.children = filteredChildren;
      }
    }

    return !toRemove.some((removeRoute) => route.path === removeRoute);
  });
};

const dashboardRoutesToRemove = ["/dashboard", "/user-settings"];

const landingRoutesToRemove = ["/onboarding"];

const authRoutesToRemove = [""];

const authChildrenRoutesToKeep = ["/auth/get-started", "/auth/sign-in"];

const Dashboard = isValidPublicDomain
  ? filterRoutes(fullDashboard, dashboardRoutesToRemove, [])
  : fullDashboard;

const Landing = isValidPublicDomain
  ? filterRoutes(fullLanding, landingRoutesToRemove, [])
  : fullLanding;

const Page = isValidPublicDomain
  ? filterRoutes(fullPage, authRoutesToRemove, authChildrenRoutesToKeep)
  : fullPage;

export { Dashboard, Landing, Page };
