import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Spinner,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledAlert,
} from "reactstrap";
import { ArrowLeft, ArrowRight, Link, Bell, List } from "react-feather";
import { Profile } from "@styled-icons/icomoon/Profile";
import { ContactCardLink } from "@styled-icons/fluentui-system-regular/ContactCardLink";
import FileUpload from "./FileUpload";
import ListForm from "./ListForm";
import moment from "moment";
import {
  draftACampaign,
  createNewList,
  getAllTag,
  getTeams,
  getShortDomain,
  fetchSubscriberLists,
  emptyDraftStatus,
  emptySignedUrls,
  checkPackagesValidity,
} from "../../../../redux/actions/subscriberActions";
import { connect } from "react-redux";
import {
  subscriberListValidation,
  draftCampaignDataValidation,
} from "../../../validation/validation";
import { useHistory } from "react-router-dom";
import TagManager from "react-gtm-module";
import DraftModal from "../../../../components/DraftModal";
import ShortUrlForm from "./ShortUrlForm";
import LinkInBio from "./LinkInBio";
import { isValidPublicDomain } from "../../../../config";
function CreateNewList({
  draftACampaign,
  createNewList,
  getShortDomain,
  shortDomain,
  shortDomainLoadingState,
  getAllTag,
  getTeams,
  teams,
  tagState,
  loading,
  fetchSubscriberLists,
  formSubmissionStatus,
  retargetCampaignData,
  retargetCampaignParentId,
  draftStatus,
  emptyDraftStatus,
  emptySignedUrls,
  tabState,
  checkPackagesValidity,
  packageValidity,
}) {
  const [activeTab, setActiveTab] = useState(tabState);
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const newPageTitle = "purl - Create purls";
  document.title = newPageTitle;

  const user = JSON.parse(localStorage.getItem("user"));
  const token = user && user.authorization.split("=")[1].split(";")[0];
  let userType = token && JSON.parse(atob(token.split(".")[1]));
  const plan = localStorage?.getItem("userPlan");

  //States are defined here
  const [finalData, setfinalData] = useState(null);
  const [isFormValid, setFormValidation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const modalToggle = () => setShowModal(!showModal);
  const [subListTitle, setSubListTitle] = useState("");
  const [landingPage, setLandingPage] = useState("");
  const [parsedLandingPageUrl, setParsedLandingPageUrl] = useState("");
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [expiry, setExpiry] = useState("");
  const [utmSource, setUtmSource] = useState({
    value: null,
    isFromQuery: true,
  });
  const [utmCampaign, setUtmCampaign] = useState({
    value: null,
    isFromQuery: true,
  });
  const [utmContent, setUtmContent] = useState({
    value: null,
    isFromQuery: true,
  });
  const [utmMedium, setUtmMedium] = useState({
    value: null,
    isFromQuery: true,
  });
  let [tag, setTag] = useState([]);
  const [selectShort, setSelectShort] = useState([]);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [step, setStep] = useState(0);
  const [fileStatus, setFileStatus] = useState(false);
  const [error, setError] = useState({
    title: "",
    landingPage: "",
    expiry: "",
    utmSource: "",
    utmCampaign: "",
    utmContent: "",
    utmMedium: "",
    tag: "",
    domainId: "",
    file: "",
    uniqueId: "",
    password: "",
  });
  let [tagOptions] = useState([{ value: "", label: "" }]);
  let [shortDomainOptions] = useState([{ value: "", label: "" }]);
  const history = useHistory();
  const [password, setPassword] = useState(null);
  // Short urls:-
  const [longUrl, setLongUrl] = useState("");
  const [name, setName] = useState("");
  const [userPackageValidity, setUserPackageValidity] = useState(null);
  const linkInBioLimit = userPackageValidity?.linkInBioLimit;
  const campaignLimitLeft = userPackageValidity?.campaignLimitLeft;
  const passwordProtectedLimitLeft =
    userPackageValidity?.passwordProtectedLimitLeft;
  const singlePurlLimit = userPackageValidity?.singlePurlLimit;
  //Functions are defined here
  const parseLandingPageURL = (urls) => {
    const realUrl = urls;
    let url = urls;
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = `https://${url}`;
    }
    try {
      const parsedUrl = new URL(url);
      const queryParams = parsedUrl.searchParams;
      const utmPattern = /^utm[\s_-]*(source|campaign|content|medium)$/i;
      const extractedParams = {};

      queryParams.forEach((value, key) => {
        if (utmPattern.test(key)) {
          const values = value.split(",");
          values.forEach((val, index) => {
            const utmKey = values.length > 1 ? `${key}_${index + 1}` : key;
            extractedParams[utmKey] = val.trim();
          });
        }
      });

      let newUrl = parsedUrl.toString();
      if (!realUrl.startsWith("http://") && !realUrl.startsWith("https://")) {
        if (newUrl.startsWith("https://")) {
          newUrl = newUrl.slice(8);
        } else if (newUrl.startsWith("http://")) {
          newUrl = newUrl.slice(7);
        }

        if (newUrl.endsWith("/")) {
          newUrl = newUrl.slice(0, -1);
        }
      }
      setParsedLandingPageUrl(newUrl);
    } catch (error) {
      console.error("Error parsing URL:", error.message);
    }
  };
  const handleLandingPageInput = (value) => {
    setLandingPage(value);
    if (value) {
      let url = value.trim();
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = `https://${url}`;
      }
      try {
        const parsedUrl = new URL(url);
        const queryParams = parsedUrl.searchParams;
        const extractedParams = {};
        const utmPattern = /^utm[\s_-]*(source|campaign|content|medium)$/i;

        queryParams.forEach((value, key) => {
          if (utmPattern.test(key)) {
            const values = value.split(",");
            values.forEach((val, index) => {
              const utmKey = values.length > 1 ? `${key}_${index + 1}` : key;
              extractedParams[utmKey.toLowerCase()] = val.trim();
            });
          }
        });
        const processedUtms = {
          source: false,
          medium: false,
          campaign: false,
          content: false,
        };
        Object.entries(extractedParams).forEach(([key, value]) => {
          const lowercaseKey = key.toLowerCase();
          if (lowercaseKey.includes("source")) {
            setUtmSource({ value, isFromQuery: false });
            processedUtms.source = true;
          }
          if (lowercaseKey.includes("medium")) {
            setUtmMedium({ value, isFromQuery: false });
            processedUtms.medium = true;
          }
          if (lowercaseKey.includes("campaign")) {
            setUtmCampaign({ value, isFromQuery: false });
            processedUtms.campaign = true;
          }

          if (lowercaseKey.includes("content")) {
            setUtmContent({ value, isFromQuery: false });
            processedUtms.content = true;
          }
        });
        if (!processedUtms.source && utmSource?.isFromQuery === false) {
          setUtmSource({ value: "", isFromQuery: false });
        }
        if (!processedUtms.medium && utmMedium?.isFromQuery === false) {
          setUtmMedium({ value: "", isFromQuery: false });
        }
        if (!processedUtms.campaign && utmCampaign?.isFromQuery === false) {
          setUtmCampaign({ value: "", isFromQuery: false });
        }
        if (!processedUtms.content && utmContent?.isFromQuery === false) {
          setUtmContent({ value: "", isFromQuery: false });
        }
      } catch (error) {
        console.error("Error parsing URL:", error.message);
      }
    } else {
      setUtmSource({ value: "", isFromQuery: true });
      setUtmMedium({ value: "", isFromQuery: true });
      setUtmCampaign({ value: "", isFromQuery: true });
      setUtmContent({ value: "", isFromQuery: true });
    }
  };

  const filteredTeams = teams?.filter((team) => {
    return (
      team?.Team?.status === "Active" &&
      (team?.role === "Admin" || team?.role === "Editor")
    );
  });
  const teamsOptions = filteredTeams?.map((team) => ({
    value: team?.team_id,
    label: team?.Team?.team_name,
  }));

  const setDropdownTwo = () => {
    for (let j = 0; shortDomain && j < shortDomain.length; j++) {
      shortDomainOptions[j] = {
        value: shortDomain[j].short_domain_id,
        label: shortDomain[j].short_domain,
      };
    }
  };

  const setDropdown = () => {
    for (let i = 0; tagState && i < tagState.length; i++) {
      tagOptions[i] = {
        value: tagState[i].tag_name,
        label: tagState[i].tag_name,
      };
    }
  };

  const handleTag = (val) => {
    setTag(val);
  };
  const handleUtmsChecks = () => {
    const utms = {
      utm_source:
        utmSource?.value === ""
          ? null
          : utmSource?.isFromQuery
          ? utmSource.value
          : null,
      utm_medium:
        utmMedium?.value === ""
          ? null
          : utmMedium?.isFromQuery
          ? utmMedium.value
          : null,
      utm_content:
        utmContent?.value === ""
          ? null
          : utmContent?.isFromQuery
          ? utmContent.value
          : null,
      utm_campaign:
        utmCampaign?.value === ""
          ? null
          : utmCampaign?.isFromQuery
          ? utmCampaign.value
          : null,
    };

    return utms;
  };
  const handleSubmitFirstStep = () => {
    const utms = handleUtmsChecks();
    tagArray();
    const finalUploadData = {
      campaign_name: subListTitle,
      tag: tag,
      domainId: selectShort?.value,
      expiry_state: checked,
      expiry: expiry,
      landing_page: parsedLandingPageUrl,
      ...utms,
      step: step,
      team_id: selectedTeamId,
      password: password,
      checked1: checked1,
    };

    const { formIsValid, error } = subscriberListValidation(finalUploadData);
    setError(error);

    if (formIsValid) {
      setStep((step) => step + 1);
    }
  };
  const uploadData = () => {
    if (finalData && isFormValid) {
      // ---------GTM Start----------
      const tagManagerArgs = {
        gtmId: "GTM-PSTCZS3",
        dataLayer: {
          event: "formSubmission",
          formName: isValidPublicDomain
            ? "Add new trackable links"
            : "Add new campaign",
          userId: userType && userType.id,
        },
      };
      TagManager.initialize(tagManagerArgs);
      // --------- GTM End-------------

      createNewList(finalData);
    }
  };

  const handleSubmit = () => {
    const utms = handleUtmsChecks();
    tagArray();
    const finalUploadData = {
      campaign_name: subListTitle,
      tag: tag,
      file: fileData,
      domainId: selectShort?.value,
      expiry_state: checked,
      expiry: expiry,
      landing_page: parsedLandingPageUrl,
      ...utms,
      team_id: selectedTeamId,
      password: password,
      checked1: checked1,
    };
    if (retargetCampaignData && retargetCampaignData.length > 0) {
      finalUploadData.retarget = Number(retargetCampaignParentId);
    }

    const { formIsValid, error } = subscriberListValidation({
      ...finalUploadData,
      step: step,
    });
    setError(error);

    if (formIsValid) {
      setfinalData(finalUploadData);
      setFormValidation(true);
      modalToggle();
    }
  };
  const handleDraftingCampaign = () => {
    const utms = handleUtmsChecks();
    tagArray();
    const finalUploadDataToDraft = {
      campaign_name: subListTitle,
      tag: tag,
      file: fileData,
      domainId: selectShort?.value,
      expiry: expiry,
      landing_page: parsedLandingPageUrl,
      ...utms,
      fileStatus: fileStatus,
      team_id: selectedTeamId,
      password: password,
      checked1: checked1,
    };
    const { formIsValid, error } = draftCampaignDataValidation({
      ...finalUploadDataToDraft,
    });

    setError(error);
    if (formIsValid) {
      draftACampaign(finalUploadDataToDraft);
    }
  };
  const tagArray = () => {
    if (tag !== null) {
      tag = tag?.filter(function (e) {
        return e !== " ";
      });
    } else {
      tag = [];
    }
  };

  let long_Url = "";
  let linkName = "";
  if (window.location.search) {
    try {
      let long_URL = window.location.search.split("&link_name");
      long_Url = long_URL[0].split("?long_url=")[1];
      let fetched_link_name = window.location.search.split("&link_name=")[1];
      linkName = fetched_link_name.replace(/%20/g, " ");
    } catch (error) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.size > 0) {
        const urlObject = new URL(queryParams.get("long_url"));
        const link_name = new URL(queryParams.get("link_name"));
        long_Url = urlObject.href;
        linkName = link_name;
      }
    }
  }

  //USE EFFECT SECTION
  React.useEffect(() => {
    if (!checked1) {
      setPassword(null);
    }
  }, [checked1]);
  React.useEffect(() => {
    parseLandingPageURL(landingPage);
  }, [landingPage]);
  React.useEffect(() => {
    if (formSubmissionStatus && formSubmissionStatus === 200) {
      history.push("/campaigns/create-purls-success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmissionStatus]);
  React.useEffect(() => {
    if (draftStatus && draftStatus === "draft") {
      history.push("/campaigns");
    }
    return () => {
      emptyDraftStatus();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftStatus]);
  useEffect(() => {
    getAllTag();
    getShortDomain();
    getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagOptions, shortDomainOptions]);

  useEffect(() => {
    if (retargetCampaignData && retargetCampaignData.length > 0) {
      setFileData(retargetCampaignData);
    }
  }, [retargetCampaignData]);

  useEffect(() => {
    setError((prevError) => ({
      ...prevError,
      title: subListTitle ? "" : prevError.title,
      landingPage: landingPage ? "" : prevError.landingPage,
      expiry: expiry ? "" : prevError.expiry,
      utmSource: utmSource ? "" : prevError.utmSource,
      utmCampaign: utmCampaign ? "" : prevError.utmCampaign,
      utmContent: utmContent ? "" : prevError.utmContent,
      utmMedium: utmMedium ? "" : prevError.utmMedium,
      domainId: selectShort?.value ? "" : prevError.domainId,
      password: password ? "" : prevError.password,
    }));
    if (expiry === "Invalid date") {
      setExpiry("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subListTitle,
    landingPage,
    expiry,
    utmSource,
    utmCampaign,
    utmContent,
    utmMedium,
    selectShort,
    password,
  ]);

  useEffect(() => {
    setError((prevError) => ({
      ...prevError,
      expiry: "",
    }));
    if (!checked) {
      setExpiry("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);
  useEffect(() => {
    return () => {
      emptySignedUrls();
    };
  }, []);

  useEffect(() => {
    if (plan === "shellpackage") {
      setActiveTab("1");
    }
  }, [plan]);

  useEffect(() => {
    if (long_Url && linkName) {
      setActiveTab("1");
      setLongUrl(long_Url);
      setName(linkName);
      setSelectShort({
        value: shortDomain[0].short_domain_id,
        label: shortDomain[0].short_domain,
      });
    }
  }, [long_Url, linkName, shortDomain]);

  useEffect(() => {
    checkPackagesValidity();
  }, []);
  useEffect(() => {
    if (packageValidity) {
      setUserPackageValidity(packageValidity);
    }
  }, [packageValidity]);
  //Value of the short domain is getting setup here
  if (shortDomainLoadingState && loading) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "200px" }}
      >
        <Spinner
          animation="border"
          color="primary"
          role="status"
          style={{ width: "8rem", height: "8rem", borderWidth: "10px" }}
        />
      </div>
    );
  } else {
    setDropdown();
    setDropdownTwo();
  }

  const stepTitles = ["Step 1: Campaign Details", "Step 2: Upload Files"];

  //Render starts here
  return (
    <>
      <Row className="mb-2 mb-xl-4 mt-7 mt-lg-0">
        <Col xs="auto">
          <h1 className="mb-2 pl-2 pl-lg-0 page-headers-1 text-dark">
            Create purls
          </h1>
        </Col>
      </Row>

      <DraftModal
        isOpen={showModal}
        toggle={modalToggle}
        handleUpload={() => {
          uploadData();
          fetchSubscriberLists();
        }}
        handleCampaignDraft={handleDraftingCampaign}
      />
      {plan === "shellpackage" && (
        <UncontrolledAlert
          color="primary"
          className="alert-outline-coloured w-100 mb-3"
        >
          <div className="alert-icon d-flex align-items-center">
            <Bell size={22} />
          </div>
          <div className="alert-message alert-message-components">
            <p className="mb-0 ">
              To access purl trackable links and link in bio feature, kindly
              upgrade your plan.
              <a
                className="ml-lg-1 ml-0"
                href="https://www.p-url.com/pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-nowrap d-none d-sm-inline-block">
                  Click here to upgrade your plan.
                </span>
                <span className="text-nowrap d-inline-block d-sm-none">
                  Take action
                </span>
              </a>
            </p>
          </div>
        </UncontrolledAlert>
      )}
      {linkInBioLimit === false && plan !== "shellpackage" && (
        <UncontrolledAlert
          color="primary"
          className="alert-outline-coloured w-100 mb-3"
        >
          <div className="alert-icon d-flex align-items-center">
            <Bell size={22} />
          </div>
          <div className="alert-message alert-message-components">
            <p className="mb-0 ">
              You've reached the maximum number of link in bio links you can
              create.
              <a
                className="ml-lg-1 ml-0"
                href="https://www.p-url.com/pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-nowrap d-none d-sm-inline-block">
                  Know more about pricing.
                </span>
                <span className="text-nowrap d-inline-block d-sm-none">
                  Take action
                </span>
              </a>
            </p>
          </div>
        </UncontrolledAlert>
      )}
      {singlePurlLimit === false && (
        <UncontrolledAlert
          color="primary"
          className="alert-outline-coloured w-100 mb-3"
        >
          <div className="alert-icon d-flex align-items-center">
            <Bell size={22} />
          </div>
          <div className="alert-message alert-message-components">
            <p className="mb-0 ">
              You've reached the maximum number of short urls you can create.
              <a
                className="ml-lg-1 ml-0"
                href="https://www.p-url.com/pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-nowrap d-none d-sm-inline-block">
                  {plan !== "shellpackage"
                    ? "Know more about pricing."
                    : "Click here to upgrade your plan."}
                </span>
                <span className="text-nowrap d-inline-block d-sm-none">
                  Take action
                </span>
              </a>
            </p>
          </div>
        </UncontrolledAlert>
      )}
      {campaignLimitLeft === false && plan !== "shellpackage" && (
        <UncontrolledAlert
          color="primary"
          className="alert-outline-coloured w-100 mb-3"
        >
          <div className="alert-icon d-flex align-items-center">
            <Bell size={22} />
          </div>
          <div className="alert-message alert-message-components">
            <p className="mb-0 ">
              You've reached the maximum number of trackable links you can
              create.
              <a
                className="ml-lg-1 ml-0"
                href="https://www.p-url.com/pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-nowrap d-none d-sm-inline-block">
                  Know more about pricing
                </span>
                <span className="text-nowrap d-inline-block d-sm-none">
                  Take action
                </span>
              </a>
            </p>
          </div>
        </UncontrolledAlert>
      )}
      {passwordProtectedLimitLeft === false && plan !== "shellpackage" && (
        <UncontrolledAlert
          color="primary"
          className="alert-outline-coloured w-100 mb-3"
        >
          <div className="alert-icon d-flex align-items-center">
            <Bell size={22} />
          </div>
          <div className="alert-message alert-message-components">
            <p className="mb-0 ">
              You've reached the maximum number of making trackable links
              password protected.
              <a
                className="ml-lg-1 ml-0"
                href="https://www.p-url.com/pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-nowrap d-none d-sm-inline-block">
                  Know more about pricing
                </span>
                <span className="text-nowrap d-inline-block d-sm-none">
                  Take action
                </span>
              </a>
            </p>
          </div>
        </UncontrolledAlert>
      )}

      <div style={{ position: "relative" }}>
        <Nav tabs>
          <div
            style={{
              overflowX: "auto",
              overflowY: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            <NavItem style={{ display: "inline-block" }}>
              <NavLink
                style={{
                  color: activeTab === "1" ? "#fff" : "#000",
                  cursor: singlePurlLimit === false ? "not-allowed" : "pointer",
                  opacity: singlePurlLimit === false ? 0.5 : 1,
                }}
                className={
                  activeTab === "1" ? "active bg-primary" : "text-dark bg-none"
                }
                onClick={() => {
                  if (singlePurlLimit !== false) {
                    toggle("1");
                  }
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <Link size={18} style={{ height: "25px" }} />
                  <p
                    style={{ marginBottom: "0px" }}
                    className="one-rem-font-size pl-1"
                  >
                    Short URL
                  </p>
                </div>
              </NavLink>
            </NavItem>
            <NavItem style={{ display: "inline-block" }}>
              <NavLink
                style={{
                  color: activeTab === "2" ? "#fff" : "#000",
                  cursor:
                    plan === "shellpackage" || campaignLimitLeft === false
                      ? "not-allowed"
                      : "pointer",
                  opacity:
                    plan === "shellpackage" || campaignLimitLeft === false
                      ? 0.5
                      : 1,
                }}
                className={
                  activeTab === "2" ? "active bg-primary" : "text-dark bg-none"
                }
                onClick={() => {
                  if (plan !== "shellpackage" && campaignLimitLeft !== false) {
                    toggle("2");
                  }
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <ContactCardLink style={{ height: "25px" }} />
                  <p
                    style={{ marginBottom: "0px" }}
                    className="one-rem-font-size pl-1"
                  >
                    Trackable Link
                  </p>
                </div>
              </NavLink>
            </NavItem>
            <NavItem style={{ display: "inline-block" }}>
              <NavLink
                style={{
                  color: activeTab === "3" ? "#fff" : "#000",
                  cursor:
                    plan === "shellpackage" || linkInBioLimit === false
                      ? "not-allowed"
                      : "pointer",
                  opacity:
                    plan === "shellpackage" || linkInBioLimit === false
                      ? 0.5
                      : 1,
                }}
                className={
                  activeTab === "3" ? "active bg-primary" : "text-dark bg-none"
                }
                onClick={() => {
                  if (plan !== "shellpackage" && linkInBioLimit !== false) {
                    toggle("3");
                  }
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <Profile size={18} style={{ height: "25px" }} />
                  <p
                    style={{ marginBottom: "0px" }}
                    className="one-rem-font-size pl-1"
                  >
                    Link in Bio
                  </p>
                </div>
              </NavLink>
            </NavItem>
          </div>
        </Nav>
        <div
          style={{
            position: "absolute",
            top: 39,
            height: "3px",
            width: "100%",
          }}
          className="bg-primary"
        ></div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <Card>
                  <div className="m-2">
                    <Row className="d-flex flex-column flex-lg-row">
                      <ShortUrlForm
                        longUrl={longUrl}
                        setLongUrl={setLongUrl}
                        name={name}
                        setName={setName}
                        shortDomainOptions={shortDomainOptions}
                        setSelectShort={setSelectShort}
                        selectShort={selectShort}
                        singlePurlLimit={singlePurlLimit}
                      />
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <Card>
                  <div className="m-2">
                    <Row className="d-flex justify-content-center flex-column flex-lg-row">
                      <h3 className="pl-4 ml-2 mb-1 mt-2 one-rem-font-size d-lg-none">
                        {stepTitles[step]}
                      </h3>
                      <Col
                        sm={12}
                        md
                        className={step == 0 ? "" : "d-none d-lg-block"}
                      >
                        <ListForm
                          handleLandingPageInput={handleLandingPageInput}
                          subListTitle={subListTitle}
                          setSubListTitle={setSubListTitle}
                          checked={checked}
                          setChecked={setChecked}
                          error={error}
                          tagOptions={tagOptions}
                          shortDomainOptions={shortDomainOptions}
                          teamsOptions={teamsOptions}
                          setSelectedTeamId={setSelectedTeamId}
                          tag={tag}
                          handleTag={handleTag}
                          setSelectShort={setSelectShort}
                          handleSubmit={handleSubmit}
                          fetchSubscriberLists={fetchSubscriberLists}
                          setExpiry={setExpiry}
                          expiry={expiry}
                          landingPage={landingPage}
                          setLandingPage={setLandingPage}
                          utmSource={utmSource}
                          setUtmSource={setUtmSource}
                          utmCampaign={utmCampaign}
                          setUtmCampaign={setUtmCampaign}
                          utmContent={utmContent}
                          setUtmContent={setUtmContent}
                          utmMedium={utmMedium}
                          setUtmMedium={setUtmMedium}
                          setChecked1={setChecked1}
                          checked1={checked1}
                          setPassword={setPassword}
                          password={password}
                          passwordProtectedLimitLeft={
                            passwordProtectedLimitLeft
                          }
                        />
                      </Col>
                      <Col
                        sm={12}
                        md
                        className={step == 1 ? " " : "d-none d-lg-block"}
                      >
                        <FileUpload
                          setFiles={setFileData}
                          setError={setError}
                          error={error}
                          setFileStatus={setFileStatus}
                        />
                      </Col>
                      <Col sm={12}>
                        <CardBody className="w-100 d-flex justify-content-end pt-1">
                          {step == 1 ? (
                            <div
                              className="d-sm-flex align-items-center justify-content-between w-100 "
                              style={{ gap: "25px" }}
                            >
                              <Button
                                color="primary"
                                href="#top"
                                onClick={() => {
                                  setStep((step) => step - 1);
                                }}
                                className="d-flex d-lg-none align-items-center mb-2 mb-sm-0"
                                style={{ width: "fit-content" }}
                              >
                                <ArrowLeft size={17} className="mr-2" /> Back
                              </Button>
                              <div
                                className="d-sm-flex "
                                style={{ gap: "25px", flexGrow: "1" }}
                              >
                                <Button
                                  color="primary"
                                  className="w-100 btn bg-white text-primary border border-primary mb-sm-0 mb-lg-0 mr-lg-2  mb-2"
                                  onClick={() => {
                                    handleDraftingCampaign();
                                  }}
                                  disabled={campaignLimitLeft === false}
                                >
                                  Save As Draft
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                  disabled={campaignLimitLeft === false}
                                  className={`"w-100 " ${
                                    isValidPublicDomain ? "mx-3" : ""
                                  }`}
                                >
                                  Publish{" "}
                                  {isValidPublicDomain ? "" : "Campaign"}
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <Button
                                color="primary"
                                className="d-flex d-lg-none align-items-center"
                                href="#top"
                                onClick={() => {
                                  handleSubmitFirstStep();
                                }}
                              >
                                Next <ArrowRight size={17} className="ml-2" />
                              </Button>
                              <div style={{ display: "flex", gap: "10px" }}>
                                <Button
                                  color="primary"
                                  className="btn one-rem-font-size bg-white text-primary border border-primary d-none d-lg-inline-block"
                                  onClick={() => {
                                    handleDraftingCampaign();
                                  }}
                                  disabled={campaignLimitLeft === false}
                                >
                                  Save as Draft
                                </Button>

                                <Button
                                  color="primary"
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                  disabled={campaignLimitLeft === false}
                                  className={`" d-none d-lg-block btn one-rem-font-size " ${
                                    isValidPublicDomain ? "px-4" : ""
                                  }`}
                                >
                                  Publish{" "}
                                  {isValidPublicDomain ? "" : "Campaign"}
                                </Button>
                              </div>
                            </div>
                          )}
                        </CardBody>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <Card>
                  <div className="m-2">
                    <LinkInBio linkInBioLimit={linkInBioLimit} />
                  </div>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

const mapPropsToState = (state) => {
  return {
    loading: state.subscriber.loading,
    shortDomain: state.subscriber.shortDomain,
    teams: state.subscriber.teams,
    shortDomainLoadingState: state.subscriber.shortDomainLoadState,
    ShortDomainState: state.subscriber.shortDomainState,
    uploadState: state.subscriber.uploadState,
    tagState: state.subscriber.tag,
    errorMessage: state.subscriber.error,
    formSubmissionStatus: state.subscriber.formSubmissionStatus,
    retargetCampaignData: state.subscriber.retargetCampaignData,
    retargetCampaignParentId: state.subscriber.retargetCampaignParentId,
    draftStatus: state.subscriber.draftStatus,
    tabState: state.subscriber.tabState,
    packageValidity: state.subscriber.packageValidityData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createNewList: (data, file) => dispatch(createNewList(data, file)),
    draftACampaign: (data, file) => dispatch(draftACampaign(data, file)),
    getShortDomain: () => dispatch(getShortDomain()),
    getAllTag: () => dispatch(getAllTag()),
    getTeams: () => dispatch(getTeams()),
    fetchSubscriberLists: () => dispatch(fetchSubscriberLists()),
    emptyDraftStatus: () => dispatch(emptyDraftStatus()),
    emptySignedUrls: () => dispatch(emptySignedUrls()),
    checkPackagesValidity: () => dispatch(checkPackagesValidity()),
  };
};
export default connect(mapPropsToState, mapDispatchToProps)(CreateNewList);
