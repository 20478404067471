import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { withRouter } from "react-router-dom";

import {
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import { LinkedinIn } from "@styled-icons/fa-brands/LinkedinIn";
import { Menu, User } from "react-feather";

import { LogOut } from "@styled-icons/ionicons-outline/LogOut";
import { isValidPublicDomain } from "../config";

const LogOutIcon = styled(LogOut)`
  &:hover {
    transform: scale(1.1);
  }
`;

const NavbarComponent = ({
  dispatch,
  sidebar,
  history,
  packageValidity,
  newTokenData,
}) => {
  const planMapping = {
    oysterpackage: "Oyster Package",
    pearlpackage: "Pearl Package",
    shellpackage: "Shell Package",
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.authorization.split("=")[1].split(";")[0];
  let userType = token && JSON.parse(atob(token.split(".")[1]));
  const [userName, setUserName] = React.useState(null);
  const [plan, setPlan] = React.useState(null);
  const formattedPlan = planMapping[plan];
  React.useEffect(() => {
    if (packageValidity) {
      setPlan(packageValidity?.plan);
    } else {
      setTimeout(() => {
        setPlan(localStorage?.getItem("userPlan"));
      }, 1000);
    }
  }, [packageValidity]);
  React.useEffect(() => {
    if (newTokenData) {
      setUserName(newTokenData?.full_name);
    } else {
      setUserName(user?.full_name);
    }
  }, [newTokenData]);

  const clientName = window.location.href.split("/")[2].split(".")[0];
  return (
    <Navbar
      color="white w-100 custom-navbar"
      light
      expand
      style={{ zIndex: 1001 }}
    >
      <span
        className="sidebar-toggle d-flex"
        style={{ marginLeft: "12px" }}
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      >
        <Menu size={30} className="align-self-center" />
      </span>

      <div
        className="w-100"
        style={
          sidebar.isOpen
            ? {}
            : { display: "grid", gridTemplateColumns: " repeat(3, 1fr)" }
        }
      >
        <a
          href={isValidPublicDomain ? "/links" : "/dashboard"}
          className={sidebar.isOpen ? "d-none " : "d-lg-none"}
          style={{ gridColumn: "2", justifySelf: "center" }}
        >
          <img
            className="align-middle"
            src={`https://storage.googleapis.com/purl-assets/client-logos/${clientName}.png`}
            alt={clientName}
            width="90"
            height="70"
            style={{
              objectFit: "contain",
              maxWidth: "100%",
            }}
          />
        </a>
        <VisitSiteDiv
          className={sidebar.isOpen ? "d-none d-sm-flex" : "d-flex"}
        >
          <LinkedInButton
            href="https://www.linkedin.com/company/p-url/"
            id="linkedin"
            target="_blank"
            color="primary"
            size="lg"
            className="mr-sm-2 mr-0"
          >
            <LinkedIn color="#fff" />
          </LinkedInButton>
          <VisitSiteButton
            id="visitSite"
            color="primary"
            size="lg"
            href="https://www.p-url.com"
            target="_blank"
            className="ml-auto d-sm-flex d-none align-self-center"
          >
            Visit our website
          </VisitSiteButton>
        </VisitSiteDiv>
      </div>
      <Nav
        style={{ marginRight: "12px" }}
        className={sidebar.isOpen ? "d-none" : "ml-auto d-block d-lg-none"}
        navbar
      >
        <UncontrolledDropdown inNavbar>
          <DropdownToggle tag="span">
            <User />
          </DropdownToggle>
          <DropdownMenu right>
            <div className="border-bottom">
              <DropdownItem className="font-weight-bold text-dark pb-0">
                {userName}
              </DropdownItem>
              <DropdownItem className="pt-0 small">
                {isValidPublicDomain ? formattedPlan : userType.role}
              </DropdownItem>
            </div>
            <label
              title="Logout"
              className="m-0 pl-4 mt-1 d-flex align-items-center"
              onClick={() => {
                localStorage.removeItem("user");
                localStorage.removeItem("userPlan");
                localStorage.removeItem("cancelPlan");
                history.push("/auth/sign-in");
              }}
            >
              <LogOutIcon
                className="cursor-pointer mr-2"
                color="#354052"
                size={20}
              />
              <span className="text-dark">Logout</span>
            </label>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
      <UncontrolledTooltip placement="bottom" target="linkedin">
        Follow us on LinkedIn!
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="bottom" target="visitSite">
        p-url.com
      </UncontrolledTooltip>
    </Navbar>
  );
};

export default withRouter(
  connect((store) => ({
    app: store.app,
    sidebar: store.sidebar,
    auth: store.auth.user,
    packageValidity: store.subscriber.packageValidityData,
    newTokenData: store.user.newTokenData,
  }))(NavbarComponent)
);

const VisitSiteDiv = styled.div`
  align-items: center;
  grid-column: 3;
  place-self: center end;
  width: fit-content;
  margin-right: 8px;
  margin-left: auto;
  @media (min-width: 992px) {
    margin-right: 4px;
  }
  @media (min-width: 1200px) {
    margin-right: 20px;
  }
`;

const LinkedIn = styled(LinkedinIn)`
  outline: none;
  aspect-ratio: 1;
  width: 15px;
  @media (min-width: 576px) {
    width: 20px;
  }
`;

const VisitSiteButton = styled(Button)`
  @media (max-width: 794px) {
    padding-inline: 7px;
  }
`;

const LinkedInButton = styled(Button)`
  @media (max-width: 575px) {
    padding: 1px 11px 3px;
    border-radius: 0.2rem;
  }
`;
