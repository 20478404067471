import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { resetLink } from "../../redux/actions/authActions";
import { ResetLinkValidation } from "../validation/validation";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { isValidPublicDomain } from "../../config";

const ResetLink = ({ resetLink }) => {
  const [email, setEmail] = useState("");

  const [validError, setValidError] = useState({
    email: "",
  });
  const clientName = window.location.href.split("/")[2].split(".")[0];

  const handleReset = (data, formIsValid) => {
    if (formIsValid) {
      resetLink(data);
    }
  };

  const newPageTitle = "purl - Reset link";
  document.title = newPageTitle;

  const [longUrl, setLongUrl] = useState("");
  const [linkName, setLinkName] = useState("");
  const [plan, setPlan] = useState("");
  const [amount, setAmount] = useState("");
  const [coupon, setCoupon] = useState("");

  useEffect(() => {
    if (window.location.search) {
      if (window.location.search) {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.size > 0) {
          const queryLinkName = queryParams.get("link_name");
          const queryLongUrl = queryParams.get("long_url");
          const plan = queryParams.get("plan");
          const amount = queryParams.get("amount");
          const coupon = queryParams.get("couponCode");
          if (plan && amount) {
            setPlan(plan);
            setAmount(amount);
            setCoupon(coupon);
          }
          if (queryLinkName && queryLongUrl) {
            let longURL = window.location.search.split("&link_name");
            let long_url = longURL[0].split("?long_url=")[1];
            let fetched_link_name =
              window.location.search.split("&link_name=")[1];
            let linkName = fetched_link_name.replace(/%20/g, " ");
            setLongUrl(long_url);
            setLinkName(linkName);
          }
        }
      }
    }
  }, []);

  const local_user = JSON.parse(localStorage.getItem("user"));
  if (!local_user) {
    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <div className="text-center pb-0">
            <img
              alt={clientName}
              src={`https://storage.googleapis.com/purl-assets/client-logos/${clientName}.png`}
              width="150"
              height="90"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
              }}
            />
          </div>
          <h1 className="h2">Reset password</h1>
          <p className="lead">Enter your email to reset your password</p>
        </div>

        <Card>
          <CardBody>
            <div className="m-sm-4">
              <Form>
                <FormGroup>
                  <Label>
                    Email<span className="text-danger">*</span>
                  </Label>
                  <Input
                    bsSize="lg"
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    placeholder="Enter your email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <span style={{ color: "red" }}>
                    <p>{validError.email}</p>
                  </span>
                </FormGroup>
                <div className="text-center mt-3 pt-3">
                  {/* <Link to="/"> */}
                  <Button
                    color="primary"
                    size="lg"
                    onClick={() => {
                      const { formIsValid, errors } =
                        ResetLinkValidation(email);
                      setValidError(errors);
                      handleReset(email, formIsValid);
                    }}
                  >
                    Send Reset Link
                  </Button>
                  {/* </Link> */}
                </div>
                <div className="pt-3">
                  <h5 style={{ alignItems: "center", textAlign: "center" }}>
                    Return to Sign-in page?{" "}
                    <span>
                      <a
                        href={(() => {
                          if (coupon && plan) {
                            return `/auth/sign-in?plan=${plan}&amount=${amount}&couponCode=${coupon}`;
                          } else if (plan) {
                            return `/auth/sign-in?plan=${plan}&amount=${amount}`;
                          } else if (longUrl) {
                            return `/auth/sign-in?long_url=${longUrl}&link_name=${linkName}`;
                          } else {
                            return "/auth/sign-in";
                          }
                        })()}
                      >
                        Click Here
                      </a>
                    </span>
                  </h5>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  } else {
    return <Redirect to={isValidPublicDomain ? "/links" : "/dashboard"} />;
  }
};
const mapStateToProps = (state) => {
  return {
    loginState: state.auth.loginState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetLink: (data) => dispatch(resetLink(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetLink);
