import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const LinkExpired = () => {
  const clientName = window.location.href.split("/")[2].split(".")[0];

  return (
    <div className="text-center">
      {/* <h1 className="display-1 font-weight-bold">401</h1> */}
      <div className="text-center pb-0">
        <img
          alt={clientName}
          src={`https://storage.googleapis.com/purl-assets/client-logos/${clientName}.png`}
          width="150"
          height="90"
          style={{
            objectFit: "contain",
            maxWidth: "100%"
          }}
        />
      </div>
      <p className="h1">Reset link expired</p>
      <p className="h2 font-weight-normal mt-3 mb-4">
        Your reset password token is invalid or expired. To generate another one{" "}
        <span
          style={{
            color: "blue",
            textDecoration: "underline",
          }}
        >
          <a href="/auth/reset-link">click here</a>
        </span>
      </p>
      <Link to="/auth/sign-in">
        <Button color="primary" size="lg">
          Back to sign-in{" "}
        </Button>
      </Link>
    </div>
  );
};

export default LinkExpired;
