/* eslint-disable no-useless-escape */
import moment from "moment";

export const RegisterFormValidation = (state) => {
  let fields = state;
  let errors = {};
  let formIsValid = true;

  if (!fields["full_name"]) {
    formIsValid = false;
    errors["full_name"] = "*Please enter your full name.";
  }
  if (typeof fields["full_name"] !== "undefined") {
    if (!fields["full_name"].match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors["full_name"] = "*Please enter alphabet characters only.";
    }
  }
  if (!fields["email"]) {
    formIsValid = false;
    errors["email"] = "*Please enter your valid email address.";
  }

  if (fields["email"]) {
    //regular expression for email validation
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(fields["email"])) {
      formIsValid = false;
      errors["email"] = "*Please enter a valid email address.";
    }
  }
  if (!fields["phone"]) {
    formIsValid = false;
    errors["phone"] = "*Please enter your valid phone number.";
  }
  if (typeof fields["phone"] !== "undefined") {
    var patternOne = new RegExp(/^[0-9-+](?=.{11,16}$)/);
    if (!patternOne.test(fields["phone"])) {
      formIsValid = false;
      errors["phone"] = "*Please enter a valid phone number.";
    }
  }
  var patternTwo = new RegExp(/^[0-9-+]/);
  if (!patternTwo.test(fields["phone"])) {
    formIsValid = false;
    errors["country_code"] = "*Please select a country.";
  }

  return { formIsValid, errors };
};

export const LoginFormValidation = (state) => {
  let fields = state;
  let errors = {};
  let formIsValid = true;

  if (!fields["email"]) {
    formIsValid = false;
    errors["email"] = "*Please enter an email address.";
  }
  if (!fields["password"]) {
    formIsValid = false;
    errors["password"] = "*Please enter a password.";
  }
  if (fields["email"]) {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(fields["email"])) {
      formIsValid = false;
      errors["email"] = "*Please enter a valid email address.";
    }
  }

  return { formIsValid, errors };
};

export const ResetLinkValidation = (email) => {
  let errors = {};
  let formIsValid = true;

  if (!email) {
    formIsValid = false;
    errors["email"] = "*Please enter an email address.";
  }
  if (email) {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(email)) {
      formIsValid = false;
      errors["email"] = "*Please enter a valid email address.";
    }
  }

  return { formIsValid, errors };
};

export const EditRequestFormValidation = (state) => {
  let fields = state;
  let errors = {};
  let formIsValid = true;

  if (!fields["full_name"]) {
    formIsValid = false;
    errors["full_name"] = "*Please enter a name.";
  }

  if (typeof fields["full_name"] !== "undefined") {
    if (!fields["full_name"].match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors["full_name"] =
        "*Name must not contain a number or symbol. Please try again.";
    }
  }

  if (!fields["email"]) {
    formIsValid = false;
    errors["email"] = "*Please enter an email address.";
  }

  if (typeof fields["email"] !== "undefined") {
    //regular expression for email validation
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(fields["email"])) {
      formIsValid = false;
      errors["email"] = "*Please enter a valid email address.";
    }
  }

  if (!fields["phone"]) {
    formIsValid = false;
    errors["phone"] = "*Please enter a phone number.";
  }
  if (fields["phone"]) {
    var patternOne = new RegExp(/^[0-9-+](?=.{11,16}$)/);
    if (!patternOne.test(fields["phone"])) {
      formIsValid = false;
      errors["phone"] = "*Please enter a valid phone number.";
    }
  }
  var patternTwo = new RegExp(/^[0-9-+]/);
  if (!patternTwo.test(fields["phone"])) {
    formIsValid = false;
    errors["country_code"] = "*Please select a country.";
  }

  return { formIsValid, errors };
};

export const NewUserFormValidation = (state) => {
  let fields = state;
  let errors = {};
  let formIsValid = true;

  if (!fields["full_name"]) {
    formIsValid = false;
    errors["full_name"] = "*Please enter your full name.";
  }

  if (typeof fields["full_name"] !== "undefined") {
    if (!fields["full_name"].match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors["full_name"] =
        "*Your full name must not contain a number or symbol. Please try again.";
    }
  }

  if (!fields["email"]) {
    formIsValid = false;
    errors["email"] = "*Please enter a valid email address.";
  }

  if (fields["email"]) {
    //regular expression for email validation
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(fields["email"])) {
      formIsValid = false;
      errors["email"] = "*Please enter a valid email address.";
    }
  }

  if (!fields["phone"]) {
    formIsValid = false;
    errors["phone"] = "*Please enter your valid phone number.";
  }
  if (fields["phone"]) {
    var patternOne = new RegExp(/^[0-9-+](?=.{11,16}$)/);
    var patternWithoutCountryCode = new RegExp(/^(?=.{9,14}$)/);
    if (
      !patternOne.test(fields["phone"]) &&
      !patternWithoutCountryCode.test(fields["phone"])
    ) {
      formIsValid = false;
      errors["phone"] = "*Please enter a valid phone number.";
    }
  }
  var patternTwo = new RegExp(/^[0-9-+]/);
  if (!patternTwo.test(fields["phone"])) {
    formIsValid = false;
    errors["country_code"] = "*Please select a country.";
  }

  return { formIsValid, errors };
};

export const ResetPasswordValidation = (state) => {
  let fields = state;
  let errors = {};
  let formIsValid = true;

  if (!fields["password"]) {
    formIsValid = false;
    errors["password"] = "*Please enter your valid password.";
  }
  if (typeof fields["password"] !== "undefined") {
    var pattern = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
    );
    if (!pattern.test(fields["password"])) {
      formIsValid = false;
      errors["password"] =
        "*Password should be min. 8 characters & must contain at least 1 (a-z), (A-Z), (0-9), (!@#$%^&*). ";
    }
  }
  if (!fields["confirmPassword"]) {
    formIsValid = false;
    errors["confirmPassword"] = "*Please re-enter your valid password.";
  }
  if (fields["password"] !== fields["confirmPassword"]) {
    formIsValid = false;
    errors["confirmPassword"] =
      "*Your passwords do not match. Please try again.";
  }

  return { formIsValid, errors };
};

export const subscriberListValidation = (state) => {
  const fields = state;
  const error = {};
  let formIsValid = true;

  if (!fields["campaign_name"]) {
    formIsValid = false;
    error["title"] = "*Please enter a valid name.";
  }
  if (!fields["landing_page"]) {
    formIsValid = false;
    error["landingPage"] = "*Please enter a URL.";
  }

  if (fields["landing_page"]) {
    var urlRegex = new RegExp(
      /^(?:(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+(?:\/[^\s]*)?(?:\?[^#\s]*)?(?:#[^\s]*)?)$/i
    );

    if (!urlRegex.test(fields["landing_page"])) {
      formIsValid = false;
      error["landingPage"] =
        "*Please enter a valid landing page/URL. For example: https://www.google.com";
    }
  }

  if (!fields["domainId"]) {
    formIsValid = false;
    error["domainId"] = "*Please select a valid domain.";
  }

  // if (fields["expiry_state"]) {
  if (!fields["expiry"] || fields["expiry"] === "Invalid date") {
    formIsValid = false;
    error["expiry"] = "*Please enter a valid expiry";
  }
  // }

  if (fields["expiry"]) {
    const currentDate = moment();
    if (moment(fields["expiry"]).isSameOrBefore(currentDate)) {
      formIsValid = false;
      error["expiry"] = "*Please choose a date that is after the current date";
    }
  }

  if (fields["step"] == 1 || fields["file"]?.length < 1) {
    if (fields["file"]?.length < 1) {
      formIsValid = false;
      error["file"] = "*Please select files to upload";
    }
  }

  var utmRegex = new RegExp(/^[^&\s]*$/);

  if (fields["utm_source"]) {
    if (!utmRegex.test(fields["utm_source"])) {
      formIsValid = false;
      error["utmSource"] = "*Invalid parameter.";
    }
  }
  if (fields["utm_medium"]) {
    if (!utmRegex.test(fields["utm_medium"])) {
      formIsValid = false;
      error["utmMedium"] = "*Invalid parameter.";
    }
  }
  if (fields["utm_content"]) {
    if (!utmRegex.test(fields["utm_content"])) {
      formIsValid = false;
      error["utmContent"] = "*Invalid parameter.";
    }
  }
  if (fields["utm_campaign"]) {
    if (!utmRegex.test(fields["utm_campaign"])) {
      formIsValid = false;
      error["utmCampaign"] = "*Invalid parameter.";
    }
  }
  if (state?.checked1) {
    var passwordRegex = new RegExp("^.{8,}$");
    var passwordSpaceRegex = new RegExp("\\s");
    if (!fields["password"]) {
      formIsValid = false;
      error["password"] = "*Please enter a password.";
    }
    if (fields["password"]) {
      if (!passwordRegex.test(fields["password"])) {
        formIsValid = false;
        error["password"] = "*Password must be at least 8 characters long";
      }
      if (passwordSpaceRegex.test(fields["password"])) {
        formIsValid = false;
        error["password"] = "*Password cannot contain spaces.";
      }
    }
  }

  return { formIsValid, error };
};
export const shortUrlValidation = (state) => {
  const fields = state;
  const error = {};
  let formIsValid = true;

  if (!fields["long_url"]) {
    formIsValid = false;
    error["longUrl"] = "*Please enter a URL.";
  }

  if (fields["long_url"]) {
    var urlRegex = new RegExp(
      /^(?:(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+(?:\/[^\s]*)?(?:\?[^#\s]*)?(?:#[^\s]*)?)$/i
    );

    if (!urlRegex.test(fields["long_url"])) {
      formIsValid = false;
      error["longUrl"] = "*Please enter a valid landing URL.";
    }
  }

  if (!fields["link_name"]) {
    formIsValid = false;
    error["name"] = "*Please enter a name.";
  }
  if (fields["link_name"]) {
    if (fields["link_name"].length > 50) {
      formIsValid = false;
      error["name"] =
        "*Maximum length of link name should be less than 50 characters.";
    }

    if (fields["link_name"].trim() !== fields["link_name"]) {
      formIsValid = false;
      error["name"] =
        "*Link name should not contain spaces at the start or end.";
    }
  }

  if (!fields["short_domain_id"]) {
    formIsValid = false;
    error["domainId"] = "*Please select a valid domain.";
  }

  return { formIsValid, error };
};
export const draftCampaignDataValidation = (state) => {
  const fields = state;
  const error = {};
  let formIsValid = true;
  if (!fields["campaign_name"]) {
    formIsValid = false;
    error["title"] = "*Please enter a valid name.";
    return { formIsValid, error };
  }

  if (fields["landing_page"]) {
    var urlRegex = new RegExp(
      /^(?:(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+(?:\/[^\s]*)?(?:\?[^#\s]*)?(?:#[^\s]*)?)$/i
    );

    if (!urlRegex.test(fields["landing_page"])) {
      formIsValid = false;
      error["landingPage"] =
        "*Please enter a valid landing page/URL. For example: https://www.google.com";
    }
  }
  if (fields["file"]?.length === 0) {
    formIsValid = true;
  }
  if (fields["file"]?.length === 0 && fields.fileStatus) {
    formIsValid = false;
    error["file"] = "*Please click on upload button";
  } else if (fields["file"]?.length !== 0 && fields.fileStatus) {
    formIsValid = true;
  }
  if (state?.checked1) {
    var passwordRegex = new RegExp("^.{8,}$");
    var passwordSpaceRegex = new RegExp("\\s");

    if (!fields["password"]) {
      formIsValid = false;
      error["password"] = "*Please enter a password.";
    }
    if (fields["password"]) {
      if (!passwordRegex.test(fields["password"])) {
        formIsValid = false;
        error["password"] = "*Password must be at least 8 characters long.";
      }
      if (passwordSpaceRegex.test(fields["password"])) {
        formIsValid = false;
        error["password"] = "*Password cannot contain spaces.";
      }
    }
  }

  return { formIsValid, error };
};
export const linkInBioValidation = (state, step) => {
  const fields = state;
  const error = {};
  let formIsValid = true;
  if (step === 1) {
    if (!fields["link_name"]) {
      formIsValid = false;
      error["link_name"] = "*Please enter an link name.";
    }

    if (fields["link_url"]) {
      var urlRegex = new RegExp(
        /^(?:(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+(?:\/[^\s]*)?(?:\?[^#\s]*)?(?:#[^\s]*)?)$/i
      );

      if (!urlRegex.test(fields["link_url"])) {
        formIsValid = false;
        error[
          "link_url"
        ] = `*Please enter a valid URL.<br>For example: https://www.google.com`;
      }
      return { formIsValid, error };
    }
  } else if (step === 2) {
    if (fields["username"]) {
      if (fields["username"].includes(" ")) {
        formIsValid = false;
        error["username"] = `*Username cannot contain spaces.`;
      } else if (!fields["username"].match(/^[A-Za-z][A-Za-z0-9_-]*$/)) {
        formIsValid = false;
        error["username"] = `*Please enter a valid username.`;
      }
    }

    if (!fields["username"]) {
      formIsValid = false;
      error["username"] = "*Please enter an username.";
    }
    if (!fields["link_name"]) {
      formIsValid = false;
      error["linkInBioName"] = "*Please enter a name.";
    }
    if (fields["link_name"]) {
      if (fields["link_name"].length > 50) {
        formIsValid = false;
        error["linkInBioName"] =
          "*Maximum length of link name should be less than 50 characters.";
      }

      if (fields["link_name"].trim() !== fields["link_name"]) {
        formIsValid = false;
        error["linkInBioName"] =
          "*Link name should not contain spaces at the start or end.";
      }
    }

    if (!fields["bio"]) {
      formIsValid = false;
      error["bio"] = "*Please enter bio.";
    }
    return { formIsValid, error };
  }
};
export const fileUploadValidation = (state) => {
  const fields = state;
  const error = {};
  let formIsValid = true;

  if (fields["file"].length < 1) {
    formIsValid = false;
    error["file"] = "*Please select at least 1 file.";
  }
  if (fields["file"].length > 0) {
    let overAllFileSize = 0;
    fields["file"].forEach((el) => {
      overAllFileSize += el.size;
      if (el.size > 100000000) {
        formIsValid = false;
        error[
          "file"
        ] = `*${el.name} has exceeded maximum file size limit (allowed size: 100MB)`;
      }
    });
    if (fields["file"].length > 1) {
      if (overAllFileSize > 146800640) {
        formIsValid = false;
        error[
          "file"
        ] = `*Uploaded Files have exceeded maximum file size limit (allowed size: 140MB)`;
      }
    }
  }
  return { formIsValid, error };
};

export const editListValidation = (state) => {
  const fields = state;
  const error = {};
  let formIsValid = true;

  if (!fields["campaign_name"]) {
    formIsValid = false;
    error["title"] = "*Please enter a valid name.";
  }
  if (!fields["landing_page"]) {
    formIsValid = false;
    error["landingPage"] = "*Please enter a URL.";
  }

  if (fields["landing_page"]) {
    var urlRegex = new RegExp(
      /^(?:(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+(?:\/[^\s]*)?(?:\?[^#\s]*)?(?:#[^\s]*)?)$/i
    );
    if (!urlRegex.test(fields["landing_page"])) {
      formIsValid = false;
      error["landingPage"] =
        "*Please enter a valid landing page/URL. For example: https://www.google.com";
    }
  }

  if (!fields["domainId"]) {
    formIsValid = false;
    error["domainId"] = "*Please select a valid domain.";
  }

  // if (fields["expiry_state"]) {
  if (!fields["expiry"] || fields["expiry"] === "Invalid date") {
    formIsValid = false;
    error["expiry"] = "*Please enter a valid expiry";
  }
  // }

  // if (fields["expiry"]) {
  //   const currentDate = moment();
  //   if (moment(fields["expiry"]).isSameOrBefore(currentDate)) {
  //     formIsValid = false;
  //     error["expiry"] = "*Please choose a date that is after the current date";
  //   }
  // }

  var utmRegex = new RegExp(/^[^&\s]*$/);

  if (fields["utm_source"]) {
    if (!utmRegex.test(fields["utm_source"])) {
      formIsValid = false;
      error["utmSource"] = "*Invalid parameter.";
    }
  }
  if (fields["utm_medium"]) {
    if (!utmRegex.test(fields["utm_medium"])) {
      formIsValid = false;
      error["utmMedium"] = "*Invalid parameter.";
    }
  }
  if (fields["utm_content"]) {
    if (!utmRegex.test(fields["utm_content"])) {
      formIsValid = false;
      error["utmContent"] = "*Invalid parameter.";
    }
  }
  if (fields["utm_campaign"]) {
    if (!utmRegex.test(fields["utm_campaign"])) {
      formIsValid = false;
      error["utmCampaign"] = "*Invalid parameter.";
    }
  }
  if (fields["file"]?.length === 0 && fields.fileStatus) {
    formIsValid = false;
    error["file"] = "*Please click on upload button";
  }

  return { formIsValid, error };
};

export const supportValidation = (state) => {
  const fields = state;
  const error = {};
  let formIsValid = true;

  if (fields["checkbox"].length === 0) {
    formIsValid = false;
    error["checkbox"] = "*Please select query type.";
  }
  if (!fields["description"]) {
    formIsValid = false;
    error["description"] = "*Please enter query description.";
  }
  if (fields["file"].length > 0) {
    let overAllFileSize = 0;
    fields["file"].forEach((el) => {
      overAllFileSize += el.size;
      if (el.size > 5242880) {
        formIsValid = false;
        error[
          "file"
        ] = `*${el.name} has exceeded maximum file size limit (allowed size: 5MB)`;
      }
    });
    if (fields["file"].length > 1) {
      if (overAllFileSize > 5242880) {
        formIsValid = false;
        error[
          "file"
        ] = `*Uploaded Files have exceeded maximum file size limit (allowed size: 5MB)`;
      }
    }
  }
  return { formIsValid, error };
};

export const SystemUserValidation = (state) => {
  let fields = state;
  let errors = {};
  let formIsValid = true;

  if (!fields["system_name"]) {
    formIsValid = false;
    errors["system_name"] = "*Please enter a system name.";
  }

  if (!fields["system_token_expiry"]) {
    formIsValid = false;
    errors["expiry"] = "*Please enter expiry date in days";
  }

  return { formIsValid, errors };
};

export const RegenerateTokenValidation = (state) => {
  let fields = state;
  let errors = {};
  let formIsValid = true;

  if (!fields["system_token_expiry"]) {
    formIsValid = false;
    errors["expiry"] = "*Please enter expiry date in days";
  }

  return { formIsValid, errors };
};

export const AnalyticsFormValidation = (state) => {
  let fields = state;
  let errors = {};
  let formIsValid = true;

  if (!fields["tool"]) {
    formIsValid = false;
    errors["tool"] = "*Please select a tool";
  }

  if (!fields["param"]) {
    formIsValid = false;
    errors["param"] = "*Please enter tracking parameter name";
  }

  if (fields["param"]) {
    var spaceRegex = new RegExp(/^\S*$/);
    if (!spaceRegex.test(fields["param"])) {
      formIsValid = false;
      errors["param"] = "*Parameter name should not contain any spaces";
    }
  }

  if (fields["toolType"] === "mixpanel") {
    if (!fields["mixpanelToken"]) {
      formIsValid = false;
      errors["mixpanelToken"] = "*Please enter the mixpanel token";
    }
  }

  return { formIsValid, errors };
};

export const PersonalisationFormValidation = (state) => {
  let fields = state;
  let errors = {};
  let formIsValid = true;

  if (!fields["tool"]) {
    formIsValid = false;
    errors["tool"] = "*Please select a tool";
  }

  if (!fields["param"]) {
    formIsValid = false;
    errors["param"] = "*Please enter parameter name";
  }

  if (fields["param"]) {
    var spaceRegex = new RegExp(/^\S*$/);
    if (!spaceRegex.test(fields["param"])) {
      formIsValid = false;
      errors["param"] = "*Parameter name should not contain any spaces";
    }
  }

  if (fields["tool"] === "ab-tasty") {
    if (!fields["token"]) {
      formIsValid = false;
      errors["token"] = "*Please enter the AB Tasty ID";
    }
  }

  if (fields["tool"] === "braze") {
    if (!fields["apiKey"]) {
      formIsValid = false;
      errors["apiKey"] = "*Please enter the Braze API Key";
    }
    if (!fields["baseUrl"]) {
      formIsValid = false;
      errors["baseUrl"] = "*Please enter the Braze Base URL";
    }
  }

  return { formIsValid, errors };
};

export const StepOneValidation = (state) => {
  let fields = state;
  let error = {};
  let formIsValid = true;

  if (!fields["campaign_expiry_fallback_url"]) {
    formIsValid = false;
    error["url"] = "*Please enter the fallback URL.";
  }

  if (fields["campaign_expiry_fallback_url"]) {
    var urlRegex = new RegExp(
      /^(?:(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+(?:\/[\w\-\.\/]*)*)$/i
    );
    if (!urlRegex.test(fields["campaign_expiry_fallback_url"])) {
      formIsValid = false;
      error["url"] =
        "*Please enter a valid URL. For example: https://www.google.com";
    }
  }
  return { formIsValid, error };
};

export const StepTwoValidation = (state, length) => {
  let fields = state;
  let error = {};
  let userValid = true;
  let emptySingleUser = false;

  if (
    !fields["full_name"] &&
    !fields["email"] &&
    !fields["role_id"] &&
    !fields["phone"] &&
    length == 1
  ) {
    emptySingleUser = true;
    return { userValid, error, emptySingleUser };
  } else {
    if (!fields["full_name"]) {
      userValid = false;
      error["name"] = "*Please enter the name.";
    }

    if (!fields["email"]) {
      userValid = false;
      error["email"] = "*Please enter the email.";
    }

    if (fields["email"]) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        userValid = false;
        error["email"] = "*Please enter a valid email address.";
      }
    }

    if (fields["phone"]) {
      var patternOne = new RegExp(/^[0-9-+](?=.{9,14}$)/);
      if (!patternOne.test(fields["phone"])) {
        userValid = false;
        error["phone"] = "*Please enter a valid phone number.";
      }
    }

    if (!fields["role_id"]) {
      userValid = false;
      error["role"] = "*Please enter the user role.";
    }
  }
  return { userValid, error, emptySingleUser };
};

export const StepThreeValidation = (state) => {
  let fields = state;
  let error = {};
  let formIsValid = true;

  if (!fields["alerts_email"]) {
    formIsValid = false;
    error["email"] = "*Please enter an email address.";
  }

  if (fields["alerts_email"]) {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(fields["alerts_email"])) {
      formIsValid = false;
      error["email"] = "*Please enter a valid email address.";
    }
  }

  if (!fields["mfa_type"]) {
    formIsValid = false;
    error["mfa"] = "*Please select the Two-Factor Authentication Security.";
  }
  return { formIsValid, error };
};

export const GetStartedFormValidation = (state) => {
  let fields = state;
  let error = {};
  let formIsValid = true;

  if (!fields["field_name"]) {
    formIsValid = false;
    error["name"] = "*Please enter your name.";
  }

  if (typeof fields["field_name"] !== "undefined") {
    if (!fields["field_name"].match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      error["name"] =
        "*Name must not contain a number or symbol. Please try again.";
    }
  }
  if (!fields["field_country"]) {
    formIsValid = false;
    error["country"] = "*Please select your country.";
  }
  if (!fields["field_phone"]) {
    formIsValid = false;
    error["phone"] = "*Please enter your phone number.";
  }
  if (fields["field_phone"]) {
    var patternOne = new RegExp(/^[0-9-+](?=.{9,14}$)/);
    if (!patternOne.test(fields["field_phone"])) {
      formIsValid = false;
      error["phone"] = "*Please enter a valid phone number.";
    }
  }

  if (!fields["field_email"]) {
    formIsValid = false;
    error["email"] = "*Please enter your email.";
  }
  if (fields["field_email"]) {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(fields["field_email"])) {
      formIsValid = false;
      error["email"] = "*Please enter a valid email address.";
    }
  }
  if (!fields["field_password"] || fields["field_password"].length === 0) {
    formIsValid = false;
    error["password"] = "*Please enter a password.";
  }
  if (
    typeof fields["field_password"] !== "undefined" &&
    fields["field_password"].length !== 0
  ) {
    var passwordPattern = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
    );
    if (!passwordPattern.test(fields["field_password"])) {
      formIsValid = false;
      error["password"] =
        "*Password should be min. 8 characters & must contain at least 1 (a-z), (A-Z), (0-9), (!@#$%^&*). ";
    }
  }
  if (!fields["field_use"]) {
    formIsValid = false;
    error["use"] = "*Please select an option.";
  }
  return { formIsValid, error };
};

export const GetStartedSecondForm = (state) => {
  let fields = state;
  let formIsValid = true;
  let error = {};
  if (!fields["company"]) {
    formIsValid = false;
    error["company"] = "*Please enter the company name.";
  }
  if (!fields["industry"]) {
    formIsValid = false;
    error["industry"] = "*Please enter the industry name.";
  }
  if (!fields["role"]) {
    formIsValid = false;
    error["role"] = "*Please enter your role.";
  }
  return { formIsValid, error };
};

export const newPasswordValidation = (state) => {
  const fields = state;
  const error = {};
  let dataIsValid = true;
  if (!fields["newPassword"]) {
    dataIsValid = false;
    error["newPassword"] = "*Please enter a password.";
  }
  if (!fields["oldPassword"]) {
    dataIsValid = false;
    error["oldPassword"] = "*Please enter a password.";
  }
  var passwordRegex = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
  );
  if (fields["newPassword"]) {
    if (!passwordRegex.test(fields["newPassword"])) {
      dataIsValid = false;
      error["newPassword"] =
        "*Password should be min. 8 characters & must contain at least 1 (a-z), (A-Z), (0-9), (!@#$%^&*). ";
    }
  }
  if (fields["oldPassword"]) {
    if (!passwordRegex.test(fields["oldPassword"])) {
      dataIsValid = false;
      error["oldPassword"] =
        "*Password should be min. 8 characters & must contain at least 1 (a-z), (A-Z), (0-9), (!@#$%^&*). ";
    }
  }

  if (fields["confirmPassword"] !== fields["newPassword"]) {
    dataIsValid = false;
    error["passwordMissMatch"] =
      "*Password doesnot matched with the new entered password. ";
  }
  if (fields["oldPassword"] && fields["newPassword"]) {
    if (fields["oldPassword"] === fields["newPassword"]) {
      dataIsValid = false;
      error["passwordMatched"] =
        "*Old password and new password should not be same. ";
    }
  }

  return { dataIsValid, error };
};
export const newNameValidation = (state) => {
  const fields = state;
  const error = {};
  let dataIsValid = true;
  if (!fields["full_name"]) {
    dataIsValid = false;
    error["full_name"] = "*Please enter a new name.";
  }

  return { dataIsValid, error };
};
export const planCancellationValidation = (state) => {
  const fields = state;
  const error = {};
  let dataIsValid = true;
  if (!fields["reason"]) {
    dataIsValid = false;
    error["reason"] = "*Please select a reason.";
  }

  return { dataIsValid, error };
};
