import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";

// const asyncFunctionMiddleware = storeAPI => next => action => {
//     // If the "action" is actually a function instead...
//     if (typeof action === 'GET_SUBSCRIBER_LIST') {
//       // then call the function and pass `dispatch` and `getState` as arguments
//       return action(storeAPI.dispatch, storeAPI.getState)
//     }

//     // Otherwise, it's a normal action - send it onwards
//     return next(action)
//   }
// var Debug = require("redux-debug");
// var debug = require("debug")("redux");

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
