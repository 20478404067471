import axios from "axios";
import * as types from "../constants";
import Toastr from "../../pages/misc/Toaster";
import { BACKEND_ENDPOINT } from "../../config";
import download from "downloadjs";

import moment from "moment";
//ACTION FOR FETCH REPORT
export const fetchReport = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/report/graph`;
    axios
      .get(reqURL, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.FETCH_REPORTS, payload: res.data });
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: types.REPORTS_ERROR,
            payload: error.response.data.message,
          });
          Toastr(error.response.data.message, "error");
        }
      });
  };
};

export const fetchReportDate = (
  startDate,
  endDate,
  sublist,
  shortdomain,
  tagdata,
  teamData
) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/report/graph?start_date=${startDate}&end_date=${endDate}&campaign_id=${sublist}&short_domain_id=${shortdomain}&tag_ids=${tagdata}&team_ids=${teamData}`;
    axios
      .get(reqURL, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        if (res.data.click_array.length <= 2) {
          res.data.click_array.push(0);
          res.data.click_array.unshift(0);
        }
        if (res.data.data_upload_array.length <= 2) {
          res.data.data_upload_array.push(0);
          res.data.data_upload_array.unshift(0);
        }
        if (res.data.date_array.length <= 2) {
          res.data.date_array.push("");
          res.data.date_array.unshift("");
        }
        dispatch({ type: types.FETCH_REPORTS_DATE, payload: res.data });
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: types.REPORTS_ERROR,
            payload: error.response.data.message,
          });
          Toastr(error.response.data.message, "error");
        }
      });
  };
};

//ACTION FOR CALENDER
export const fetchDate = (endDate, startDate) => {
  return (dispatch) => {
    dispatch({
      type: types.FETCH_DATE,
      payload: { endDate: endDate, startDate: startDate },
    });
  };
};

//ACTION FOR FILTERS
export const fetchFilteredData = (
  teamArray,
  sublist,
  shortDomain,
  tagdata,
  type
) => {
  return (dispatch) => {
    dispatch({
      type: types.FETCH_FILTERED_DATA,
      payload: {
        teamData: teamArray,
        subList: sublist,
        shortDomain: shortDomain,
        tagData: tagdata,
        type: type,
      },
    });
  };
};

export const setSearch = (search) => {
  return (dispatch) => {
    dispatch({
      type: types.FETCH_FILTERED_DATA_BUTTON,
      payload: search,
    });
  };
};

export const setRefresh = (refresh) => {
  return (dispatch) => {
    dispatch({
      type: types.FILTERED_DATA_REFRESH,
      payload: refresh,
    });
  };
};

export const resetTopTen = () => {
  return (dispatch) => {
    dispatch({
      type: types.RESET_TOP_TEN,
    });
  };
};

export const resetPage = () => {
  return (dispatch) => {
    dispatch({
      type: types.RESET_PAGE,
    });
  };
};

//ACTION FOR DATA IN REPORTS PAGE
export const fetchReportData = (
  start_date,
  end_date,
  sublist,
  shortdomain,
  tagdata,
  teamData
) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/report/table?start_date=${start_date}&end_date=${end_date}&campaign_id=${sublist}&short_domain_id=${shortdomain}&tag_ids=${tagdata}&team_ids=${teamData}`;
    axios
      .get(reqURL, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.FETCH_REPORTS_DATA, payload: res.data });
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: types.REPORTS_DATA_ERROR,
            payload: error.response.data.message,
          });
          Toastr(error.response.data.message, "error");
        }
      });
  };
};

//ACTION FOR TOP 10 URLS
export const topTen = (start_date, end_date, sublist) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/report/top?from_date=${start_date}&to_date=${end_date}&campaign_id=${sublist}`;
    axios
      .get(reqURL, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.FETCH_TOPTEN_URLS, payload: res.data });
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: types.REPORTS_DATA_ERROR,
            payload: error.response.data.message,
          });
          Toastr(error.response.data.message, "error");
        }
      });
  };
};

//ACTION FOR RAW CLICK DATA DOWNLOAD
export const downloadRawClick = (
  type,
  name,
  subListId,
  short_domain_id,
  start_date,
  end_date
) => {
  return async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const authorization = user.authorization.split("=");
      const reqUrl = `${BACKEND_ENDPOINT}/report/download_report?campaign_id=${subListId}&short_domain_id=${short_domain_id}&to_date=${moment(
        end_date
      ).format("YYYY-MM-DD")}&from_date=${moment(start_date).format(
        "YYYY-MM-DD"
      )}&type=${type}`;

      const response = await axios.get(reqUrl, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
        responseType: "blob",
      });

      const file = new Blob([response.data], { type: "application/zip" });
      if (type === "no_range") {
        download(file, `Report-${name}-all-clicks.zip`);
        Toastr("File downloaded successfully. Thank you.", "Success");
      } else if (type === "range") {
        download(
          file,
          `Report-${name}-${moment(start_date).format(
            "YYYY-MM-DD"
          )}-to-${moment(end_date).format("YYYY-MM-DD")}.zip`
        );
        Toastr("File downloaded successfully. Thank you.", "Success");
      } else {
        download(file, `Report-${name}-unclicked.zip`);
        Toastr("File downloaded successfully. Thank you.", "Success");
      }
    } catch (error) {
      if (error.response) {
        let { message } = JSON.parse(await error.response.data.text());
        Toastr(message, "error");
      }
    }
  };
};

export const fetchLinksData = (start_date, end_date, type, shortdomain) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/report/linksReportCharts?start_date=${start_date}&end_date=${end_date}&short_domain_id=${shortdomain}&type=${type}`;
    axios
      .get(reqURL, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.FETCH_LINKS_DATA, payload: res.data });
      })
      .catch(function (error) {
        if (error.response) {
          Toastr(error.response.data.message, "error");
        }
      });
  };
};
export const fetchGraphData = (start_date, end_date, type, shortdomain) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/report/linksReportGraph?start_date=${start_date}&end_date=${end_date}&short_domain_id=${shortdomain}&type=${type}`;
    axios
      .get(reqURL, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.FETCH_GRAPH_DATA, payload: res.data });
      })
      .catch(function (error) {
        if (error.response) {
          Toastr(error.response.data.message, "error");
        }
      });
  };
};

export const downloadLinksReport = (link_id, type) => {
  return async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const authorization = user.authorization.split("=");
      const reqUrl = `${BACKEND_ENDPOINT}/report/downloadLinkReport?link_id=${link_id}`;

      const response = await axios.get(reqUrl, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
        responseType: "blob",
      });
      const file = new Blob([response.data], { type: "application/zip" });
      if (file) {
        download(file, `${type}-report.zip`);
        Toastr("File downloaded successfully. Thank you.", "Success");
      }
    } catch (error) {
      if (error.response) {
        let { message } = JSON.parse(await error.response.data.text());
        Toastr(message, "error");
      }
    }
  };
};

export const fetchMiniLinksData = (linkId) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/campaign/linkInBioMiniLink?link_id=${linkId}`;
    axios
      .get(reqURL, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.MINI_LINKS_DATA, payload: res.data });
      })
      .catch(function (error) {
        if (error.response) {
          Toastr(error.response.data.message, "error");
        }
      });
  };
};
