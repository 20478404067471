import React, { useState, useEffect } from "react";
import {
  CardBody,
  Input,
  Label,
  Button,
  Row,
  Col,
  Card,
  CardImg,
  UncontrolledTooltip,
} from "reactstrap";
import { Trash } from "styled-icons/bootstrap";
import { Plus } from "react-feather";
import { connect } from "react-redux";
import { linkInBioValidation } from "../../../validation/validation";
import { ChromePicker } from "react-color";
import { createLinkInBio } from "../../../../redux/actions/subscriberActions";
import { emptyAddNewLinkInBioStatus } from "../../../../redux/actions/subscriberActions";
import { useHistory } from "react-router-dom";
import defaultProfile from "../../../../assets/img/avatars/defaultProfile.png";
import { InfoCircleFill } from "@styled-icons/bootstrap/InfoCircleFill";
const LinkInBio = ({
  createLinkInBio,
  addLinkInBioStatus,
  emptyAddNewLinkInBioStatus,
  linkInBioLimit,
}) => {
  const [step, setStep] = useState(1);
  const [linkName, setLinkName] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [links, setLinks] = useState([]);
  const [linkInBioName, setLinkInBioName] = useState("");
  const [username, setUsername] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [profilePhotoPreview, setProfilePhotoPreview] = useState("");
  const [addProfilePhotoPreview, setAddProfilePhotoPreview] = useState("");
  const [error, setError] = useState({});
  const [pageTwoErrors, setPageTwoErrors] = useState({
    username: "",
    bio: "",
    linkInBioName: "",
  });
  const [colorScheme, setColorScheme] = useState("");
  const [bio, setBio] = useState("");
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [photoError, setPhotoError] = useState("");
  const [textColor, setTextColor] = useState("#ffffff");
  const history = useHistory();
  useEffect(() => {
    if (addLinkInBioStatus && addLinkInBioStatus === 200) {
      history.push("/links");
    }
    return () => {
      emptyAddNewLinkInBioStatus();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addLinkInBioStatus]);
  const handleNextStep = () => {
    setStep(2);
  };

  const handlePrev = () => {
    setStep(1);
  };

  const handleAddLink = () => {
    const finalData = {
      link_name: linkName,
      link_url: linkUrl,
    };

    const { formIsValid, error } = linkInBioValidation({ ...finalData }, 1);
    setError(error);
    if (formIsValid) {
      setLinks([...links, { name: linkName, url: linkUrl }]);
      setLinkName("");
      setLinkUrl("");
    } else {
      setError(error);
    }
  };

  const handleDeleteLink = (index) => {
    const newLinks = links.filter((_, i) => i !== index);
    setLinks(newLinks);
  };

  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setPhotoError("*Image size must be less than 5MB.");
        setProfilePhoto("");
        setProfilePhotoPreview("");
        setAddProfilePhotoPreview("");
      } else {
        setProfilePhoto(file);
        setProfilePhotoPreview(URL.createObjectURL(file));
        setPhotoError("");
      }
    }
  };

  const handleButtonClick = () => {
    document.getElementById("profilePhotoInput").click();
  };

  const handleAddProfilePhoto = () => {
    setAddProfilePhotoPreview(profilePhotoPreview);
  };
  const handleRemoveProfilePhoto = () => {
    setProfilePhoto("");
    setProfilePhotoPreview("");
    setAddProfilePhotoPreview("");
    setPhotoError("");
    // Reset the value of the file input element
    const fileInput = document.getElementById("profilePhotoInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };
  const handleColorSchemeChange = (color) => {
    setColorScheme(color.hex);
    setTextColor(isDarkColor(color.hex) ? "#FFFFFF" : "#000000");
  };
  const handleDefaultColorTheme = () => {
    setColorScheme("");
  };

  const toggleColorPicker = () => {
    setColorPickerOpen(!colorPickerOpen);
  };

  const getBackgroundColor = () => {
    if (colorScheme !== "") {
      return colorScheme;
    } else {
      return "linear-gradient(180deg, rgba(30, 178, 210, 0.8) 0%, rgba(143, 82, 243, 0.8) 100%)";
    }
  };
  const handleSubmit = () => {
    const finalData = {
      link_in_bio: links,
      username: username,
      link_name: linkInBioName,
      profile_pic: profilePhoto,
      background_theme:
        colorScheme !== ""
          ? colorScheme
          : "linear-gradient(180deg, rgba(30, 178, 210, 0.8) 0%, rgba(143, 82, 243, 0.8) 100%)",
      bio: bio,
      text_color: textColor,
    };

    const { formIsValid, error } = linkInBioValidation({ ...finalData }, 2);
    setPageTwoErrors(error);

    if (formIsValid) {
      const formData = new FormData();
      formData.append("link_in_bio", JSON.stringify(finalData.link_in_bio));
      formData.append("username", finalData.username);
      formData.append("link_name", finalData.link_name);
      formData.append("background_theme", finalData.background_theme);
      formData.append("bio", finalData.bio);
      formData.append("profile_pic", finalData.profile_pic);
      formData.append("text_color", finalData.text_color);
      createLinkInBio(formData);
    }
  };

  const isDarkColor = (hexColor) => {
    let r = parseInt(hexColor.slice(1, 3), 16);
    let g = parseInt(hexColor.slice(3, 5), 16);
    let b = parseInt(hexColor.slice(5, 7), 16);
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminance < 128;
  };

  React.useEffect(() => {
    setPageTwoErrors((prevError) => ({
      ...prevError,
      username: username ? "" : prevError.username,
      bio: bio ? "" : prevError.bio,
      linkInBioName: linkInBioName ? "" : prevError.linkInBioName,
    }));
  }, [username, bio, linkInBioName]);
  return (
    <>
      {step === 1 ? (
        <div>
          <CardBody>
            <Label className="one-rem-font-size">Add your Links</Label>
            <Row>
              <Col md="9">
                {links?.map((link, index) => (
                  <Row key={index} className="mb-3 align-items-center">
                    <Col xs="10" md="4" className="mb-3 mb-md-0">
                      <Input
                        className="custom-input w-100"
                        bsSize="lg"
                        type="text"
                        value={link.name}
                        disabled
                      />
                    </Col>
                    <Col xs="2" className="d-md-none mb-3 mb-md-0 text-right ">
                      <Trash
                        size={24}
                        className="cursor-pointer"
                        color="#d9534f"
                        id="delete"
                        style={{
                          border: "none",
                          outline: "none",
                        }}
                        onClick={() => handleDeleteLink(index)}
                      />
                    </Col>
                    <Col xs="12" md="6" className="mb-3 mb-md-0">
                      <Input
                        className="custom-input w-100"
                        bsSize="lg"
                        type="text"
                        value={link.url}
                        disabled
                      />
                    </Col>
                    <Col
                      xs="12"
                      md="2"
                      className="d-none d-md-block text-right text-md-left"
                    >
                      <Trash
                        size={24}
                        className="cursor-pointer"
                        color="#d9534f"
                        id="delete"
                        style={{
                          border: "none",
                          outline: "none",
                        }}
                        onClick={() => handleDeleteLink(index)}
                      />
                    </Col>
                  </Row>
                ))}

                <Row className="align-items-start">
                  <Col md="4">
                    <Input
                      className="custom-input w-100 "
                      bsSize="lg"
                      type="text"
                      name="linkName"
                      id="linkName"
                      value={linkName}
                      placeholder="Link Name"
                      onChange={(e) => {
                        const inputValue = e.target.value;

                        setLinkName(inputValue);

                        if (e.keyCode === 8) {
                          setLinkName(inputValue);
                        }
                      }}
                      style={
                        error?.link_name
                          ? {
                              border: "1px solid red",
                            }
                          : {}
                      }
                      required
                    />
                    {error?.link_name && (
                      <div
                        style={{
                          color: "red",
                          width: "100%",
                          position: "absolute",
                        }}
                      >
                        <p>{error?.link_name}</p>
                      </div>
                    )}
                  </Col>
                  <Col md="6">
                    {" "}
                    <Input
                      className="custom-input mt-3 mt-md-0 w-100"
                      bsSize="lg"
                      type="text"
                      name="linkUrl"
                      id="linkUrl"
                      value={linkUrl}
                      style={
                        error?.link_url
                          ? {
                              border: "1px solid red",
                            }
                          : {}
                      }
                      placeholder="Link"
                      onChange={(e) => setLinkUrl(e.target.value)}
                      required
                    />
                    {error?.link_url && (
                      <div
                        style={{
                          color: "red",
                          width: "100%",
                          position: "absolute",
                        }}
                      >
                        <p
                          dangerouslySetInnerHTML={{ __html: error.link_url }}
                        ></p>
                      </div>
                    )}
                  </Col>
                  <Col
                    md="2"
                    className={`${
                      error?.link_url ? "mt-4" : "mt-3"
                    } text-right text-md-left d-md-block mt-md-0`}
                  >
                    <Button
                      color="primary"
                      className=" mb-3 mb-md-0 one-rem-font-size d-lg-block cursor-pointer"
                      onClick={handleAddLink}
                      disabled={!linkName || !linkUrl}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col sm="6" md="3" xs="12">
                <Card
                  className="text-center p-3"
                  style={{
                    background: getBackgroundColor(),
                    color: "white",
                    borderRadius: "15px",
                    aspectRatio: "9/16",
                  }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={profilePhotoPreview || defaultProfile}
                    alt="Profile"
                    className="rounded-circle mx-auto bg-white"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      backgroundColor: "white",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      margin: "0",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {username ? username : "@Username..."}
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      margin: "0",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {bio ? bio : "bio..."}
                  </p>
                  <div>
                    {links.map((link, index) => (
                      <Input
                        key={index}
                        style={{
                          marginTop: "0.5rem",
                          backgroundColor: "white",
                          textAlign: "center",
                          lineHeight: "21px",
                          fontSize: "16px",

                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          pointerEvents: "none",
                        }}
                        className="w-full"
                        readOnly
                        placeholder={link.name}
                      ></Input>
                    ))}
                  </div>
                </Card>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleNextStep}
                color="primary"
                className="one-rem-font-size d-lg-block"
                disabled={links.length < 1}
              >
                Next
              </Button>
            </div>
          </CardBody>
        </div>
      ) : (
        <div>
          <CardBody>
            <Row>
              <Col sm="8">
                <div style={{ marginBottom: "1rem" }}>
                  <Label className="one-rem-font-size">
                    Give a name to your link in bio link
                    <span style={{ color: "#d9534f" }}>*</span>
                    <InfoCircleFill
                      size={16}
                      className="cursor-pointer primary-color ml-2 mb-1"
                      id="nameInfo"
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                    />
                    <UncontrolledTooltip placement="top" target="nameInfo">
                      This is a required field when creating a link in bio
                      because this name will help you easily analyse the link's
                      performance on the link analytics page
                    </UncontrolledTooltip>
                  </Label>
                  <div>
                    <Input
                      className="custom-input w-100 mr-md-4 mr-2"
                      bsSize="lg"
                      type="text"
                      name="linkName"
                      id="linkName"
                      value={linkInBioName}
                      placeholder="Add your link name"
                      onChange={(e) => {
                        setLinkInBioName(e.target.value);
                      }}
                      style={
                        pageTwoErrors?.linkInBioName
                          ? { border: "1px solid red" }
                          : {}
                      }
                      required
                    />

                    <div
                      style={{
                        color: "red",
                        width: "100%",
                      }}
                    >
                      <p>{pageTwoErrors?.linkInBioName}</p>
                    </div>
                  </div>
                </div>
                <div style={{ marginBottom: "1rem" }}>
                  <Label className="one-rem-font-size">
                    Add your username<span style={{ color: "#d9534f" }}>*</span>
                  </Label>
                  <div>
                    <Input
                      className="custom-input w-100 mr-md-4 mr-2"
                      bsSize="lg"
                      type="text"
                      name="username"
                      id="username"
                      value={username}
                      placeholder="Add your username"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 25) {
                          setUsername(inputValue);
                        }
                        if (e.keyCode === 8) {
                          setUsername(inputValue);
                        }
                      }}
                      style={
                        pageTwoErrors?.username
                          ? { border: "1px solid red" }
                          : {}
                      }
                      required
                    />

                    <div
                      style={{
                        color: "red",
                        width: "100%",
                      }}
                    >
                      <p>{pageTwoErrors?.username}</p>
                    </div>
                  </div>
                </div>
                <div style={{ marginBottom: "1rem" }}>
                  <Label className="one-rem-font-size">
                    Add your profile photo
                  </Label>
                  <div className="d-flex align-items-center">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Input
                        type="file"
                        accept="image/*"
                        onChange={handleProfilePhotoChange}
                        style={{ display: "none" }}
                        id="profilePhotoInput"
                      />
                      <Label
                        for="profilePhotoInput"
                        className="cursor-pointer position-relative"
                      >
                        <CardImg
                          top
                          width="100%"
                          src={profilePhotoPreview || defaultProfile}
                          alt="Profile"
                          className="rounded-circle mx-auto"
                          style={{
                            width: "80px",
                            height: "80px",
                            objectFit: "cover",
                          }}
                        />
                        <Button
                          type="button"
                          onClick={handleButtonClick}
                          color="primary"
                          className="p-0 position-absolute top-0 d-flex align-items-center"
                          style={{
                            height: "22px",
                            width: "22px",
                            bottom: "0px",
                            transform: "translateX(-50%) translateY(20%)",
                            left: "50%",
                          }}
                        >
                          <Plus style={{ height: "20px", width: "20px" }} />
                        </Button>
                      </Label>
                    </div>

                    <div className="text-right text-md-left d-md-block mt-md-0">
                      <Button
                        color="primary"
                        className="one-rem-font-size d-lg-block cursor-pointer ml-2"
                        onClick={handleAddProfilePhoto}
                        disabled={!profilePhoto}
                      >
                        Add
                      </Button>
                    </div>

                    <div className="text-right text-md-left d-md-block mt-md-0">
                      <Button
                        color="danger"
                        className="one-rem-font-size d-lg-block cursor-pointer ml-2"
                        onClick={handleRemoveProfilePhoto}
                        disabled={!profilePhoto}
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                  {photoError && (
                    <p style={{ color: "red", marginTop: "10px" }}>
                      {photoError}
                    </p>
                  )}
                </div>
                <div
                  className="one-rem-font-size"
                  style={{ marginBottom: "0.5rem" }}
                >
                  Choose your color scheme
                </div>
                <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      onClick={toggleColorPicker}
                      color="primary"
                      className="one-rem-font-size bg-white text-primary border border-primary"
                      style={{ flexShrink: 0 }}
                    >
                      {colorPickerOpen
                        ? "Add color to the card"
                        : "Choose a color scheme"}
                    </Button>
                    {colorScheme !== "#1EB2D2" && !colorPickerOpen && (
                      <Button
                        onClick={handleDefaultColorTheme}
                        color="primary"
                        className="one-rem-font-size bg-white text-primary border border-primary"
                        style={{ flexShrink: 0 }}
                      >
                        Change to default theme
                      </Button>
                    )}
                  </div>
                  {colorPickerOpen && (
                    <div>
                      <ChromePicker
                        color={colorScheme}
                        onChange={handleColorSchemeChange}
                        disableAlpha
                      />
                    </div>
                  )}
                </div>

                <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                  <Label className="one-rem-font-size">
                    Add your bio<span style={{ color: "#d9534f" }}>*</span>
                  </Label>
                  <div>
                    <Input
                      className="custom-input w-100 mr-md-4 mr-2"
                      bsSize="lg"
                      type="text"
                      name="bio"
                      id="bio"
                      value={bio}
                      placeholder="Add your bio"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 300) {
                          setBio(inputValue);
                        }
                        if (e.keyCode === 8) {
                          setBio(inputValue);
                        }
                      }}
                      style={
                        pageTwoErrors?.bio ? { border: "1px solid red" } : {}
                      }
                      required
                    />
                    <div
                      style={{
                        color: "red",
                        width: "100%",
                      }}
                    >
                      <p>{pageTwoErrors?.bio}</p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col md="1">
                {/* This column acts as the spacer between the two main columns */}
              </Col>

              <Col sm="3">
                <Card
                  className="text-center p-3"
                  style={{
                    background: getBackgroundColor(),
                    color: "white",
                    borderRadius: "15px",
                    aspectRatio: "9/16",
                  }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={addProfilePhotoPreview || defaultProfile}
                    alt="Profile"
                    className="rounded-circle mx-auto"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      margin: "0",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color: textColor,
                    }}
                  >
                    {username ? "@" + username : "@Username..."}
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      margin: "0",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color: textColor,
                    }}
                  >
                    {bio ? bio : "bio..."}
                  </p>

                  <div>
                    {links.map((link, index) => (
                      <Input
                        key={index}
                        style={{
                          marginTop: "0.5rem",
                          backgroundColor: "white",
                          textAlign: "center",
                          lineHeight: "21px",
                          fontSize: "16px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          pointerEvents: "none",
                        }}
                        className="w-full"
                        readOnly
                        placeholder={link.name}
                      ></Input>
                    ))}
                  </div>
                </Card>
              </Col>
            </Row>

            <div className="d-flex justify-content-between">
              <Button
                onClick={handlePrev}
                color="primary"
                className="one-rem-font-size d-lg-block"
              >
                Back
              </Button>

              <Button
                onClick={handleSubmit}
                color="primary"
                className="one-rem-font-size d-lg-block"
                disabled={!linkInBioLimit}
              >
                Create your link
              </Button>
            </div>
          </CardBody>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    addLinkInBioStatus: state.subscriber.addNewLinkInBioStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    emptyAddNewLinkInBioStatus: () => dispatch(emptyAddNewLinkInBioStatus()),
    createLinkInBio: (data) => dispatch(createLinkInBio(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LinkInBio);
