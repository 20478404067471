import async from "../components/Async";

import {
  List as ListIcon,
  Users as UsersIcon,
  Layers as LayersIcon,
  BarChart2 as ActivityIcon,
  HelpCircle as HelpIcon,
  Link as Plus,
  Code as CodeIcon,
  Link2 as LinkIcon,
  Globe as DomainIcon,
  User as User,
} from "react-feather";
import { isValidPublicDomain } from "../config";

// Landing
import Landing from "../pages/landing/Landing";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import TwoFA from "../pages/auth/TwoFA";
import TwoFASetup from "../pages/auth/TwoFactorAuthenticatorSetup";
import OTPpage from "../pages/auth/OTP";
import GetStarted from "../pages/auth/GetStarted";
import PublicSignIn from "../pages/auth/PublicSignIn";

// Pages
// import SubscriberList from "../pages/pages/SubscriberList";
import SubscriberListNew from "../pages/pages/SubscriberList/SubscriberListNew";
import CreatePurls from "../pages/pages/SubscriberList/CreatePurls";
import CreationSuccess from "../pages/pages/SubscriberList/CreatePurls/CreationSuccess";
import EditPurls from "../pages/pages/SubscriberList/EditPurls";
import ViewReport from "../pages/pages/SubscriberList/SingleCampaignReport/ViewReport";
import draftCampaign from "../pages/pages/SubscriberList/draftCampaign";

// Documentation
import RequestPage from "../pages/auth/RequestPage";
import ResetLink from "../pages/auth/ResetLink";
// import EditList from "../pages/pages/SubscriberList/EditList";
import LinkExpired from "../pages/auth/LinkExpired";
import LinkExpiry from "../pages/auth/LinkExpiry";
// Onboarding
import StepOne from "../pages/onboarding/StepOne";
import StepTwo from "../pages/onboarding/StepTwo";
import StepThree from "../pages/onboarding/StepThree";

// Users
const Users = async(() => import("../pages/pages/UserSettings/Users"));
const AccountRequests = async(() =>
  import("../pages/pages/UserSettings/AccountRequests")
);

//Teams
const Teams = async(() => import("../pages/pages/UserSettings/Teams/Teams"));
const EditTeam = async(() =>
  import("../pages/pages/UserSettings/Teams/EditTeam")
);

//Doamins
const Domains = async(() =>
  import("../pages/pages/UserSettings/Domains/index")
);

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));

// Reporting
const DefaultReports = async(() => import("../pages/reporting/DefaultReports"));

// Analytics Integration
const AnalyticsIntegration = async(() =>
  import("../pages/pages/AnalyticsIntegration")
);

const PersonalisationIntegration = async(() =>
  import("../pages/pages/PersonalisationIntegration")
);

//Help and Support
const DefaultHelp = async(() => import("../pages/helpandsupport/DefaultHelp"));

//Public Domain Pages
const Links = async(() => import("../pages/pages/PublicDomain/Links"));
const UserProfile = async(() =>
  import("../pages/pages/PublicDomain/UserProfile")
);
const LinksAnalytics = async(() =>
  import("../pages/pages/PublicDomain/LinkAnalytics/LinkAnalytics")
);

// Routes
const landingRoutes = {
  path: "/",
  name: "Landing Page",
  component: Landing,
  children: null,
};

const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  badgeColor: "primary",
  icon: LayersIcon,
  component: Default,
  containsHome: true,
  children: null,
};

const createPurlsRoutes = {
  path: "/campaigns/create-purls",
  name: "Create purls",
  badgeColor: "primary",
  icon: Plus,
};

const ReportRoutes = {
  path: "/analytics",
  name: "Analytics",
  badgeColor: "primary",
  icon: ActivityIcon,
  component: DefaultReports,
  containsHome: true,
  children: [
    {
      path: "/analytics/trackable-links-analytics",
      name: isValidPublicDomain
        ? "Trackable Links Analytics"
        : "Campaign Analytics",
      component: DefaultReports,
    },
    {
      path: "/analytics/links-analytics",
      name: "Links Analytics",
      component: LinksAnalytics,
    },
  ],
};

const IntegrationRoutes = {
  path: "/integrations",
  name: "Integrations",
  badgeColor: "primary",
  icon: CodeIcon,
  component: AnalyticsIntegration,
  containsHome: true,
  children: [
    {
      path: "/integrations/analytics-tools",
      name: "Analytics Tools",
      component: AnalyticsIntegration,
    },
    {
      path: "/integrations/personalization-tools",
      name: "Personalization Tools",
      component: PersonalisationIntegration,
    },
  ],
};

const HelpRoutes = {
  path: "/help-and-support",
  name: "Help & Support",
  badgeColor: "primary",
  icon: HelpIcon,
  component: DefaultHelp,
  containsHome: true,
  children: null,
};

const userSettingsRoutes = {
  path: "/user-settings",
  name: "User & Teams",
  badgeColor: "primary",
  icon: UsersIcon,
  component: Users,
  containsHome: true,
  children: [
    {
      path: "/user-settings/users",
      name: "Users",
      component: Users,
    },
    {
      path: "/user-settings/account-requests",
      name: "Account Requests",
      component: AccountRequests,
    },
    {
      path: "/user-settings/teams",
      name: "Teams",
      component: Teams,
    },
    {
      path: "/user-settings/teams/editTeam/:id",
      component: EditTeam,
    },
    {
      path: "/user-settings/teams/domains",
      name: "Domains",
      component: Domains,
    },
  ],
};
const standardUserSettingsRoutes = {
  path: "/user-settings",
  name: "User & Teams",
  badgeColor: "primary",
  icon: UsersIcon,
  component: Users,
  containsHome: true,
  children: [
    {
      path: "/user-settings/teams",
      name: "Teams",
      component: Teams,
    },
  ],
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: isValidPublicDomain ? PublicSignIn : SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-link",
      name: "Reset Link",
      component: ResetLink,
    },
    {
      path: "/auth/reset-password/:token",
      name: "Reset password",
      component: ResetPassword,
    },
    {
      path: "/auth/set-new-password/:token",
      name: "Set new password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
    {
      path: "/auth/LinkExpired",
      name: "LinkExpired",
      component: LinkExpired,
    },
    {
      path: "/requestAccount/200",
      name: "Request Page",
      component: RequestPage,
    },
    {
      path: "/auth/2fa-request",
      name: "Two Factor Authentication",
      component: TwoFA,
    },
    {
      path: "/auth/2fa-configuration",
      name: "Authenticator Setup",
      component: TwoFASetup,
    },
    {
      path: "/auth/otp",
      name: "OTP page",
      component: OTPpage,
    },
    {
      path: "/auth/get-started",
      name: "Get Started",
      component: isValidPublicDomain ? GetStarted : Page404,
    },
  ],
};

const onboardingRoute = {
  path: "/onboarding",
  name: "Onboarding",
  children: [
    {
      path: "/onboarding/step1",
      name: "Step 1",
      component: StepOne,
    },
    {
      path: "/onboarding/step2",
      name: "Step 2",
      component: StepTwo,
    },
    {
      path: "/onboarding/step3",
      name: "Step 3",
      component: StepThree,
    },
  ],
};

const expiryRoute = {
  path: "/404",
  name: "LinkExpiry",
  badgeColor: "primary",
  icon: ListIcon,
  component: LinkExpiry,
  children: null,
};

const subscriberListRoutes = {
  path: "/campaigns",
  name: isValidPublicDomain ? "Trackable links" : "Campaigns",
  badgeColor: "primary",
  icon: ListIcon,
  component: SubscriberListNew,
  containsHome: true,
  children: null,
};

const creationSuccessRoutes = {
  path: "/campaigns/create-purls-success",
  name: "Success",
  component: CreationSuccess,
};

const subscriberRoutes = {
  path: "/campaigns",
  name: "Campaigns",
  children: [
    {
      path: "/campaigns/edit-list/:id",
      name: "Edit List",
      component: EditPurls,
    },
    {
      path: "/campaigns/create-purls",
      name: "Create Purls",
      component: CreatePurls,
    },
    {
      path: "/campaigns/report/:id",
      name: "View Report",
      component: ViewReport,
    },
    {
      path: "/campaigns/draft/:id",
      name: "draft",
      component: draftCampaign,
    },
  ],
};

const linksRoutes = {
  path: "/links",
  name: "Links",
  badgeColor: "primary",
  icon: LinkIcon,
  component: Links,
  containsHome: true,
  children: null,
};
const userProfileRoute = {
  path: "/user-profile",
  name: "User Profile",
  badgeColor: "primary",
  icon: User,
  component: UserProfile,
  containsHome: true,
  children: null,
};

const Domain = {
  path: "/domains",
  name: "Domains",
  badgeColor: "primary",
  icon: DomainIcon,
  component: Domains,
  containsHome: true,
  children: null,
};

// Dashboard specific routes
export const dashboard = isValidPublicDomain
  ? [
      linksRoutes,
      ReportRoutes,
      subscriberListRoutes,
      subscriberRoutes,
      creationSuccessRoutes,
      IntegrationRoutes,
      userProfileRoute,
      HelpRoutes,
      Domain,
    ]
  : [
      linksRoutes,
      dashboardRoutes,
      ReportRoutes,
      IntegrationRoutes,
      userSettingsRoutes,
      subscriberListRoutes,
      subscriberRoutes,
      HelpRoutes,
      creationSuccessRoutes,
    ];

// Landing specific routes
export const landing = [landingRoutes, expiryRoute, onboardingRoute];

// Auth specific routes
export const page = [authRoutes];

// All routes

export const adminDashboard = [
  dashboardRoutes,
  createPurlsRoutes,
  subscriberListRoutes,
  linksRoutes,
  ReportRoutes,
  IntegrationRoutes,
  userSettingsRoutes,
  HelpRoutes,
];

export const standardDashboard = [
  dashboardRoutes,
  createPurlsRoutes,
  subscriberListRoutes,
  linksRoutes,
  ReportRoutes,
  IntegrationRoutes,
  standardUserSettingsRoutes,
  HelpRoutes,
];

export const publicRoutes = [
  ReportRoutes,
  createPurlsRoutes,
  subscriberListRoutes,
  linksRoutes,
  IntegrationRoutes,
  Domain,
  userProfileRoute,
  HelpRoutes,
];

export default [
  dashboardRoutes,
  createPurlsRoutes,
  subscriberListRoutes,
  ReportRoutes,
  IntegrationRoutes,
  userSettingsRoutes,
  HelpRoutes,
  linksRoutes,
];
