import React, { useState, useEffect } from "react";
import { Button, Col, Row, Input, Label } from "reactstrap";
import Select from "react-select";
import { useHistory, Redirect } from "react-router-dom";
import { Trash } from "styled-icons/bootstrap";
import { Minus, Plus } from "react-feather";
import { connect } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { ArrowLeft, ArrowRight } from "react-feather";

import { customStyles, errorStyles } from "../../components/CustomStyle";
import Header from "./OnboardingHeader";
import { StepTwoValidation } from "../validation/validation";
import {
  userReqest,
  fetchFallbackUrlData,
} from "../../redux/actions/authActions";

const StepTwo = ({
  uploadUsers,
  stepTwoDone,
  fetchUrlData,
  onboardingData,
  stepOneFilled,
}) => {
  let formIsValid = true;
  const history = useHistory();
  const [errors, setErrors] = useState([]);
  const [users, setUsers] = useState([
    { full_name: "", email: "", phone: "", role_id: 0 },
  ]);
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user?.authorization?.split("=")[1]?.split(";")[0];
  const decoded = token ? jwtDecode(token) : "";

  const stepTwoUpload = () => {
    setErrors([]);
    let emptyOnlyUser = false;
    users?.map((user) => {
      const { userValid, error, emptySingleUser } = StepTwoValidation(
        user,
        users?.length
      );
      emptyOnlyUser = emptySingleUser;
      formIsValid = formIsValid && userValid;
      const newError = { ...error };
      setErrors((prevErrors) => [...prevErrors, newError]);
    });

    if (formIsValid) {
      if (emptyOnlyUser) {
        history.push("/onboarding/step3");
      } else {
        uploadUsers(users);
      }
    }
  };

  useEffect(() => {
    if (user) {
      fetchUrlData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stepTwoDone) {
      history.push("/onboarding/step3");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepTwoDone]);

  const options = [
    {
      label: "Admin",
      value: 1,
    },
    {
      label: "Standard",
      value: 2,
    },
  ];

  function decreaseUser() {
    const newUsers = [...users];
    newUsers.pop();
    setUsers(newUsers);
    const newErrors = [...errors];
    newErrors.pop();
    setErrors(newErrors);
  }
  function increaseUser() {
    const newUser = {
      full_name: "",
      email: "",
      phone: "",
      role_id: 0,
    };
    const newUsers = [...users];
    newUsers.push(newUser);
    setUsers(newUsers);
  }

  function deleteUser(index) {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    setUsers(newUsers);
    const newError = [...errors];
    newError.splice(index, 1);
    setErrors(newError);
  }

  const handleUserChange = (e, index) => {
    const newUsers = [...users];
    newUsers[index] = {
      ...newUsers[index],
      full_name: e.target.value ?? "",
    };
    setUsers(newUsers);
    const newError = [...errors];
    newError[index] = {
      ...newError[index],
      name: "",
    };
    setErrors(newError);
  };

  const handleEmailChange = (e, index) => {
    const newUsers = [...users];
    newUsers[index] = {
      ...newUsers[index],
      email: e.target.value ?? "",
    };
    setUsers(newUsers);
    const newError = [...errors];
    newError[index] = {
      ...newError[index],
      email: "",
    };
    setErrors(newError);
  };

  const handlePhoneChange = (e, index) => {
    const newUsers = [...users];
    newUsers[index] = {
      ...newUsers[index],
      phone: e.target.value ?? "",
    };
    setUsers(newUsers);
    const newError = [...errors];
    newError[index] = {
      ...newError[index],
      phone: "",
    };
    setErrors(newError);
  };

  const handleRoleChange = (e, index) => {
    const newUsers = [...users];
    newUsers[index] = {
      ...newUsers[index],
      role_id: e.value,
    };
    setUsers(newUsers);
    const newError = [...errors];
    newError[index] = {
      ...newError[index],
      role: "",
    };
    setErrors(newError);
  };
  if (user) {
    if (decoded?.role === "Admin" && decoded?.onboarding === false) {
      if (
        stepOneFilled ||
        (!onboardingData?.campaign_expiry_fallback_url == "" &&
          !onboardingData?.auto_delete_purl_days == "")
      ) {
        return (
          <Row className="h-100 my-auto mx-2">
            <Col sm="10" md="8" lg="6" className="mx-auto my-6 d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-start bg-white px-3 px-md-5 py-3">
                  <div className="">
                    <Header step={2} />
                    <div>
                      <p className="text-center h4 mb-4">
                        Add Users (Optional)
                      </p>
                      <div>
                        <div>
                          <div className="d-sm-flex align-items-center mb-3">
                            <p className="h5 mb-2 mb-sm-0 text-center text-md-left">
                              Configure the number of users to add:{" "}
                            </p>
                            <div className="d-flex mx-sm-3 justify-content-center align-items-center">
                              <Button
                                disabled={users?.length <= 1 ? true : false}
                                className={`p-0 rounded-circle mb-0 bg-white justify-content-center d-flex ${
                                  users?.length <= 1
                                    ? "custom-border"
                                    : "text-primary border-primary"
                                }`}
                                style={{ width: "25px", height: "25px" }}
                                onClick={decreaseUser}
                              >
                                <span
                                  className={`${
                                    users?.length <= 1
                                      ? "gray-color"
                                      : "text-primary"
                                  }`}
                                >
                                  <Minus size={20} />
                                </span>
                              </Button>
                              <input
                                value={users?.length}
                                disabled
                                size={1}
                                className="mx-2 text-center"
                                style={{ height: "27px", width: "32px" }}
                              />
                              <Button
                                disabled={users?.length >= 10 ? true : false}
                                className={`p-0 rounded-circle mb-0 bg-white justify-content-center d-flex ${
                                  users?.length >= 10
                                    ? "custom-border"
                                    : "border-primary"
                                }`}
                                style={{ width: "25px", height: "25px" }}
                                onClick={increaseUser}
                              >
                                <span
                                  className={`${
                                    users?.length >= 10
                                      ? "gray-color"
                                      : "text-primary"
                                  }`}
                                >
                                  <Plus size={20} />
                                </span>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div>
                          {users?.map((user, index) => (
                            <div key={index} className="mb-4">
                              <div className="d-flex justify-content-between">
                                <Label className="mb-2 one-rem-font-size">
                                  User {index + 1}
                                </Label>
                                {users?.length > 1 && (
                                  <Trash
                                    className="cursor-pointer"
                                    size={20}
                                    color="red"
                                    onClick={() => deleteUser(index)}
                                  />
                                )}
                              </div>
                              <form className="div-grid">
                                <div>
                                  <Input
                                    className="custom-input"
                                    style={
                                      errors[index]?.name
                                        ? { border: "1px solid red" }
                                        : {}
                                    }
                                    type="text"
                                    placeholder="Name"
                                    bsSize="lg"
                                    value={user?.full_name}
                                    onChange={(e) => handleUserChange(e, index)}
                                  />
                                  {errors[index]?.name && (
                                    <p
                                      style={{ color: "red" }}
                                      className="mb-0"
                                    >
                                      {errors[index]?.name}
                                    </p>
                                  )}
                                </div>
                                <div>
                                  <Input
                                    className={`custom-input mt-2 mt-sm-0 `}
                                    style={
                                      errors[index]?.email
                                        ? { border: "1px solid red" }
                                        : {}
                                    }
                                    text="email"
                                    placeholder="Email"
                                    bsSize="lg"
                                    value={user?.email}
                                    onChange={(e) =>
                                      handleEmailChange(e, index)
                                    }
                                  />
                                  {errors[index]?.email && (
                                    <p
                                      style={{ color: "red" }}
                                      className="mb-0"
                                    >
                                      {errors[index]?.email}
                                    </p>
                                  )}
                                </div>
                                <div>
                                  <Input
                                    className={`mt-2 none custom-input`}
                                    style={
                                      errors[index]?.phone
                                        ? { border: "1px solid red" }
                                        : {}
                                    }
                                    type="number"
                                    placeholder="Phone (Optional)"
                                    bsSize="lg"
                                    value={user?.phone}
                                    onChange={(e) =>
                                      handlePhoneChange(e, index)
                                    }
                                  />
                                  {errors[index]?.phone && (
                                    <p
                                      style={{ color: "red" }}
                                      className="mb-0"
                                    >
                                      {errors[index]?.phone}
                                    </p>
                                  )}
                                </div>
                                <div>
                                  <Select
                                    className={`react-select-container mt-2 `}
                                    placeholder="Role"
                                    styles={
                                      errors[index]?.role
                                        ? errorStyles
                                        : customStyles
                                    }
                                    options={options}
                                    value={options.find(
                                      (option) => option.value === user?.role_id
                                    )}
                                    onChange={(e) => {
                                      handleRoleChange(e, index);
                                    }}
                                  />
                                  {errors[index]?.role && (
                                    <p
                                      style={{ color: "red" }}
                                      className="mb-0"
                                    >
                                      {errors[index]?.role}
                                    </p>
                                  )}
                                </div>
                              </form>
                            </div>
                          ))}
                        </div>
                        <p>
                          Add users to your purl instance. Enter the name,
                          email, phone number and role of each user. If you need
                          to add more users, simply click the + button to
                          increase the input fields.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <Button
                        className="px-3"
                        color="primary d-flex align-items-center"
                        outline
                        onClick={() => history.push("/onboarding/step1")}
                      >
                        <span className="mr-1" style={{ lineHeight: "0" }}>
                          <ArrowLeft size={17} />
                        </span>
                        Back
                      </Button>
                      <Button
                        className="px-3"
                        color="primary d-flex align-items-center"
                        onClick={() => {
                          stepTwoUpload();
                        }}
                      >
                        Next
                        <span className="ml-1" style={{ lineHeight: "0" }}>
                          <ArrowRight size={17} />
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        );
      } else {
        return <Redirect to="/onboarding/step1" />;
      }
    } else {
      return <Redirect to="/dashboard" />;
    }
  } else {
    return <Redirect to="/auth/sign-in" />;
  }
};
const mapStateToProps = (state) => {
  return {
    onboardingData: state.auth.onboardingData,
    stepTwoDone: state.auth.stepTwoDone,
    stepOneFilled: state.auth.stepOneFilled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUrlData: () => dispatch(fetchFallbackUrlData()),
    uploadUsers: (users) => dispatch(userReqest(users)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
